import dayjs from "dayjs";

export const scheduleValueParser = (values) => {
  
  let ccValues = Array.isArray(values.cc)? values.cc : values.cc.split(",").map(value=>value.trim());
  return {
    "sendTo": values.sendTo,
    "cc": ccValues,
    "subject": values.subject,
    "from": values.startDate,
    "to":values.endDate,
    "time": dayjs(values.startTime).format("HH:mm"),
    "renderFormat": values.format,
    "runSchedule": values.frequency,
    "every": values.every
  };
};

export const scheduleType = {
  daily: 1,
  weekly: 2,
  monthly: 3
};

export const weekDays = [
  {
    label: "Monday",
    value: 1
  },
  {
    label: "Tuesday",
    value: 2
  },
  {
    label: "Wednesday",
    value: 3
  },
  {
    label: "Thursday",
    value: 4
  },
  {
    label: "Friday",
    value: 5
  },
  {
    label: "Saturday",
    value: 6
  },
  {
    label: "Sunday",
    value: 7
  }
];

export const monthDates = [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31 ];

