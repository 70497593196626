import React, { useEffect, useState } from 'react';
import { Box, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { convertClientPermissionToId } from '../../Helpers/clientRolesPermissions';
import { selectUser } from '../../redux/features/user/userSlice';
import Icons from '../icons';
import SearchAccount from '../SearchAccount';

const SelectAEContainer = styled.div``;

const AEEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssignAccountsContainer = styled.div`
  position: relative;
  margin-top: 18px;
`;

const DisabledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(255,255,255,0.6);
`;

const ResultsContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: scroll;
  height: 245px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0;
`;

const RedText = styled.span`
  color: #B80A26;
`;

const HeadLabelText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #444444;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Email = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ color = '#000000' }) => color};
`;

const SelectedAccounts = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const TitleLabel = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const Divider = styled.div`
  border-bottom: 1px solid #BDBDBD;
  margin: 14px 0 20px 0;
`;

const EditShadowAccountSelectAE = ({
  clientId,
  selectedAE,
  onEditShadowAccount,
}) => {
  const [selectedAEUser, setSelectedAEUser] = useState(selectedAE);

  const userRedux = useSelector(selectUser);

  const onEditSelectedAccounts = () => {
    setSelectedAEUser((prev) => ({
      ...prev,
      isEdittingAccount: true,
    }));
  }

  useEffect(() => {
    onEditShadowAccount(selectedAEUser);
  }, [selectedAEUser]);

  const onAccountRowsChange = (rows) => {
    const _assignedAccounts = rows.map((row) => {
      const account = {
        ...row,
        accounts: row.id,
        accountId: row.id,
        id: row.id,
        permissions: [],
        company: row.company,
      };

      if (row.createReports)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "REPORT_CREATION"));

      if (row.userManagement)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "USER_MANAGEMENT"));

      if (row.readOnly)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "READ_ONLY"));

      return account;
    });

    setSelectedAEUser((prev) => ({
      ...prev,
      accounts: _assignedAccounts,
    }));
  }
  return (
    <>
      <SelectAEContainer>
        {
          selectedAEUser.userId && (
            <SectionContainer>
              <AEEmailContainer>
                <TitleLabel>Shadowing:</TitleLabel>
                <Email>
                  <b>{selectedAEUser.fullName}</b>
                </Email>
                <Email><RedText>{selectedAEUser.email}</RedText></Email>
              </AEEmailContainer>
            </SectionContainer>
          )
        }
      </SelectAEContainer>

      {
        (selectedAEUser.isEdittingUser || selectedAEUser.isEdittingAccount) && (
          <AssignAccountsContainer>
            {!selectedAEUser?.userId && <DisabledContainer />}

            <Box mb={2}>
              <HeadLabelText>Assign Accounts</HeadLabelText>
            </Box>

            <SearchAccount
              onRowsChange={onAccountRowsChange}
              accounts={selectedAEUser.accounts}
              accountExecutiveId={clientId}
            />
          </AssignAccountsContainer>
        )
      }

      {
        !selectedAEUser.isEdittingAccount && !selectedAEUser.isEdittingUser && (
          <>
            <Box my={3}>
              <SectionContainer>
                <div>
                  <TitleLabel>Clients Assigned:</TitleLabel>
                  <SelectedAccounts>{selectedAEUser.accounts.map(a => a.company).join(', ')}</SelectedAccounts>
                </div>

                <IconButton onClick={onEditSelectedAccounts}>
                  <Icons.EditIcon />
                </IconButton>
              </SectionContainer>
            </Box>
            <Divider />
          </>
        )
      }

      {
        selectedAEUser?.isEdittingAccount && (
          <Box
            display="flex"
            justifyContent="end"
            margin="18px 0;"
          >
            <TitleLabel>
              {!selectedAEUser.accounts.length ? `No accounts selected. ` : ' '}
            </TitleLabel>
          </Box>
        )
      }
    </>
  );
}

export default EditShadowAccountSelectAE;
