import Moment from "moment";

export const toCurrency = (value) =>
  (isNaN(value) ? 0 : +value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

export const formatNumber = (value) => {
  const format = Intl.NumberFormat('en-US');

  return format.format(value);
};

export const validateNullValue = (value, isDate) => {

  if (!value) {
    return 'Not Available';
  }

  if (isDate) {
    return  Moment(value).format("MM/DD/YYYY");
  }
 
  return value;

}

export const formatAccountPayload = (selectedCompanies) => {
  let companyFilter = selectedCompanies.map((company) => company.id).join(","); 
  return companyFilter;
}
