export const DESCRIPTION_WORDS_TO_FILTER = [
  'freight',
]

export const detailsDataRemoveRowsWithDescriptionWords = (detailsData) => {
  const filteredDetailsData = detailsData.filter((detail) => {
    const descriptionWords = detail.description.split(' ')

    const hasDescriptionWordsToFilter = descriptionWords.some((word) =>
      DESCRIPTION_WORDS_TO_FILTER.includes(word.toLowerCase())
    )

    return !hasDescriptionWordsToFilter
  })

  return filteredDetailsData
}
