import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
  Divider,
  CircularProgress,
  Checkbox,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Moment from 'moment';
import styledC from 'styled-components';
import {
  Search as SearchIcon,
  DoubleArrow as DoubleArrowIcon,
  Clear as ClearIcon,
} from '@mui/icons-material';
import AdminModalComponent from './AdminModalComponent';
import usersAPI from '../api/modules/users';
import authAPI from '../api/modules/authentication';
import { ClientOwnerModal } from './InviteClientOwner/ClientOwnerModal';
import AccountExecutiveModal from './AccountExecutiveModal';
import { ClientUserModal } from './ClientUser/ClientUserModal';
import {
  convertClientPermisionsToNames,
  convertClientRolesToIds,
} from '../Helpers/clientRolesPermissions';
import { useSelector } from 'react-redux';
import Icons from './icons';
import ShadowAccountExecutive from './AccountExecutiveInvite/ShadowAccountExecutive';
import {
  getSelectedCompany,
  selectUser,
} from '../redux/features/user/userSlice';
import { useNotification } from '../context/notificationContext';
import SearchTableComponent from './SearchTableComponent';
import { formatAccountPayload } from '../Helpers/formatters';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import {
  BlueAquaSearchButtonOutline,
  BodyContainer,
  BodyGridWrapper,
} from './Shared/BodyContainer/Index';
import AutoResizeGrid from './AutoResizeGrid/AutoResizeGrid';
import MixpanelHelper from '../Helpers/MixpanelHelper';
import { NavLink } from 'react-router-dom';

const BlueAquaButtonFilled = styled(Button)({
  padding: '8px 22px',
  gap: '8px',
  width: 'auto',
  height: '42px',
  borderRadius: 0,
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  fontSize: 16,
});

const StMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: '203px',
    '& .MuiMenu-list': {
      padding: '0px ',
    },
  },
}));
const StMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: 'rgba(239, 180, 71, 0.2)',
    fontWeight: 'bold',
  },
}));
const StDivider = styled(Divider)(({ theme }) => ({
  marginTop: '0px !important',
  marginBottom: '0px !important',
}));
const StGridCheckBox = styled(Checkbox)(({ theme }) => ({
  '&.Mui-disabled': {
    color: '#44C0B4',
  },
}));

const ActionWrapper = styledC.div`
  display: flex;
  align-items: center;
`;

const UserActivityButton = styled(Box)`
  width: 169px;
  height: 42px;
  background: #44c0b4;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
`;

const styles = {
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const GradientEditIcon = ({ handleClickOpen }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>

    <IconButton title="Edit" onClick={handleClickOpen}>
      <Icons.EditIcon />
    </IconButton>
  </>
);
const GradientShareIcon = () => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>
    <DoubleArrowIcon sx={{ fill: 'url(#linearColors)' }} />
  </>
);

const columnsChooser = {
  allUsers: {
    lastLogin: false,
    manageAccounts: false,
    lastName: false,
  },
  ADMIN: {
    company: false,
    role: false,
    manageAccounts: false,
  },
  ACCOUNT_EXECUTIVE: {
    company: false,
    role: true,
    lastName: false,
  },
  CLIENT_STANDARD_USER: {
    lastLogin: false,
    manageAccounts: false,
    actions: false,
  },
  CLIENT_ADMIN: {
    lastLogin: false,
    manageAccounts: false,
    actions: false,
  },
  CLIENT_OWNER: {
    lastLogin: false,
    manageAccounts: false,

    actions: false,
  },
};

function InformativeComponent() {
  const userRedux = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoleFilter, setSelectedRoleFilter] = useState('');
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState('ALL_USERS');
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [usersCount, setUsersCount] = useState(0);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textToSearch, setTextToSearch] = useState('');

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const tableRef = useRef(null);
  const { setNotification } = useNotification();

  const [adminModal, setAdminModal] = useState({
    isOpen: false,
    selectedAdmin: null,
  });

  const [clientOwnerModal, setClientOwnerModal] = useState({
    isOpen: false,
    selectedClient: null,
  });

  const [clientUserModal, setClientUserModal] = useState({
    isOpen: false,
    selectedClient: null,
  });

  const [accountExecutiveModal, setAccountExecutiveModal] = useState({
    isOpen: false,
    selectedClient: null,
  });

  const [shadowAccountExecutiveModal, setShadowAccountExecutiveModal] =
    useState({
      isOpen: false,
      selectedClient: null,
    });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOpenInviteAdmin = () => {
    setAdminModal({
      isOpen: true,
      selectedAdmin: null,
    });
  };

  const onClickInviteClientOwner = () => {
    setClientOwnerModal({
      isOpen: true,
      selectedClient: null,
    });

    handleClose();
  };

  const handleCloseInviteClientOwner = () => {
    setClientOwnerModal({
      isOpen: false,
      selectedClient: null,
    });
  };

  const handleCloseInviteClientUser = () => {
    setClientUserModal({
      isOpen: false,
      selectedClient: null,
    });
  };

  const handleClickOpenEditAdmin = (adminId) => {
    const selectedAdmin = users.find((user) => user.id === adminId);

    setAdminModal({
      selectedAdmin,
      isOpen: true,
    });
  };

  const handleClickOpenEditClientOwner = (id) => {
    const userFound = users.find((user) => user.id === id);

    if (userFound) {
      const selectedClient = {
        ...userFound,
        accounts: userFound.accounts[0],
      };

      setClientOwnerModal({
        selectedClient,
        isOpen: true,
      });
    }
  };

  const handleClickOpenEditClientUser = (id) => {
    const userFound = users.find((user) => user.id === id);

    if (userFound) {
      const selectedClient = {
        ...userFound,
        accounts: userFound.accounts[0],
      };

      setClientUserModal({
        selectedClient,
        isOpen: true,
      });
    }
  };

  const handleCloseAdminModal = () => {
    setAnchorEl(null);
    setAdminModal({
      isOpen: false,
      selectedAdmin: null,
    });
  };

  const handleClickOpenInviteAExecutive = () => {
    setAccountExecutiveModal({
      isOpen: true,
      selectedClient: null,
    });
  };

  const handleCloseAccountExecutive = () => {
    setAnchorEl(null);
    setAccountExecutiveModal({
      isOpen: false,
      selectedClient: null,
    });
  };

  const handleClickOpenEditAExecutive = (id) => {
    const selectedClient = users.find((user) => user.id === id);

    setAccountExecutiveModal({
      isOpen: true,
      selectedClient: selectedClient,
    });
  };

  const handleOpenShadowAccount = (id) => {
    setShadowAccountExecutiveModal({
      isOpen: true,
      selectedClient: id,
    });
  };

  const acceptRequest = async (id) => {
    try {
      const response = await authAPI.invite({ userId: id });

      if (response?.message) {
        return setNotification({
          open: true,
          message: response?.data?.message,
          type: 'error',
        });
      }

      handleReloadTable();

      return setNotification({
        open: true,
        message: 'Request has been approved.',
        type: 'success',
      });
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
    }
  };

  const resendInvite = async (id) => {
    try {
      const response = await authAPI.resendInvite({ userId: id });

      if (response?.message) {
        return setNotification({
          open: true,
          message: response?.data?.message,
          type: 'error',
        });
      }

      return setNotification({
        open: true,
        message: 'Invitation has been resent.',
        type: 'success',
      });
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
    }
  };

  const renderAction = (GridCellParams) => {
    const role = GridCellParams.row?.role?.name ?? 'NA';
    let actionToRender = <></>;

    if (role === 'ADMIN') {
      actionToRender = (
        <GradientEditIcon
          handleClickOpen={() =>
            handleClickOpenEditAdmin(GridCellParams.row.id)
          }
        />
      );
    }

    if (role === 'ACCOUNT_EXECUTIVE') {
      actionToRender = (
        <ActionWrapper>
          <GradientEditIcon
            handleClickOpen={() =>
              handleClickOpenEditAExecutive(GridCellParams.row.id)
            }
          />
          <IconButton
            title="Shadow Account"
            onClick={() => handleOpenShadowAccount(GridCellParams.row.id)}
          >
            <Icons.UserIcon color="#9E9E9E" />
          </IconButton>
        </ActionWrapper>
      );
    }

    if (role === 'CLIENT_ACCOUNT_OWNER') {
      actionToRender = (
        <GradientEditIcon
          handleClickOpen={() =>
            handleClickOpenEditClientOwner(GridCellParams.row.id)
          }
        />
      );
    }

    if (role === 'CLIENT_ADMIN' || role === 'CLIENT_STANDARD_USER') {
      actionToRender = (
        <GradientEditIcon
          handleClickOpen={() =>
            handleClickOpenEditClientUser(GridCellParams.row.id)
          }
        />
      );
    }

    if (GridCellParams.row.status === 0)
      actionToRender = (
        <>
          <IconButton
            title="Accept Request"
            onClick={() => acceptRequest(GridCellParams.row.id)}
          >
            <Icons.ThumbUpIcon />
          </IconButton>
          <IconButton title="Decline Request" onClick={() => {}}>
            <ClearIcon />
          </IconButton>
        </>
      );

    if (actionToRender && GridCellParams.row.lastLogin === null) {
      actionToRender = (
        <>
          {actionToRender}
          <IconButton
            title="Resend Invite"
            onClick={() => resendInvite(GridCellParams.row.id)}
          >
            <Icons.SendIcon />
          </IconButton>
        </>
      );
    }

    return actionToRender;
  };

  const checkUserPermissionGrid = (user, type) => {
    const permissions = convertClientPermisionsToNames(
      userRedux.permissions,
      user.permissions
    );

    return permissions.some((permission) => permission === type);
  };

  const columns = [
    {
      field: 'firstName',
      headerName:
        selectedDropdownFilter === 'ALL_USERS' ||
        selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
          ? 'Name'
          : 'First Name',
      minWidth: 150,
      // maxWidth: 350,
      flex: 1,
      valueGetter: ({ row }) => {
        return selectedDropdownFilter === 'ALL_USERS' ||
          selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
          ? row.fullName
          : row.firstName;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 150,
      // maxWidth: 350,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 300,
      // maxWidth: 600,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#B80A26' }}>{params.row.email}</Box>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => {
        if (row.role?.id === 3 || row.role?.id === 2 || row.role?.id === 1) {
          return 'MicroAge';
        }
        const { accounts } = row;

        if (!accounts?.length) return '-';

        const [account] = accounts;

        return account.company;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 250,
      // maxWidth: 300,
      flex: 1,
      valueGetter: ({ row }) => {
        const { role } = row;

        return `${role?.name ? role.name.replace(/_/g, ' ') : '-'}`;
      },
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      minWidth: 250,
      // maxWidth: 300,
      renderCell: ({ row }) => {
        const value = row.lastLogin
          ? Moment(row.lastLogin).format('MM/DD/YYYY')
          : null;

        return (
          <Box
            sx={{
              color: value ? 'inherit' : '#EFB447',
              fontWeight: value ? 'inherit' : 700,
            }}
          >
            {value ? value : 'Unverified'}
          </Box>
        );
      },
    },
    // {
    //   field: "manageAccounts",
    //   headerName: "Manage Accounts",
    //   minWidth: 164,
    //   valueGetter: (params) => {
    //     return params.row.manageAccounts ? params.row.manageAccounts : "-";
    //   },
    // },

    {
      field: 'Action',
      headerName: 'Actions',
      align: 'left',
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      renderCell: renderAction,
    },
  ];
  const handleGridUserChange = ({ role = '' }) => {
    if (role === '') {
      setColumnVisibilityModel(columnsChooser['allUsers']);
      return;
    }

    const _role = role.split(',')[0];

    setColumnVisibilityModel(columnsChooser[_role]);
  };

  const getUsersByRole = async (
    role = '',
    offset = 1,
    limit = 25,
    selectedCompany = [{ id: -1 }],
    search
  ) => {
    try {
      setIsLoading(true);

      let roles = '';

      if (role !== '') {
        roles = convertClientRolesToIds(userRedux.roles, role.split(','));
        roles = roles.join(',');
      }

      const getUsersPayload = {
        offset,
        limit,
        role: roles,
      };

      if (search) Object.assign(getUsersPayload, { search });
      if (
        selectedCompany.length >= 1 &&
        selectedCompany[0].id > -1 &&
        role !== 'ADMIN'
      ) {
        const companyFilter = formatAccountPayload(selectedCompany);
        Object.assign(getUsersPayload, {
          accountId: companyFilter,
        });
      }

      const { data } = await usersAPI.getUsers(getUsersPayload);

      handleGridUserChange({ role });

      setUsers(data.users);
      setUsersCount(data.count);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadTable = async () => {
    await getUsersByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      selectedCompanyRedux
    );
  };

  useEffect(() => {
    getUsersByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      selectedCompanyRedux,
      isSearchOpen ? textToSearch : null
    );
  }, [selectedRoleFilter, currentPage, pageSize, selectedCompanyRedux]);

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const onPageChange = (page) => {
    scrollToTable();
    setCurrentPage(page + 1);
  };

  const scrollToTable = () => tableRef.current.scrollIntoView();

  const renderTable = useMemo(
    () => (
      <AutoResizeGrid
        sx={{ marginTop: '10px' }}
        columnVisibilityModel={columnVisibilityModel}
        rows={users}
        columns={columns}
        onPageChange={onPageChange}
        pageSize={pageSize}
        paginationMode="server"
        page={currentPage - 1}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        rowCount={usersCount}
        autoHeight
      />
    ),
    [currentPage, users, pageSize, columnVisibilityModel, usersCount]
  );

  const handleSearchUsers = (text, selectedValue) => {
    setTextToSearch(text);
    setSelectedDropdownFilter(selectedValue);

    getUsersByRole(
      selectedValue === 'ALL_USERS' ? '' : selectedValue,
      currentPage,
      pageSize,
      selectedCompanyRedux,
      text
    );
  };

  const onCloseSearch = () => {
    setIsSearchOpen(false);
    setTextToSearch('');
    handleReloadTable();
  };

  const onOpenSearch = () => {
    setIsSearchOpen(true);
  };

  const trackUserManagement = (tab) => {
    const company = selectedCompanyRedux?.[0]?.name ?? 'All';
    MixpanelHelper.track('User Management', { tab: tab, company: company });
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ minHeight: 'inherit', display: 'inherit' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopContainer>
            <TitleHeaderRow>
              <TitleHeaderCol>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    fontStyle: 'normal',
                    letterSpacing: '0.15px',
                  }}
                >
                  <span style={{ fontWeight: 700 }}>
                    {`${userRedux.firstName} ${userRedux.lastName}`}
                  </span>{' '}
                  - {`${userRedux.role?.name?.replace(/_/g, ' ') ?? ''}`}
                </Box>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '48px',
                    lineHeight: '65px',
                  }}
                >
                  Manage Users
                </Box>
              </TitleHeaderCol>

              <TitleHeaderCol>
                <Box display={'flex'} flexDirection={'row'} gap={'18px'}>
                  {(userRedux.role.name === 'SUPER_ADMIN' ||
                    userRedux.role.name === 'ADMIN') && (
                    <UserActivityButton>
                      <NavLink
                        style={{
                          textDecoration: 'none',
                          color: 'white',
                          display: 'flex',
                          width: '100%',
                          height: '100%',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        to={'/logs'}
                      >
                        User Activity Log
                      </NavLink>
                    </UserActivityButton>
                  )}
                  <BlueAquaButtonFilled
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant={open ? 'contained' : 'blueGradient-contained'}
                    color={open ? 'customRed' : undefined}
                  >
                    Invite New User
                  </BlueAquaButtonFilled>
                  <StMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '14px',
                      letterSspacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      borderRadius: '0 px !important',
                      paddingTop: '0px !important',
                    }}
                  >
                    <StMenuItem onClick={handleClickOpenInviteAdmin}>
                      Admin
                    </StMenuItem>
                    <StDivider />
                    <StMenuItem onClick={handleClickOpenInviteAExecutive}>
                      Account Executive
                    </StMenuItem>
                    <StDivider />
                    <StMenuItem onClick={onClickInviteClientOwner}>
                      Client Account
                    </StMenuItem>
                    <StDivider />
                  </StMenu>
                </Box>
              </TitleHeaderCol>
            </TitleHeaderRow>
            {shadowAccountExecutiveModal.isOpen && (
              <ShadowAccountExecutive
                open={shadowAccountExecutiveModal.isOpen}
                clientId={shadowAccountExecutiveModal.selectedClient}
                onClose={() => {
                  setShadowAccountExecutiveModal({
                    isOpen: false,
                    selectedClient: null,
                  });
                }}
              />
            )}

            <ClientOwnerModal
              open={clientOwnerModal.isOpen}
              client={clientOwnerModal.selectedClient}
              onClose={handleCloseInviteClientOwner}
              onSubmit={handleReloadTable}
            />

            <ClientUserModal
              open={clientUserModal.isOpen}
              client={clientUserModal.selectedClient}
              onClose={handleCloseInviteClientUser}
              onSubmit={handleReloadTable}
            />
          </TopContainer>
        </Grid>

        <BodyGridWrapper ref={tableRef} item xs={12} md={12}>
          <BodyContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!isSearchOpen ? (
                <>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ALL_USERS'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('');
                      setSelectedDropdownFilter('ALL_USERS');
                      trackUserManagement('All Users');
                    }}
                  >
                    All Users
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ADMIN'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('ADMIN');
                      setSelectedDropdownFilter('ADMIN');
                      trackUserManagement('Admin Users');
                    }}
                  >
                    Admin Users
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('ACCOUNT_EXECUTIVE');
                      setSelectedDropdownFilter('ACCOUNT_EXECUTIVE');
                      trackUserManagement('Account Executives');
                    }}
                  >
                    Account Executives
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter ===
                      'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                      );
                      setSelectedDropdownFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                      );
                      trackUserManagement('Clients');
                    }}
                  >
                    Clients
                  </Button>
                  <BlueAquaSearchButtonOutline
                    onClick={onOpenSearch}
                    variant="blueGradient-outlined"
                  >
                    <SearchIcon fontSize={'large'} />
                  </BlueAquaSearchButtonOutline>
                </>
              ) : (
                <SearchTableComponent
                  onCloseSearch={onCloseSearch}
                  onSubmitSearch={handleSearchUsers}
                  defaultValue={selectedDropdownFilter}
                  items={[
                    {
                      value: 'ALL_USERS',
                      label: 'All Users',
                    },
                    {
                      value: 'ADMIN',
                      label: 'Admin Users',
                    },
                    {
                      value: 'ACCOUNT_EXECUTIVE',
                      label: 'Account Executives',
                    },
                    {
                      value:
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER',
                      label: 'Clients',
                    },
                  ]}
                />
              )}
            </Box>

            {isLoading ? (
              <div style={styles.loadingContainer}>
                <CircularProgress style={{ color: '#B80A26' }} size={60} />
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>{renderTable}</div>
            )}
          </BodyContainer>
          {/* </Box> */}
          <AdminModalComponent
            open={adminModal.isOpen}
            admin={adminModal.selectedAdmin}
            handleSubmit={handleReloadTable}
            onClose={handleCloseAdminModal}
          />

          <AccountExecutiveModal
            handleSubmit={handleReloadTable}
            open={accountExecutiveModal.isOpen}
            client={accountExecutiveModal.selectedClient}
            onClose={handleCloseAccountExecutive}
          />
        </BodyGridWrapper>
      </Grid>
    </Box>
  );
}

export default InformativeComponent;
