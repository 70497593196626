export const invoiceReportRows = [
  {
    id: 1,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 2,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 3,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 4,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 5,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 6,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 7,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 8,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 9,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
  {
    id: 10,
    status: "Open",
    orderNumber: "PO1039035",
    orderDate: "12/13/2021",
    invoiceDate: "12/13/2021",
    invoice: 813570,
    originalAmount: 813570,
    balanceDue: 813570,
    description: "-",
  },
];
