import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Formik, Form } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import Icons from "../../icons";
import CloseIcon from "../../icons/CloseIcon";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNotification } from "../../../context/notificationContext";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { columnsParser } from "./../helpers/columnsOptions";
import { optionsParser } from "./../helpers/filterOptions";
import { monthDates, scheduleType, scheduleValueParser, weekDays } from "./../helpers/scheduleHelper";
import { useNavigate } from "react-router-dom";
import reportsAPI from "../../../api/modules/reports";
import * as Yup from "yup";
import MixpanelHelper from "../../../Helpers/MixpanelHelper";
import { getSelectedCompany } from "../../../redux/features/user/userSlice";
import { useSelector } from "react-redux";

const styles = {
  errorMessages: {
    color: "#B80A26",
  },
}

const DateOverlay = styled.div`
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.6);
  display: ${({ hidden }) => (hidden ? "none" : "inital")};
`;

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #b80a26;
  margin-bottom: 32px;
  display: block;
`;

const Container = styled.div`
  padding: 48px 40px;
`;

const StLabelDescription = muiStyled(Box)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#444444",
}));
const StLabelTitle = muiStyled(Box)(({ theme }) => ({
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "20px",
  lineHeight: "32px",
  letterSpacing: "0.15px",
  color: "#444444",
}));

const MoneyIcon = muiStyled(AttachMoneyIcon)(({ theme }) => ({
  width: 16,
  color: "#444444",
}));

const FilterTextField = muiStyled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));

const ScheduleDialogComponent = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    scheduleValues,
    setScheduleValues,
    selectedCompany,
    processType,
    type,
    optionValues,
    currentColumns,
    reportId,
  } = props;

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [schedule, setSchedule] = useState("daily");
  const { setNotification } = useNotification();
  const navigate = useNavigate();

  const [overlay, setOverlay] = useState({
    invoiceDate: false,
    passedDue: false,
    orderDate: false,
  });

  const handleClose = () => {
    onClose(selectedValue);
  };

  const validationSchema = Yup.object({
    sendTo: Yup.string().email("Email is invalid").required("Email is required"),
    reportName: Yup.string().required("Report unique name is required"),
    subject: Yup.string().required("Subject is required"),
    startDate: Yup.date().nullable().notRequired()
    .test(
      "Is Start Date is present",
      "Start Date is required",
      (value) => {
        if (!value) return false;
        else return true;
      }
    ),
    endDate: Yup.date().nullable().notRequired()
    .test(
      "Is End Date is present",
      "End Date is required",
      (value) => {
        if (!value) return false;
        else return true;
      }
    )
  });

  const initialValues = {
    reportName: scheduleValues.reportName  ||"",
    sendTo: scheduleValues.sendTo || "",
    cc: scheduleValues.cc ||"",
    subject: scheduleValues.subject || "",
    every: scheduleValues.every || 1,
    frequency: scheduleValues.frequency || 1,
    startTime: scheduleValues.startTime,
    startDate: scheduleValues.startDate,
    endDate: scheduleValues.endDate,
    format: scheduleValues.format || 1,
  }

  const formatSubmitValues = (reportName, scheduleValuesForm) => {
    return {
      name: reportName,
      type: 1,
      group: type === "Orders" ? 1 : 2,
      accounts: selectedCompany,
      columns: columnsParser(type === "Invoice" ? 2 : 1, currentColumns), //Come back
      options: optionsParser(optionValues),
      schedule: scheduleValueParser(scheduleValuesForm),
    };
  };
  
  const onSubmit = async (values) => {
    try {
      const submitData = formatSubmitValues(values.reportName, values);
      if (processType === "edit") {
        const response = await reportsAPI.editPreset(reportId, submitData);
        if (response.data.success === false) {
          throw response;
        }
        setNotification({
          open: true,
          message: (
            <>
              <b>{values.reportName}</b>
              {" Report has been Re-Scheduled"}
            </>
          ),
        });
      }
      if (processType === "new" || processType === "load") {
        const response = await reportsAPI.savePreset(submitData);
        if (response.data.success === false) {
          throw response;
        }
        setNotification({
          open: true,
          message: (
            <>
              <b>{values.reportName}</b>
              {" Report has been saved and scheduled!"}
            </>
          ),
        });
      }

      const company = selectedCompanyRedux?.[0]?.name ?? 'All';
      MixpanelHelper.track("Reports", { 'Action': 'Scheduled Report', 'Report Name': values.reportName });
      MixpanelHelper.peopleIncrement("Reports Scheduled", 1);

      handleClose();

      return navigate("/reports/");
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
        response?.data?.message ? response.data.message :  "An error occured. Please try again.",
        type: "error",
      });
    }
  };

  const handleSchedule = (scheduleValue) => {
    if (scheduleValue === scheduleType["weekly"]) {
      setSchedule("weekly");
    } else if (scheduleValue === scheduleType["monthly"]) {
      setSchedule("monthly");
    } else {
      setSchedule("daily");
    }
  }

  useEffect(() => {
    handleSchedule(scheduleValues.frequency);
  },[scheduleValues]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={"lg"}
      scroll="body"
    >
      <DialogTitle style={{ padding: 0 }} sx={{ padding: 0 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Container>
        <Title> Schedule Report</Title>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, values, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FilterTextField
                    label="Report Name"
                    name="reportName"
                    value={values.reportName}
                    onChange={handleChange("reportName")}
                    disabled={processType === "edit"}
                    helperText={errors.reportName ?? ""}
                    FormHelperTextProps={{ style: styles.errorMessages }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <StLabelTitle>Select Mailing List</StLabelTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <FilterTextField
                        label="Send To"
                        name="sendTo"
                        value={values.sendTo}
                        onChange={handleChange("sendTo")}
                        helperText={errors.sendTo ?? ""}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FilterTextField
                        label="CC"
                        name="cc"
                        value={values.cc}
                        onChange={handleChange("cc")}
                        helperText={errors.cc ?? ""}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FilterTextField
                        label="Subject"
                        name="subject"
                        value={values.subject}
                        onChange={handleChange("subject")}
                        helperText={errors.subject ?? ""}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12}>
                      <StLabelTitle>Select Schedule</StLabelTitle>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Select when the report will run
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={values.frequency}
                          label="Select when the report will run"
                          onChange={handleChange("frequency")}
                        >
                          <MenuItem value={1} onClick={() => {setSchedule("daily")}}>Daily</MenuItem>
                          <MenuItem value={2} onClick={() => {setSchedule("weekly")}}>Weekly</MenuItem>
                          <MenuItem value={3} onClick={() => {setSchedule("monthly")}}>Monthly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        { !(schedule === "daily")? (
                          <Grid item xs={6}>
                            <FormControl fullWidth>
                              <InputLabel id="demo-simple-select-label">
                                {schedule === "weekly"? "Every": "Day of Month"}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={values.every}
                                label={schedule === "weekly"? "Every": "Day of Month"}
                                onChange={handleChange("every")}
                                MenuProps={{
                                  style:{
                                    maxHeight: "275px"
                                  }
                                }}
                              >
                                { schedule === "weekly"? (
                                  weekDays.map(({label, value}) => (
                                    <MenuItem key={value} value={value}>{label}</MenuItem>
                                  ))):(
                                    monthDates.map((value) => (
                                      <MenuItem key={value} value={value}>{value}</MenuItem>
                                  )))
                                }
                              </Select>
                            </FormControl>
                          </Grid> ) : null 
                        }
                        <Grid item xs={schedule === "daily"? 12 : 6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <TimePicker
                              onChange={(value) => {
                                setFieldValue("startTime", value, true);
                              }}
                              value={values.startTime}
                              label="Start Time"
                              renderInput={(params) => (
                                <FilterTextField
                                  name="startTime"
                                  fullWidth
                                  {...params}
                                />
                              )}
                              components={{
                                OpenPickerIcon: Icons.DatePickerIcon,
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={(value) =>
                                setFieldValue(
                                  "startDate",
                                  dayjs(value).format("YYYY-MM-DD"),
                                  true
                                )
                              }
                              value={values.startDate}
                              label="From"
                              renderInput={(params) => (
                                <FilterTextField
                                  helperText={errors.startDate ?? ""}
                                  FormHelperTextProps={{ style: styles.errorMessages }}
                                  name="startDate"
                                  fullWidth
                                  {...params}
                                />
                              )}
                              components={{
                                OpenPickerIcon: Icons.DatePickerIcon,
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              onChange={(value) =>
                                setFieldValue(
                                  "endDate",
                                  dayjs(value).format("YYYY-MM-DD"),
                                  true
                                )
                              }
                              value={values.endDate}
                              label="To"
                              renderInput={(params) => (
                                <FilterTextField
                                  name="endDate"
                                  helperText={errors.endDate ?? ""}
                                  FormHelperTextProps={{ style: styles.errorMessages }}   
                                  fullWidth
                                  {...params}
                                />
                              )}
                              components={{
                                OpenPickerIcon: Icons.DatePickerIcon,
                              }}
                            />
                          </LocalizationProvider>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}></Grid>
                  </Grid>
                </Grid>

                <Grid item xs={9}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Render Format
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={values.format}
                      label="Render Format"
                      onChange={handleChange("format")}
                    >
                      <MenuItem value={1}>PDF</MenuItem>
                      <MenuItem value={2}>Excel</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="blueGradient-outlined"
                      type="submit"
                      size="large"
                      sx={{
                        width: "100%",
                        height: "56px",
                        borderRadius: "0px",
                      }}
                    >
                      SCHEDULE AND SAVE
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Dialog>
  );
};
export default ScheduleDialogComponent;
