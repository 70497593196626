import { CircularProgress, Dialog, IconButton } from '@mui/material';
import orderInvoicesAPI from '../../api/modules/orderInvoices';
import React, { useEffect, useState } from 'react';
import CloseIcon from '../icons/CloseIcon';
import download from 'downloadjs';
import { useNotification } from '../../context/notificationContext';

const PdfDialog = (props) => {
  const { open, onClose, selectedValue, selectedFilter, LoadingContainer } =
    props;

  const [isLoading, setIsLoading] = useState(true);
  const { setNotification } = useNotification();

  const handleClose = () => {
    onClose();
  };

  const documentNumber = selectedValue.invoiceNo;
  const getPdfFile = async (documentNumber) => {
    try {
      setIsLoading(true);
      const response = await orderInvoicesAPI.getInvoicePdf(
        documentNumber,
        selectedFilter
      );
      const blob = new Blob([response.data], { type: 'application/pdf' });
      download(blob, `Invoice-${documentNumber}.pdf`);
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? 'Pdf does not exist for this invoice',
        type: 'error',
      });
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  useEffect(() => {
    if (documentNumber && open) {
      getPdfFile(documentNumber);
    }
  }, [documentNumber, open]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="sm">
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {isLoading ? (
        <LoadingContainer style={{ marginLeft: '6%' }}>
          <CircularProgress style={{ color: '#B80A26' }} size={60} />
        </LoadingContainer>
      ) : null}
    </Dialog>
  );
};
export default PdfDialog;
