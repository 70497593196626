import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";

const CompanyLabel = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const CompanyText = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ color }) => color ?? "#000000"};
  margin: 0 3px;
`;

const CompanyContainer = styled(Grid)`
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin-bottom: 12px;
  padding: 0;
  justify-content: stretch !important;
  align-items: stretch !important;
`;

const CompanyName = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const CompanyGrid = styled(Grid)`
  padding: 40px 32px;
`;

const ImageGrid = styled(Grid)`
  border-left: 1px solid #eeeeee;

  @media (max-width: 899px) {
    margin-bottom: 32px !important;
  }
`;

export const AeInfo = ({ name, email, phoneNumber }) => {
  return (
    <CompanyContainer container alignItems="center" justifyContent="center">
      <CompanyGrid item md={8} xs={12}>
        <CompanyName>{name}</CompanyName>
        <div style={{ margin: "4px 0" }}>
          <CompanyLabel>Address:</CompanyLabel>
          <CompanyText>{email}</CompanyText>
        </div>
        <div style={{ margin: "4px 0" }}>
          <CompanyLabel>Phone Number:</CompanyLabel>
          <CompanyText color="#B80A26">{phoneNumber}</CompanyText>
        </div>
      </CompanyGrid>
    </CompanyContainer>
  );
};
