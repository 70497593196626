import mixpanel from "mixpanel-browser";

export default class MixpanelHelper {
  static isProduction = () => {
    return process.env.REACT_APP_ENV === "production";
  };

  static initialize = () => {
    console.log(process.env.REACT_APP_ENV);
    if (this.isProduction()) {
      mixpanel.init("bcc39e257368bf0527cd450396367301", { debug: true });
    }
  };

  static setUser = (user) => {
    if (this.isProduction()) {
      mixpanel.identify(user.id);

      mixpanel.people.set({
        $email: user.email,
        $name: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role?.name,
        env: process.env.NODE_ENV,
      });
    }
  };

  static track = (eventName, properties = {}) => {
    if (this.isProduction()) {
      mixpanel.track(eventName, properties);
    }
  };

  static peopleIncrement = (property, value) => {
    if (this.isProduction()) {
      mixpanel.people.increment(property, value);
    }
  };

  static trackPageView = (pageName) => {
    if (this.isProduction()) {
      mixpanel.track('Page View', { page: pageName });
      mixpanel.people.increment('Page Views', 1);
    }
  }
}
