import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../redux/features/user/userSlice';
import { getToken } from '../Helpers/auth';
import AccountExecutiveDashboard from '../pages/AccountExecutiveDashboard';
import ClientOwnerDashboard from '../pages/ClientOwnerDashboard';
import AdminDashBoard from '../pages/AdminDashboard';
import OrderInvoicesDashboard from '../pages/OrderInvoicesDashboard';

export const AuthLayoutRoute = () => {
  const userRedux = useSelector(selectUser);

  if (userRedux.id === null || getToken() === null) {
    return <Navigate to={'/'} replace />;
  }

  if (
    userRedux.role?.name === 'ADMIN' ||
    userRedux.role?.name === 'SUPER_ADMIN'
  ) {
    return <AdminDashBoard />;
  }

  if (userRedux.role?.name === 'ACCOUNT_EXECUTIVE') {
    return <AccountExecutiveDashboard />;
  }

  if (
    userRedux.role?.name === 'CLIENT_ACCOUNT_OWNER' ||
    userRedux.role?.name === 'CLIENT_ADMIN'
  ) {
    return <ClientOwnerDashboard />;
  }

  if (userRedux.role?.name === 'CLIENT_STANDARD_USER') {
    // Workaround
    return <OrderInvoicesDashboard />;
  }

  return <AdminDashBoard />;
};
