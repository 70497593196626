import axios from 'axios';
import { deleteToken, getToken } from '../Helpers/auth';

// NOTE: REACT_APP_API_URL is added to docker during build so
// if you wish to change it on azure servers, then please edit the
// file azure-pipelines.yml
const API_URL = process.env.REACT_APP_API_URL + 'api/v1';

export const authInterceptor = [
  (config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
];

export const Service = (url) =>
  axios.create({
    baseURL: API_URL + url,
  });

export const AuthorizedService = (url) => {
  const authorizedService = Service(url);
  authorizedService.interceptors.request.use(...authInterceptor);

  authorizedService.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        deleteToken();
        window.location.replace('/');
      }

      return Promise.reject(error);
    }
  );

  return authorizedService;
};
