import { Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import styled from "styled-components";
import { formatNumber } from "../../../Helpers/formatters";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ordersIdsToName, invoicesIdsToName } from "../helpers/columnsOptions";
import { filtersIdsToName, filtersValueParser } from "../helpers/filterOptions";
import { useSelector } from "react-redux";
import {
  getSelectedCompany,
  selectUser,
} from "../../../redux/features/user/userSlice";

const Container = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
  margin-bottom: 24px;
  color: teal;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 28px;
  border-bottom: 1px solid #e0e0e0;
`;

const SmallLabel = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #444444;
`;

const MediumLabel = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 6px;
  text-transform: uppercase;
`;

const PresetName = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 25px;
  color: #44444;
`;

const Edit = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #b80a26;
  margin-right: 35px;
`;

const ValueLabel = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #444444;
`;
const ValueLabelBold = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #444444;
`;

const Body = styled.div`
  padding: 24px 28px 42px 28px;
`;

const PresetReport = ({ preset }) => {
  const { id, name: presetName, group, columns, options } = preset;
  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const userRedux = useSelector(selectUser);
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Container>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              <>
                <ExpandMoreIcon />
              </>
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box flexDirection="column" display="flex">
                <SmallLabel>
                  {group === 1 ? "Orders" : "Invoices"} Preset
                </SmallLabel>
                <PresetName>{presetName}</PresetName>
              </Box>

              <Box sx={{ marginRight: "10px" }}>
                {userRedux.role.name === "ADMIN" ||
                userRedux.role.name === "SUPER_ADMIN" ? (
                  <NavLink
                    to={`/build-report/edit-${id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <Edit>Edit</Edit>
                  </NavLink>
                ) : null}

                <NavLink
                  to={`/build-report/load-${id}`}
                  style={{ textDecoration: "none" }}
                >
                  <Button
                    variant="blueGradient-outlined"
                    type="submit"
                    sx={{
                      borderRadius: "0px",
                      fontFamily: "Montserrat",
                      fontStyle: "normal",
                      fontWeight: 600,
                      fontSze: "14px",
                      lineHeight: "26px",
                      textTransform: "unset !important",
                    }}
                  >
                    LOAD
                  </Button>
                </NavLink>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <MediumLabel>Columns</MediumLabel>
                  <hr
                    style={{
                      border: "1px solid #E0E0E0",
                      width: "100%",
                    }}
                  ></hr>
                  {columns.map((column, counter) => {
                    return (
                      <div>
                        <span style={{ fontWeight: 700 }}>{counter + 1} </span>
                        &nbsp;
                        <ValueLabel>
                          {group === 1
                            ? ordersIdsToName[column]
                            : invoicesIdsToName[column]}
                        </ValueLabel>
                      </div>
                    );
                  })}
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box display="flex" flexDirection="column">
                  <MediumLabel>Options</MediumLabel>
                  <hr
                    style={{
                      border: "1px solid #E0E0E0",
                      // marginTop: "40px",
                      width: "100%",
                    }}
                  ></hr>
                  <Grid container spacing={0}>
                    {options.map((option) => {
                      return (
                        <>
                          <Grid item xs={5}>
                            <ValueLabelBold>
                              {filtersIdsToName[option.key]}{" "}
                            </ValueLabelBold>
                          </Grid>
                          <Grid item xs={7}>
                            <ValueLabel>
                              {filtersValueParser(option.key, option.value)}
                            </ValueLabel>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Container>
    </>
  );
};
export default PresetReport;
