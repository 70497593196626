import { Dialog, IconButton } from '@mui/material';
import React from 'react'
import CloseIcon from '../icons/CloseIcon';

const BugReportingDialog = (props) => {
  const {open, onClose, type} = props;
  const handleClose = () => {
    onClose();
  }

  const url = type === 'bug'?
    'https://www.wrike.com/form/eyJhY2NvdW50SWQiOjI4NTE4NjUsInRhc2tGb3JtSWQiOjgwMjY1OX0JNDgyNjU2MzgwNDExNgk4NDE2YTFiYzIzMzE3ODMyMDk2OWIyOWExMDdlYjgzNWM4ZTUxNDVjMjFhNzBmMWI0NmE0YzU2ODVlZDc0NTFm'
  : 'https://www.wrike.com/form/eyJhY2NvdW50SWQiOjI4NTE4NjUsInRhc2tGb3JtSWQiOjgwMzkyMH0JNDgyNzA3NTk1NDc3MAljZmU3ODA3NThjMWViM2Q5NTM4ZDllZDk1ZmUwMjA0OWY4MWRkNDg5OTUwN2JkZWYwNDVkY2IzNzBjNTMwMmQ1';

  return (
    <Dialog onClose={handleClose} open={open} maxWidth="md">
      {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
            <CloseIcon />
            </IconButton>
          ) : null}
      {true ? (
      <iframe title="wrikeFrame" width="768" height="890" frameBorder="0" src={url} style={{zIndex:2}}>
      </iframe>
      ): null
    }
    </Dialog>
  )
}

export default BugReportingDialog;
