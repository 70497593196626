import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { NavLink } from "react-router-dom";
import { SignInForm, Sign2FAForm } from "./";
import { styles } from "./styles";

export const SignInComponent = () => {
  const [show2FA, setShow2FA] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleOnSubmit = (email) => {
    setUserEmail(email);
    setShow2FA(true);
  };

  return (
    <Box sx={styles.formContainer}>
      <Stack spacing={2}>
        <div style={styles.signInTypo}>{"Sign In"}</div>

        {show2FA ? (
          <Sign2FAForm email={userEmail} />
        ) : (
          <SignInForm handleOnSubmit={handleOnSubmit} />
        )}

        <hr style={styles.dotted}></hr>
        <div
          style={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "14px",
          }}
        >
          <NavLink style={styles.navLink} to={"/signup"}>
            <span
              style={{
                color: "#B80A26",
              }}
            >
              Request your personal login credentials
            </span>{" "}
          </NavLink>
          if your company has an account with MicroAge.
        </div>
      </Stack>
    </Box>
  );
};
