import { PluginService } from '../pluginService';

const logService = PluginService('');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getLogs(params) {
    return logService({
      method: 'GET',
      url: `/logs`,
      params,
    });
  },
  exportToXlsx(params) {
    return logService({
      method: 'GET',
      url: `/logs/export`,
      responseType: 'blob',
      params,
    });
  },
};
