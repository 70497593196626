import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  DialogTitle,
  Dialog,
  IconButton,
  Button,
} from "@mui/material";
import orderInvoicesAPI from "../../api/modules/orderInvoices";
import { toCurrency, validateNullValue } from "../../Helpers/formatters";
import CloseIcon from "../icons/CloseIcon";
import styled from 'styled-components';
import { useNotification } from "../../context/notificationContext";
import { detailsDataRemoveRowsWithDescriptionWords } from "./helpers/description";
import MixpanelHelper from "../../Helpers/MixpanelHelper";
import { useSelector } from "react-redux";
import { getSelectedCompany } from "../../redux/features/user/userSlice";

const UnderlinedText = styled.span`
  cursor: pointer;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: ${({ color = '#B80A26' }) => color};
`;

const styles = {
  showMoreButton: {
    borderRadius: "1px",
    fontFamily: "Montserrat",
    fontStyle: "italic",
    fontWeight: 500,
    fontSize: "13px",
    letterSpacing: "0px",
    textTransform: "unset !important",
  },
}

const columnFilters = {
  orders: {
    description: true,
    description2: true,
    trackingNo: false,
    serialNo: true,
    unitType: true,
    unitCost: true,
    quantity: true,
    manufacturerName: true,
  },
  invoices: {
    description: true,
    description2: true,
    trackingNo: true,
    serialNo: true,
    unitType: true,
    unitCost: true,
    quantity: true,
    manufacturerName: true,
  },
};

const DescriptionDialog = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    filter,
    CustomColorDataGrid,
    LoadingContainer,
  } = props;
  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [status, type] = filter.split("_");
  const { setNotification } = useNotification();
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandNumbers, setExpandNumbers] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columnFilters["invoices"]
  );
  
  const isExpanded = (lineNo) => expandNumbers.find(number => number === lineNo);
  const removeExpand = (lineNo) => expandNumbers.filter(number => number !== lineNo);

  const handleExpand = (lineNo) => {
    isExpanded(lineNo)? setExpandNumbers([...removeExpand(lineNo)]) : setExpandNumbers([...expandNumbers, lineNo])
  }

  const alternateCopyToClipboard = (trackingNumber) => {
		const textArea = document.createElement("textarea");
		textArea.value = trackingNumber;
		document.body.appendChild(textArea);
		textArea.focus({ preventScroll: true });
		textArea.select();
		document.execCommand('copy');
		document.body.removeChild(textArea);
	}

	const handleCopyTrackingNumber = (trackingNumber) => {
		try {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(trackingNumber);
		  } else {
		    alternateCopyToClipboard(trackingNumber);
		  }
      setNotification({
        open: true,
        message: (
          <>
            <b>Tracking Number</b>{" has been copied to clipboard."}
          </>
        ),
      });

      const company = selectedCompanyRedux?.[0]?.name ?? 'All';
      MixpanelHelper.track("Tracking Number", { "Tracking Number": trackingNumber, company: company });
      MixpanelHelper.peopleIncrement("Tracking Number", 1);
	  } catch (err) {
			console.log("Unable to copy to clipboard",err);
		}
  }

  const parseSerialNumbers = (serialNumbers, lineNo) => {
  
    if (isExpanded(lineNo)) {
      return serialNumbers.join(", ");
    } else {
      return serialNumbers[0];
    }
  
  }

  const handleClose = () => {
    onClose(selectedValue);
  };

  const columnsDialog = [
    { headerName: "Description", field: "description", minWidth: 120, maxWidth: 500, flex: 1,valueGetter: ({ row }) =>  validateNullValue(row.description, false), },
    { headerName: "Description 2", field: "description2", minWidth: 120, maxWidth: 500, flex: 1,valueGetter: ({ row }) =>  validateNullValue(row.description2, false), },
    { 
      headerName: "Tracking Number", 
      field: "trackingNo",
      minWidth: 130,
      maxWidth: 200,
      flex: 1,
      renderCell: ({ row }) => (
        row.trackingNo ? (
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'start' }}>
            {row.trackingNo.split(",").map((trackingNumber) => {
              return (
                <div key={trackingNumber} style={{ marginBottom: 10 }}>
                  <UnderlinedText onClick={() => handleCopyTrackingNumber(trackingNumber)}>
                    {validateNullValue(trackingNumber, false)}
                  </UnderlinedText>
                </div>
              )
            })}
          </div>
        ) : validateNullValue(row.trackingNo, false)
      ),
     },
    { 
      headerName: "Serial Numbers",
      field: "serialNo",
      minWidth: 300,
      maxWidth: 800,
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
          {row.serialNo.length? parseSerialNumbers(row.serialNo, row.lineNo): "Not Available"}
          {row.serialNo.length > 1 ? (
            <Button
              color="customRed"
              sx={styles.showMoreButton}
              onClick={() => {handleExpand(row.lineNo)}}
            >
              {isExpanded(row.lineNo)? 'Show less' : 'Show More'}
            </Button>
            ) : null
          }
          </>
        );
      },
    },
    { headerName: "Unit Type", field: "unitType", minWidth: 70, maxWidth: 90, flex: 1,valueGetter: ({ row }) =>  validateNullValue(row.unitType, false), },
    { headerName: "Unit Cost", field: "unitCost", minWidth: 70, maxWidth: 110, flex: 1,valueGetter: ({ row }) => toCurrency(row.unitCost), },
    { headerName: "Quantity", field: "quantity", minWidth: 70, maxWidth: 80, flex: 1,valueGetter: ({ row }) =>  row.quantity ?? 0 },
    {
      headerName: "Manufacturer Name",
      field: "manufacturerName",
      minWidth: 120,
      maxWidth: 165,
      flex: 1,
      valueGetter: ({ row }) =>  validateNullValue(row.manufacturerName, false),
    },
  ];

  const documentNo =
    type === "orders" ? selectedValue.documentNo : selectedValue.invoiceNo;

  const title = 
    type === "orders" ? "Order Number" : "Invoice Number";

  const actualNumber =
    type === "orders" ? selectedValue.orderNo : selectedValue.invoiceNo;

  const getDetails = async (documentNo) => {
    try {
      setIsLoading(true);
      setColumnVisibilityModel(columnFilters[type]);
      const { data } =
        type === "orders"
          ? await orderInvoicesAPI.getOrdersDetails(documentNo)
          : await orderInvoicesAPI.getInvoiceDetails(documentNo);
      const details =
        type === "orders" ? data.orderDetails : data.invoiceDetails;
      const detailsFiltered = detailsDataRemoveRowsWithDescriptionWords(details);
      
      setRows(detailsFiltered);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (documentNo && open) {
      getDetails(documentNo);
    }
  }, [documentNo, open]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="xl">
      <DialogTitle>
        {title}: {actualNumber}
        {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem onClick={() => {}}>
          {isLoading ? (
            <LoadingContainer style={{ marginLeft: "47%" }}>
              <CircularProgress style={{ color: "#B80A26" }} size={60} />
            </LoadingContainer>
          ) : (
            <CustomColorDataGrid
              columnVisibilityModel={columnVisibilityModel}
              defaultGroupingExpansionDepth={1}
              sx={{ marginTop: "10px", width: "500px", height: "400px" }}
              rows={rows}
              columns={columnsDialog}
              getRowHeight={() => "auto"}
              getRowId={(row) => row.lineNo}
            />
          )}
        </ListItem>
      </List>
    </Dialog>
  );
};

export default DescriptionDialog;
