import { PluginService } from '../pluginService';
import { createFilterUrl } from '../helper/manageFilter';

const accountService = PluginService('');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAccountsBC(params) {
    const { filter, skip, top } = params;
    const filterUrl = filter
      ? createFilterUrl(filter.field, filter.filterType, filter.value)
      : '?';
    const topUrl = top ? `&$top=${top}` : '';
    const skipUrl = skip ? `&$skip=${skip}` : '';
    return accountService({
      method: 'GET',
      url: `/bc/BC180ac/ODataV4/Company('MicroAge')/QueryCustomers${filterUrl}${topUrl}${skipUrl}`,
    });
  },
  getAccounts(params) {
    return accountService({
      method: 'GET',
      url: `/accounts`,
      params,
    });
  },

  getAEAccounts(params) {
    return accountService({
      method: 'GET',
      url: `/ae-accounts/${params.aemail}`,
      params,
    });
  },

  getUserAccounts(params) {
    return accountService({
      method: 'GET',
      url: `/user-accounts`,
      params,
    });
  },
};
