import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import { CompanyInfo } from "../Company/CompanyInfo";
import { styles as signInStyles } from "../../components/SignIn/styles";
import { CompanySelection } from "../Company/CompanySelection";
import { ClientInformationForm } from "../Client/ClientInformationForm";
import {
  clientPermissions,
  clientRoles,
  convertClientPermisionsToNames,
  convertClientPermissionsToIds,
  convertClientRoleToId,
} from "../../Helpers/clientRolesPermissions";
import { useNotification } from "../../context/notificationContext";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import authAPI from "../../api/modules/authentication";
import userAPI from "../../api/modules/users";
import { useAuth } from "../../context/authContext";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/user/userSlice";
const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #b80a26;
  margin-bottom: 24px;
  display: block;
`;

const Container = styled.div`
  padding: 32px 70px;
`;

const Red = styled.b`
  color: #b80a26;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0;
`;

const Body = styled.div``;

export const ClientUserModal = ({
  open = false,
  client,
  onClose,
  onSubmit,
}) => {
  const [selectedCompany, setSelectedCompany] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [payloadAction, setPayloadAction] = useState({
    action: "",
    payload: {},
  });
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userRedux = useSelector(selectUser);
  const { checkRolesAndPermissions } = useAuth();

  const initialFormValues = {
    companyRole: "",
    email: "",
    firstName: "",
    lastName: "",
    permissions: ["READ_ONLY"],
    userRole: clientRoles.clientUser.default,
    accountExecutives: [],
  };

  const [initialValues, setInitialValues] = useState(initialFormValues);
  const { setNotification } = useNotification();

  const titleMessage = client ? "Edit Client" : "Invite New Client";
  const { action, payload } = payloadAction;

  const handleClose = () => {
    onClose();
    setInitialValues(initialFormValues);
    setCurrentStep(1);
    setSelectedCompany(null);
  };

  const handleOnItemClick = (company) => {
    setSelectedCompany(company);
  };

  const handleOnBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      setSelectedCompany(null);
      onClose();
    }
  };

  const handleOnAfterSubmit = async () => {
    handleClose();
    onSubmit();
    setTimeout(() => {
      setIsConfirmOpen(false);
    }, 0);
  };

  const handleOnSubmit = async (values, { setSubmitting }) => {
    try {
      await checkRolesAndPermissions();

      const role = convertClientRoleToId(userRedux.roles, values.userRole);
      const permissions = convertClientPermissionsToIds(
        userRedux.permissions,
        values.permissions
      );

      const data = {
        permissions,
        role,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        companyRole: values.companyRole,
        account: selectedCompany.No_,
        accountExecutives: [],
      };

      if (client) {
        setPayloadAction({
          action: "edit",
          payload: data,
        });

        return setIsConfirmOpen(true);
      }

      await authAPI.inviteClientOwner(data);

      setNotification({
        open: true,
        message: (
          <>
            <b>
              {values.firstName} {values.lastName}
            </b>
            {" was invited!"}
          </>
        ),
      });

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };

  const handleRemoveUser = async () => {
    setPayloadAction({
      action: "delete",
      payload: client,
    });

    setIsConfirmOpen(true);
  };

  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      if (action === "edit") {
        await userAPI.editClient({ id: client.id }, payload);

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.firstName} {payload.lastName}
              </b>
              {" user has been updated."}
            </>
          ),
        });
      } else if (action === "delete") {
        await userAPI.deleteUser({ id: payload.id });

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.firstName} {payload.lastName}
              </b>
              {" has been removed from the system."}
            </>
          ),
        });
      }

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const getUserInfo = async () => {
    try {
      setIsLoading(true);

      const { data } = await userAPI.getUserInfo(client.id);
      const user = data.data;

      const permissions = convertClientPermisionsToNames(
        userRedux.permissions,
        user.permissions ?? []
      );

      setInitialValues({
        ...user,
        permissions: permissions,
        userPermissions: permissions,
        userRole: user.role.name,
      });

      setSelectedCompany({
        ...client.accounts,
        Name: client.accounts.company,
        Phone_No_: client.accounts.phoneNo,
        Address: client.accounts.companyAddress1,
        No_: client.accounts.id,
      });
      setCurrentStep(2);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (client) {
      getUserInfo();
    }

    return () => {
      setInitialValues(initialFormValues);
    };
  }, [client]);

  const dialogText =
    action === "edit" ? (
      "If you continue, you cannot undo this action."
    ) : (
      <>
        Are you sure you want to remove{" "}
        <Red>
          {payload?.firstName} {payload?.lastName}
        </Red>
        . This action cannot be undone.{" "}
      </>
    );

  if (!open) return <></>;

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={action === "delete" ? "Delete" : "Okay"}
        isSubmitting={isSubmitting}
        title={
          action === "edit"
            ? "Are you sure you want to continue?"
            : "Remove Account"
        }
        text={dialogText}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        hidden={isConfirmOpen}
      >
        <Container>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Title>{titleMessage} </Title>

          <Body>
            {selectedCompany && (
              <div style={{ marginBottom: 32 }}>
                <CompanyInfo {...selectedCompany} />
              </div>
            )}

            {isLoading ? (
              <Box>
                <LoadingContainer>
                  <CircularProgress style={{ color: "#B80A26" }} />
                </LoadingContainer>
              </Box>
            ) : currentStep === 1 ? (
              <>
                <CompanySelection
                  onSelectCompany={handleOnItemClick}
                  selectedCompany={selectedCompany}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="text"
                    color="customRed"
                    onClick={handleOnBack}
                    sx={signInStyles.textUnset}
                  >
                    {"< Back"}
                  </Button>
                  <Button
                    type="submit"
                    variant="blueGradient-outlined"
                    onClick={() => setCurrentStep(2)}
                    sx={signInStyles.submitButton}
                    disabled={!selectedCompany}
                  >
                    Next
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <ClientInformationForm
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  setClientPermissions={() => {}}
                  handleOnBack={handleOnBack}
                  handleOnSubmit={handleOnSubmit}
                  clientRoles={clientRoles.clientUser}
                  clientPermissions={clientPermissions}
                  handleRemoveUser={handleRemoveUser}
                  isEditting={client && true}
                />
              </>
            )}
          </Body>
        </Container>
      </Dialog>
    </>
  );
};
