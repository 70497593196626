const TOKEN_KEY = 'token';

export const setToken = (token)  => {
  localStorage.setItem(TOKEN_KEY, token);
}

export const getToken = () => {
  const token = localStorage.getItem(TOKEN_KEY);
  if (!token) return null;

  return token;
}

export const deleteToken = ()  => {
  localStorage.removeItem(TOKEN_KEY);
}

