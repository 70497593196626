import React, { useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getSelectedCompany, selectUser } from "../../../redux/features/user/userSlice";
import SaveIcon from '@mui/icons-material/Save';
import { styled as muiStyled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field, Formik, Form } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import Icons from "../../icons";
import CloseIcon from "../../icons/CloseIcon";
import reportsAPI from "../../../api/modules/reports";
import { useNotification } from "../../../context/notificationContext";
import { columnsParser } from "./../helpers/columnsOptions";
import { optionsParser } from "./../helpers/filterOptions";
import { scheduleValueParser } from "./../helpers/scheduleHelper";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import MixpanelHelper from "../../../Helpers/MixpanelHelper";

const styles = {
  errorMessages: {
    color: "#B80A26",
  },
  savePresetButton: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    letterSpacing: "1px",
    textTransform: "unset !important",
    color: "#b80a26",
    cursor: "pointer",
  }
}

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #b80a26;
  margin-bottom: 32px;
  display: block;
`;

const Container = styled.div`
  padding: 48px 40px;
`;

const FilterTextField = muiStyled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));

const SaveDialogComponent = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    currentColumns,
    type,
    optionValues,
    selectedCompany,
    scheduleValues,
    reportId,
    processType,
    reportName,
  } = props;

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const { setNotification } = useNotification();
  const userRedux = useSelector(selectUser);
  const [isPreset, setIsPreset] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleSaveAsPreset = () => { 
    setIsPreset(true);
  }

  const initialFormValues = {
    reportName: reportName || "",
  };
  
  const validationSchema = Yup.object({
    reportName: Yup.string().required("Report unique name is required"),
  });

  const formatSubmitValues = ({ reportName }) => {

    if (scheduleValues.sendTo) {
      return {
        name: reportName,
        type: isPreset? 2 : 1,
        group: type === "Orders" ? 1 : 2,
        accounts: selectedCompany,
        columns: columnsParser(type === "Invoice" ? 2 : 1, currentColumns), //Come back
        options: optionsParser(optionValues),
        schedule: scheduleValueParser(scheduleValues),
      };
    } else {
      return {
        name: reportName,
        type: isPreset? 2 : 1,
        group: type === "Orders" ? 1 : 2,
        accounts: selectedCompany,
        columns: columnsParser(type === "Invoice" ? 2 : 1, currentColumns), //Come back
        options: optionsParser(optionValues),
      };
    }
  };

  const onSubmit = async (values) => {
    try {
      if (processType === "edit") {
        const submitData = formatSubmitValues({ reportName });
        const response = await reportsAPI.editPreset(reportId, submitData);
        if (response.data.success === false) {
          throw response;
        }
        setNotification({
          open: true,
          message: (
            <>
              <b>{reportName}</b>
              {" Report has been updated!"}
            </>
          ),
        });
      }
      if (processType === "new" || processType === "load") {
        const submitData = formatSubmitValues(values);
        const response = await reportsAPI.savePreset(submitData);
        if (response.data.success === false) {
          throw response;
        }
        setNotification({
          open: true,
          message: (
            <>
              <b>{values.reportName}</b>
              {" Report has been saved!"}
            </>
          ),
        });
      }

      handleClose();

      const company = selectedCompanyRedux?.[0]?.name ?? 'All';
      MixpanelHelper.track("Reports", { 'Action': 'Save Report', 'Report Name': values.reportName, company: company });
      MixpanelHelper.peopleIncrement("Reports Saved", 1);

      return navigate("/reports/");
    } catch ({ response }) {
      setIsPreset(false);
      setNotification({
        open: true,
        message:
        response?.data?.message ? response.data.message :  "An error occured. Please try again.",
        type: "error",
      });
    }
  };

  const isAdminUser = userRedux.role.name === "SUPER_ADMIN" || userRedux.role.name === "ADMIN"? true : false;

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} scroll="body">
      <DialogTitle style={{ padding: 0 }} sx={{ padding: 0 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Container>
        <Title>Save Report</Title>

        <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({errors, values, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Box>
                    {reportName !== "" && processType === "edit" ? (
                      <FilterTextField
                        label="Report Name"
                        name="reportName"
                        value={reportName}
                        helperText={errors.reportName ?? ""}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        disabled
                      />
                    ) : (
                      <FilterTextField
                        label="Report Name"
                        name="reportName"
                        value={values.reportName}
                        onChange={handleChange("reportName")}
                        helperText={errors.reportName ?? ""}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                      />
                    )}
                    {errors.reportName? setIsPreset(false) : null}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    {isAdminUser? (
                      <Box justifyContent="flex-start">
                        <Button 
                          sx={styles.savePresetButton} 
                          type="submit"
                          onClick={handleSaveAsPreset}>
                          <SaveIcon />
                          Save As Preset
                        </Button> 
                      </Box> ) : <Box></Box>
                    }
                    <Box justifyContent="flex-end">
                      <Button
                        variant="blueGradient-outlined"
                        type="submit"
                        sx={{ borderRadius: "0px" }}>
                        SAVE
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Dialog>
  );
};
export default SaveDialogComponent;
