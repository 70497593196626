import React from 'react'

const MicroAgeWaves = () => {
  return (
    <svg className="svg" width="1440" height="328" viewBox="0 0 1440 328" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.3" d="M311.991 327.933C105.78 329.737 21.5165 294.452 4.06055e-05 287.345L0 0H1917.18L1920 253.632C1917.18 252.236 1814.74 230.335 1708.3 233.562C1601.87 236.789 1562.62 283.032 1413.61 287.345C1264.6 291.658 1057.71 247.923 890.738 252.236C723.766 256.549 518.203 326.13 311.991 327.933Z" fill="url(#paint0_linear_5655_60902)" />
      <path opacity="0.3" d="M375.739 295.92C98.5982 298.275 21.5481 248.133 0 241.218V0H1920V231.168C1917.18 229.809 1817.4 224.106 1710.81 227.246C1604.22 230.386 1484.97 266.771 1335.74 270.967C1186.51 275.164 1059.26 241.218 892.047 245.415C724.83 249.611 652.88 293.565 375.739 295.92Z" fill="url(#paint1_linear_5655_60902)" />
      <defs>
        <linearGradient id="paint0_linear_5655_60902" x1="83.4783" y1="7.80719e-07" x2="183.444" y2="611.763" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B80A26" />
          <stop offset="1" stopColor="#ECC277" />
        </linearGradient>
        <linearGradient id="paint1_linear_5655_60902" x1="83.4783" y1="7.04552e-07" x2="165.285" y2="554.757" gradientUnits="userSpaceOnUse">
          <stop stopColor="#B80A26" />
          <stop offset="1" stopColor="#ECC277" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default MicroAgeWaves;
