import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Image from "../images/micro_age_cover.png";
import Logo from "../images/micro_age_logo.png";
import crn_one from "../images/crn_one.png";
import crn_two from "../images/crn_two.png";
import crn_three from "../images/crn_three.png";
import Logo_name from "../images/Logo_name.svg";

function InformativeComponent({ crmMarginTop }) {
  const styles = {
    imageContainer: {
      display: "flex",
      height: "100%",
      maxHeight: "100%",
      minHeight: "83vh",
      backgroundImage: `url(${Image})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
    },
    logo: {
      height: "71px",
      width: "163px",
      marginTop: "7%",
    },
    typography_one: {
      fontFamily: "OpenSans",
      marginTop: "9%",
      fontWeight: 700,
      fontSize: "48px",
    },
    typography_two: {
      fontFamily: "OpenSans",
      marginBottom: "8%",
      fontWeight: 300,
      fontSize: "48px",
    },
    crn_one: {
      height: "82px",
      width: "44px",
      backgroundImage: `url(${crn_one})`,
    },
    crn_two: {
      height: "82px",
      width: "44px",
      backgroundImage: `url(${crn_two})`,
    },
    crn_three: {
      height: "82px",
      width: "40px",
      backgroundImage: `url(${crn_three})`,
    },
  };
  return (
    <div style={styles.imageContainer}>
      <Box
        style={{
          flexDirection: "column",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Box sx={{ flexGrow: 1 }} style={{ marginLeft: "7%" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <div style={styles.logo}>
                <img
                  src={Logo_name}
                  alt="logoName"
                  style={{ height: "71px" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={12}>
              <div style={styles.typography_one}>Welcome to MicroAge</div>
              <div style={styles.typography_two}>Run your business smarter</div>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          spacing={2}
          sx={{
            marginTop: crmMarginTop,
            marginLeft: "7%",
            marginBottom: "35px",
          }}
        >
          <Grid item>
            <div style={styles.crn_one}></div>
          </Grid>
          <Grid item>
            <div style={styles.crn_two}></div>
          </Grid>
          <Grid item>
            <div style={styles.crn_three}></div>
          </Grid>
          <Grid item>
            <div
              style={{
                display: "flex",
                alignItems: "initial",
                flexDirection: "column",
                paddingTop: "23px",
              }}
            >
              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 400,
                  fontSize: "14px",
                }}
              >
                {"3 Consecutive Years"}{" "}
              </div>

              <div
                style={{
                  fontFamily: "Montserrat",
                  fontWeight: 700,
                  fontSize: "14px",
                }}
              >
                {"Elite 150 Category"}{" "}
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default InformativeComponent;
