import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, IconButton, Stack } from '@mui/material';
import styled from 'styled-components';
import Moment from 'moment';
import { styles } from './styles';
import Icons from '../icons';
import RangeDatePicker from '../RangeDatePicker';
import userAPI from '../../api/modules/users';
import ShadowAccountSelectAE from './ShadowAccountSelectAE';
import { useNotification } from '../../context/notificationContext';

const Label = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const Email = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ color = '#000000' }) => color};
`;

const Container = styled.div`
  padding: 32px 70px;
`;

const ChooseDateText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-top: 20px !important;
`;

const SelectedDatesText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #444444;
`;

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #b80a26;
  margin-bottom: 24px;
  display: block;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  border-bottom: 1px solid #BDBDBD;
  margin: 14px 0;
`;

const HeadLabelText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #444444;
`;

const RedText = styled.span`
  color: #B80A26;
`;

const ShadowAccountExecutive = ({ clientId, open, onClose }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDates, setSelectedDates] = useState(null);
  const [newAssigment, setNewAssigment] = useState(true);
  const [shadowAccountsToAdd, setShadowAccountsToAdd] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newShadowAccount = {
    id: null,
    firstName: '',
    lastName: '',
    email: '',
    isEdittingUser: true,
    isEdittingAccounts: false,
    accounts: []
  };

  const [selectedShadowAE, setSelectedShadowAE] = useState([newShadowAccount]);

  const [selectedClient, setSelectedClient] = useState({
    firstName: '',
    email: '',
    lastName: '',
    fullName: '',
  });

  const { setNotification } = useNotification();

  const handleClose = () => {
    onClose();
  }

  const onChooseDate = (dates) => {
    setSelectedDates(dates);
  }

  const getShadowAccountInfo = async () => {
    try {
      const { data } = await userAPI.getUserInfo(clientId);

      setSelectedClient(data.data);

    } catch (error) {

    } finally {

    }
  }

  const onPrevStep = () => {
    if (currentStep === 1) {
      return handleClose();
    }

    setCurrentStep((prev) => prev - 1);
  }

  const onNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  }

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      const shadows = shadowAccountsToAdd.map((shadowAccount) => ({
        shadowUserId: shadowAccount.id,
        access: shadowAccount.accounts.map((account) => ({
          accounts: account.id,
          permissions: account.permissions,
          startAt: Moment(selectedDates[0]).format("MM-DD-YYYY"),
          endAt: Moment(selectedDates[1]).format("MM-DD-YYYY"),
        })),
      }));

      const { data } = await userAPI.setShadowUser({
        userId: clientId,
        shadows
      });

      setNotification({
        message: data.message,
        open: true,
      });

      handleClose();
    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }

  }

  const isFormValid = useCallback(() => {
    const hasAccounts = shadowAccountsToAdd.every(sa => sa?.accounts?.length > 0);

    return selectedDates !== null && shadowAccountsToAdd.length > 0 && hasAccounts;

  }, [selectedDates, shadowAccountsToAdd]);

  useEffect(() => {
    getShadowAccountInfo();

    return () => {
      console.log('unmounted');
    }
  }, []);

  const isSelectedDatesValid = () => {
    const valid = selectedDates?.filter(date => date !== null);

    return valid?.length === 2;
  }

  const getMySelection = (step = 1) => {
    if (!isSelectedDatesValid()) return `Select a date range`;

    const [fromDate, toDate] = selectedDates;

    return step === 1
      ? `Your selection: ${Moment(fromDate).format("D MMM, YYYY")} to ${Moment(toDate).format("D MMM, YYYY")}`
      : `From ${Moment(fromDate).format("D MMM, YYYY")} to ${Moment(toDate).format("D MMM, YYYY")}`;
  }

  const onCreateNewAssigment = (shadowAccount) => {
    const exists = selectedShadowAE.find(ae => ae.id === shadowAccount.id);

    if (exists) return;

    setNewAssigment(true);

    setSelectedShadowAE((prev) =>
      [
        ...prev.filter(ae => ae.id !== null),
        shadowAccount,
        newShadowAccount,
      ]
    );
  }

  const onEditShadowAccount = (shadowAccount) => {
    if (!shadowAccount.id) return;

    const exists = shadowAccountsToAdd.find(ae => ae.id === shadowAccount.id);

    if (!exists) {
      setNewAssigment(false);
      return setShadowAccountsToAdd((prev) => [...prev, shadowAccount]);
    }

    setShadowAccountsToAdd(prev =>
      prev.map(ae => ae.id === shadowAccount.id ? shadowAccount : ae)
    );
  }

  const onEditShadowAccountUser = (id) => {
    setShadowAccountsToAdd(prev => prev.filter(ae => ae.id !== id));
  }

  const onRemoveShadowAccount = (id) => {
    onEditShadowAccountUser(id);

    setSelectedShadowAE((prev) => prev.filter(ae => ae.id !== id));
  }

  const renderSelectedShadowAE = useMemo(() => (
    shadowAccountsToAdd.map((shadow, index) => {
      return (
        <div key={`${shadow.id ?? index}`}>
          <ShadowAccountSelectAE
            clientId={clientId}
            onEditShadowAccountUser={onEditShadowAccountUser}
            onRemoveShadowAccount={onRemoveShadowAccount}
            selectedAE={shadow}
            allShadows={shadowAccountsToAdd}
            onCreateNewAssigment={onCreateNewAssigment}
            onEditShadowAccount={onEditShadowAccount} />
        </div>
      );
    })
  ), [shadowAccountsToAdd]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth="md"
      scroll="body"
    >
      <Container>
        <Title>Shadow Account Executive User</Title>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Icons.CloseIcon />
        </IconButton>

        <Stack spacing={1.5}>
          <Label>
            <b>{`${selectedClient.fullName}`}</b>
          </Label>
          <Email>Email: <RedText>{selectedClient.email}</RedText></Email>

          {
            currentStep === 1 && (
              <>
                <ChooseDateText>Choose beginning and end date.</ChooseDateText>
                <RangeDatePicker
                  onChooseDate={onChooseDate}
                  initialDate={selectedDates}
                />
                <SelectedDatesText>
                  {getMySelection()}
                </SelectedDatesText>
              </>
            )
          }

          {
            currentStep === 2 && (
              <Stack>
                <SectionContainer>
                  <HeadLabelText>{getMySelection(2)}</HeadLabelText>
                  <IconButton onClick={onPrevStep}>
                    <Icons.EditIcon />
                  </IconButton>
                </SectionContainer>

                <Divider />
                <div>
                  {renderSelectedShadowAE}

                  {
                    newAssigment && (
                      <div>
                        <ShadowAccountSelectAE
                          onEditShadowAccountUser={onEditShadowAccountUser}
                          onRemoveShadowAccount={onRemoveShadowAccount}
                          selectedAE={newShadowAccount}
                          clientId={clientId}
                          allShadows={shadowAccountsToAdd}
                          onCreateNewAssigment={onCreateNewAssigment}
                          onEditShadowAccount={onEditShadowAccount} />
                      </div>
                    )
                  }

                </div>
              </Stack>
            )
          }

          <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
            <Button
              color="customRed"
              sx={styles.submitButton}
              onClick={onPrevStep}
            >
              {'< Back'}
            </Button>
            <Button
              onClick={currentStep === 1 ? onNextStep : onSubmit}
              sx={styles.submitButton}
              variant="blueGradient-outlined"
              disabled={
                (currentStep === 2 && !isFormValid()) ||
                (currentStep === 1 && !isSelectedDatesValid()) ||
                isSubmitting
              }
            >
              {currentStep === 1 ? 'Next' : 'Save'}
            </Button>
          </Box>
        </Stack>
      </Container>
    </Dialog>
  );
}

export default ShadowAccountExecutive;

ShadowAccountExecutive.propType = {
  clientId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
