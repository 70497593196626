import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Box,
  Grid,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import styledC from 'styled-components';
import {
  DoubleArrow as DoubleArrowIcon,
  Search as SearchIcon,
} from '@mui/icons-material';
import usersAPI from '../api/modules/users';
import { ClientOwnerModal } from './InviteClientOwner/ClientOwnerModal';
import { convertClientRolesToIds } from '../Helpers/clientRolesPermissions';
import { useDispatch, useSelector } from 'react-redux';
import Icons from './icons';
import {
  getSelectedCompany,
  resetSelectedCompany,
  selectUser,
  setSelectedCompany,
} from '../redux/features/user/userSlice';
import SearchTableComponent from './SearchTableComponent';
import { formatAccountPayload } from '../Helpers/formatters';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import {
  BlueAquaSearchButtonOutline,
  BodyContainer,
  BodyGridWrapper,
} from './Shared/BodyContainer/Index';
import AutoResizeGrid from './AutoResizeGrid/AutoResizeGrid';

const BlueAquaButtonFilled = styled(Button)({
  padding: '8px 22px',
  gap: '8px',
  width: 'auto',
  height: '42px',
  borderRadius: 0,
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  fontSize: 16,
});

const StMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: '203px',
    '& .MuiMenu-list': {
      padding: '0px ',
    },
  },
}));

const StMenuItem = styled(MenuItem)(({ theme }) => ({
  fontFamily: 'Montserrat',
  '&:hover': {
    backgroundColor: 'rgba(239, 180, 71, 0.2)',
    fontWeight: 'bold',
  },
}));

const styles = {
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
    marginRight: '5px',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const ManageUsersLabel = styledC.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: #FFFFFF;
`;

const ClientName = styledC.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
`;

const GradientEditIcon = ({ handleClickOpen }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>

    <IconButton title="Edit" onClick={handleClickOpen}>
      <Icons.EditIcon />
    </IconButton>
  </>
);

const columnsChooser = {
  allUsers: {
    lastLogin: false,
    manageAccounts: false,
    inviteAdmins: false,
    inviteclients: false,
    inviteae: false,
  },
  ADMIN: {
    company: false,
    role: false,
    manageAccounts: false,
  },
  ACCOUNT_EXECUTIVE: {
    company: false,
    role: false,
    inviteAdmins: false,
    inviteclients: false,
    inviteae: false,
    lastName: false,
    lastLogin: false,
    companyAddress: false,
  },
  CLIENT_STANDARD_USER: {
    lastLogin: false,
    manageAccounts: false,
    inviteAdmins: false,
    inviteclients: false,
    inviteae: false,
    actions: false,
    phoneNumber: false,
    // role: false,
  },
  CLIENT_ADMIN: {
    lastLogin: false,
    manageAccounts: false,
    inviteAdmins: false,
    inviteclients: false,
    inviteae: false,
    actions: false,
    phoneNumber: false,
  },
};

function InformativeComponent() {
  const userRedux = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRoleFilter, setSelectedRoleFilter] =
    useState('ACCOUNT_EXECUTIVE');
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [anchorEl, setAnchorEl] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState();
  const [usersCount, setUsersCount] = useState(0);
  const [textToSearch, setTextToSearch] = useState('');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const tableRef = useRef(null);
  const dispatch = useDispatch();
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState('ACCOUNT_EXECUTIVE');

  const [clientUserModal, setClientUserModal] = useState({
    isOpen: false,
    selectedClient: null,
  });

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickInviteClientUser = () => {
    setClientUserModal({
      isOpen: true,
      selectedClient: null,
    });

    handleClose();
  };

  const handleCloseInviteClientUser = () => {
    setClientUserModal({
      isOpen: false,
      selectedClient: null,
    });
  };

  const handleClickOpenEditClientUser = (id) => {
    const userFound = users.find((user) => user.id === id);

    if (userFound) {
      const selectedClient = {
        ...userFound,
        accounts: userFound.accounts[0],
      };

      setClientUserModal({
        selectedClient,
        isOpen: true,
      });
    }
  };

  const renderAction = (GridCellParams) => {
    const role = GridCellParams.row?.role?.name ?? 'NA';

    if (role === 'CLIENT_ADMIN' || role === 'CLIENT_STANDARD_USER') {
      return (
        <GradientEditIcon
          handleClickOpen={() =>
            handleClickOpenEditClientUser(GridCellParams.row.id)
          }
        />
      );
    }

    return <DoubleArrowIcon sx={{ color: '#9E9E9E' }} />;
  };

  const columns = [
    {
      field: 'company',
      headerName: 'Company',
      minWidth: 300,
      // maxWidth: 400,
      flex: 1,
      valueGetter: ({ row }) => {
        const { accounts } = row;

        if (!accounts?.length) return '-';

        const [account] = accounts;

        return account.company;
      },
    },
    {
      field: 'companyAddress',
      headerName: 'Company Address',
      minWidth: 227,
      // maxWidth: 260,
      flex: 1,
      valueGetter: ({ row }) => {
        const { accounts } = row;

        if (!accounts?.length) return '-';

        const [account] = accounts;

        return account.companyAddress1;
      },
    },
    {
      field: 'firstName',
      headerName:
        selectedDropdownFilter === 'ACCOUNT_EXECUTIVE' ? 'Name' : 'First Name',
      minWidth: 195,
      // maxWidth: 240,
      flex: 1,
      valueGetter: ({ row }) => {
        return selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
          ? row.fullName
          : row.firstName;
      },
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      minWidth: 150,
      // maxWidth: 200,
      flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 330,
      // maxWidth: 600,
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#B80A26' }}>{params.row.email}</Box>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      minWidth: 227,
      flex: 1,
      valueGetter: ({ row }) => {
        return row.phoneNumber;
      },
    },
    {
      field: 'role',
      headerName: 'Role',
      minWidth: 180,
      // maxWidth: 205,
      flex: 1,
      valueGetter: ({ row }) => {
        const { role } = row;

        return `${role?.name ? role.name.replace(/_/g, ' ') : '-'}`;
      },
    },
    {
      field: 'lastLogin',
      headerName: 'Last Login',
      minWidth: 196,
      renderCell: ({ row }) => {
        const value = row.lastLogin;
        return (
          <Box
            sx={{
              color: value ? 'inherit' : '#EFB447',
              fontWeight: value ? 'inherit' : 700,
            }}
          >
            {value ? value : 'Unverified'}
          </Box>
        );
      },
    },
    {
      field: 'Action',
      headerName: 'Actions',
      align: 'left',
      minWidth: 180,
      maxWidth: 200,
      flex: 1,
      renderCell: renderAction,
    },
  ];

  const handleGridUserChange = ({ role = '' }) => {
    if (role === '') {
      setColumnVisibilityModel(columnsChooser['allUsers']);
      return;
    }

    const _role = role.split(',')[0];

    setColumnVisibilityModel(columnsChooser[_role]);
  };

  const getUsersByRole = async (
    role = '',
    offset = 1,
    limit = 25,
    selectedCompany = [{ id: -1 }],
    search
  ) => {
    try {
      setIsLoading(true);

      let roles = '';

      if (role !== '') {
        roles = convertClientRolesToIds(userRedux.roles, role.split(','));
        roles = roles.join(',');
      }

      const getUsersPayload = {
        offset,
        limit,
        role: roles || 3,
      };

      if (search) Object.assign(getUsersPayload, { search });

      if (
        selectedCompany.length >= 1 &&
        selectedCompany[0].id > -1 &&
        role !== 'ADMIN'
      ) {
        const companyFilter = formatAccountPayload(selectedCompany);
        Object.assign(getUsersPayload, {
          accountId: companyFilter,
        });
      }

      const { data } = await usersAPI.getUsers(getUsersPayload);

      handleGridUserChange({ role });

      setUsers(data.users);
      setUsersCount(data.count);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadTable = async () => {
    await getUsersByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      selectedCompanyRedux
    );
  };

  useEffect(() => {
    getUsersByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      selectedCompanyRedux,
      textToSearch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, selectedRoleFilter, selectedCompanyRedux]);

  useEffect(() => {
    //TODO: change this to /api/me endpoint instead
    const getUserInfo = async () => {
      try {
        const { data } = await usersAPI.getUserInfo(userRedux.id);
        const { accounts } = data.data;
        const [account] = accounts;

        if (account) {
          dispatch(resetSelectedCompany());
          dispatch(
            setSelectedCompany({
              id: account.id,
              name: account.company,
            })
          );
        }
      } catch (error) {
        console.log(error);
      }
    };

    getUserInfo();
  }, []);

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const onPageChange = (page) => {
    scrollToTable();
    setCurrentPage(page + 1);
  };

  const scrollToTable = () => tableRef.current.scrollIntoView();

  const renderTable = useMemo(
    () => (
      <AutoResizeGrid
        sx={{ marginTop: '10px' }}
        columnVisibilityModel={columnVisibilityModel}
        rows={users}
        columns={columns}
        onPageChange={onPageChange}
        pageSize={pageSize}
        paginationMode="server"
        page={currentPage - 1}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={[1, 25, 50, 100]}
        pagination
        rowCount={usersCount}
        autoHeight
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, users, pageSize, columnVisibilityModel, usersCount]
  );

  const handleSearchUsers = (text) => {
    setTextToSearch(text);

    getUsersByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      selectedCompanyRedux,
      text
    );
  };

  const onCloseSearch = () => {
    setTextToSearch('');
    setIsSearchOpen(false);
    handleReloadTable();
  };

  const onOpenSearch = () => {
    setIsSearchOpen(true);
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ minHeight: 'inherit', display: 'inherit' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopContainer>
            <TitleHeaderRow>
              <TitleHeaderCol>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    fontStyle: 'normal',
                    letterSpacing: '0.15px',
                  }}
                >
                  <ManageUsersLabel>Manage Users</ManageUsersLabel>
                </Box>

                <Box>
                  <ClientName>{selectedCompanyRedux[0].name}</ClientName>
                </Box>
              </TitleHeaderCol>
              <TitleHeaderCol>
                <Box>
                  <BlueAquaButtonFilled
                    id="basic-button"
                    aria-controls={open ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    variant={open ? 'contained' : 'blueGradient-contained'}
                    color={open ? 'customRed' : undefined}
                  >
                    Invite New User
                  </BlueAquaButtonFilled>
                  <StMenu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '14px',
                      letterSspacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      borderRadius: '0 px !important',
                      paddingTop: '0px !important',
                    }}
                  >
                    <StMenuItem onClick={onClickInviteClientUser}>
                      Client User
                    </StMenuItem>
                  </StMenu>
                </Box>
              </TitleHeaderCol>
            </TitleHeaderRow>

            <ClientOwnerModal
              open={clientUserModal.isOpen}
              client={clientUserModal.selectedClient}
              onClose={handleCloseInviteClientUser}
              onSubmit={handleReloadTable}
            />
          </TopContainer>
        </Grid>

        <BodyGridWrapper ref={tableRef} item xs={12} md={12}>
          <BodyContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!isSearchOpen ? (
                <>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('ACCOUNT_EXECUTIVE');
                      setSelectedDropdownFilter('ACCOUNT_EXECUTIVE');
                    }}
                  >
                    Account Executives
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter ===
                      'CLIENT_ADMIN,CLIENT_STANDARD_USER'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER'
                      );
                      setSelectedDropdownFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER'
                      );
                    }}
                  >
                    Clients
                  </Button>

                  <div>
                    <BlueAquaSearchButtonOutline
                      onClick={onOpenSearch}
                      variant="blueGradient-outlined"
                    >
                      <SearchIcon fontSize={'large'} />
                    </BlueAquaSearchButtonOutline>
                  </div>
                </>
              ) : (
                <SearchTableComponent
                  onCloseSearch={onCloseSearch}
                  onSubmitSearch={handleSearchUsers}
                  defaultValue={selectedDropdownFilter}
                  items={[
                    {
                      value: 'ALL_USERS',
                      label: 'All Users',
                    },
                    {
                      value: 'ADMIN',
                      label: 'Admin Users',
                    },
                    {
                      value: 'ACCOUNT_EXECUTIVE',
                      label: 'Account Executives',
                    },
                    {
                      value:
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER',
                      label: 'Clients',
                    },
                  ]}
                />
              )}
            </Box>

            {isLoading ? (
              <div style={styles.loadingContainer}>
                <CircularProgress style={{ color: '#B80A26' }} size={60} />
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>{renderTable}</div>
            )}
          </BodyContainer>
        </BodyGridWrapper>
      </Grid>
    </Box>
  );
}

export default InformativeComponent;
