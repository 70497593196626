import { Grid } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import TopBackground1920 from '../../images/Bg_1920.png';
import TopBackground1024 from '../../images/Bg_1024.png';
import TopBackgroundXL from '../../images/Bg_XL.png';

const Container = styled.div`
  display: flex;
  height: 328px;
  background-image: url(${TopBackground1920});
  background-size: cover;
  background-repeat: no-repeat;

  @media (min-width: 1940px) {
    height: auto;
    background-image: url(${TopBackgroundXL});
  }

  @media (max-width: 1025px) {
    background-image: url(${TopBackground1024});
    height: 253px;
  }
`;

const MainGrid = styled(Grid)`
  margin: 71px 90px 0 90px;

  @media (min-width: 1940px) {
    margin: 48px 90px;
  }

  @media (max-width: 1025px) {
    margin: 58px 53px;
  }
`;

const TopContainer = ({ children }) => {
  return (
    <Container>
      <MainGrid container spacing={0}>
        {children}
      </MainGrid>
    </Container>
  );
};

export default TopContainer;
