import { FormControl, Grid, IconButton, InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import Icons from './icons';

const SearchBarContainer = styled.div`
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 4px;
  width: 100%;
  height: auto;
`;

const FormControlSelect = styled(FormControl)`
  background-color: #FFF;

  &:before {
    content: 'Search: ';
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #9E9E9E;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(0, -50%);
  }

  .MuiSelect-select{
    padding-left: 81px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
  }

  fieldset {
    border: none;
  }
`;

const SearchUserInput = styled(TextField)`
  border-left: 1px solid #E0E0E0 !important;

  .MuiOutlinedInput-input{
    &::placeholder {
      opacity: 1;
      font-family: 'Montserrat';
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }

  fieldset {
    border: none;
  }
`;

const SearchTableComponent = ({
  searchPlaceholder = 'Search Users',
  items = [],
  defaultValue,
  onCloseSearch,
  onSubmitSearch,
  setSelectedFilterState,
}) => {
  const [selectedValue, setSelectedValue] = useState(defaultValue ?? items[0]?.value ?? '');
  const [textToSearch, setTextToSearch] = useState('');

  const handleSubmit = () => {
    onSubmitSearch(textToSearch, selectedValue);
  }

  const onSearchChange = (value) => {
    setSelectedValue(value);

    if (setSelectedFilterState) {
      setSelectedFilterState(value);
    }
  }

  const handleClose = () => {
    setTextToSearch('');
    onCloseSearch();
  }

  return (
    <SearchBarContainer>
      <Grid container>
        {
          items.length > 0 && (

            <Grid item xs={12} md={3}>
              <FormControlSelect fullWidth>
                <Select
                  fullWidth
                  labelId="ddlFilter"
                  value={selectedValue}
                  onChange={({ target }) => onSearchChange(target.value)}
                >
                  {
                    items.map(({ label, value }) => (
                      <MenuItem key={value} value={value}>{label}</MenuItem>
                    ))
                  }
                </Select>
              </FormControlSelect>
            </Grid>
          )
        }

        <Grid item xs={12} md={items.length ? 9 : 12}>
          <SearchUserInput
            fullWidth
            placeholder={searchPlaceholder}
            value={textToSearch}
            autoFocus
            onChange={({ target }) => setTextToSearch(target.value)}
            onKeyPress={(ev) => {
              if (ev.key === 'Enter')
                handleSubmit()
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <>
                    <IconButton title="Search" onClick={handleSubmit}>
                      <Icons.SearchIcon />
                    </IconButton>
                    {
                      onCloseSearch && (
                        <IconButton title="Close" onClick={handleClose}>
                          <Icons.CloseIcon />
                        </IconButton>
                      )
                    }
                  </>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
    </SearchBarContainer>
  );
}

export default SearchTableComponent;
