import React, { useRef, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import authAPI from "../../api/modules/authentication";
import { styles as singInStyles } from "./styles";
import { useNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/authContext";
import MixpanelHelper from "../../Helpers/MixpanelHelper";

const styles = {
  email: {
    color: "#B80A26",
    fontWeight: "bold",
    margin: "6px 0",
    display: "block",
  },
  form: {
    marginTop: "12px",
  },
  faButton: {
    width: "58px",
    textAlign: "center",
  },
};

export const Sign2FAForm = ({ email }) => {
  const [userEmail, _] = useState(email);
  const [sendCodeLabel, setSendCodeLabel] = useState("Send code again?");
  const navigate = useNavigate();
  const { setNotification } = useNotification();
  const { onLogin } = useAuth();

  const [otpValues, setOTPValues] = useState(Array(6).fill(""));
  const otpElements = useRef([]);

  const handleOnChange = (el, index) => {
    if (isNaN(el.value)) return;

    setOTPValues((prev) => [
      ...prev.map((otp, otpIndex) => (otpIndex === index ? el.value : otp)),
    ]);

    if (index < otpValues.length - 1 && el.value.trim() !== "") {
      const element = otpElements[index + 1];
      element.focus();
    }
  };

  const onSubmit = async (_, { setSubmitting }) => {
    try {
      const otp = otpValues.join("");
      const response = await authAPI.validateOTP({ otp, email: userEmail });
      const token = response?.data?.data?.access_token;

      if (token) {
        await onLogin(token);
        MixpanelHelper.track("login");
        navigate("/orders", { replace: true });
      }
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onPaste = (e) => {
    const copiedData = e.clipboardData.getData("text/plain");
    setOTPValues(copiedData.split("").slice(0, 6));
    const element = otpElements[5];
    element.focus();
  };

  const onBackSpace = (e, index) => {
    if (e.keyCode === 8 && !index - 1 < 0) {
      setOTPValues((prev) => [
        ...prev.map((otp, otpIndex) => (otpIndex === index ? "" : otp)),
      ]);
      const element = otpElements[index - 1];
      element.focus();
      e.preventDefault();
    }
    if (e.keyCode === 37 && !index - 1 < 0) {
      const element = otpElements[index - 1];
      element.focus();
      e.preventDefault();
    }
    if (e.keyCode === 39 && !(index + 1 === otpValues.length)) {
      const element = otpElements[index + 1];
      element.focus();
      e.preventDefault();
    }
  };

  const resendCode = async () => {
    if (sendCodeLabel !== "Send code again?") {
      return;
    }
    await authAPI.resendOTPcode({ email: userEmail });
    setSendCodeLabel("A new code has been sent, try again");
  };

  return (
    <Box onPaste={onPaste}>
      <Stack spacing={2} style={singInStyles.textTypography}>
        <div>
          {"We have sent a 6-digit code to"}
          <span style={styles.email}>{userEmail}</span>
          {"Please check your email and enter it below."}
        </div>

        <Formik initialValues={{}} onSubmit={onSubmit}>
          {({ isSubmitting }) => (
            <Form style={styles.form}>
              <Grid container spacing={0} columns={6}>
                {otpValues.map((otp, index) => (
                  <Grid
                    item
                    xs={2}
                    md={2}
                    lg={1}
                    sx={{ my: 1 }}
                    key={`otp-${index}`}
                  >
                    <TextField
                      inputRef={(element) => (otpElements[index] = element)}
                      key={`SingleInput-${index}`}
                      value={otp}
                      name={`otp-${index}`}
                      style={styles.faButton}
                      variant="outlined"
                      required
                      inputProps={{
                        maxLength: 1,
                        style: { textAlign: "center" },
                      }}
                      onChange={({ target }) => handleOnChange(target, index)}
                      onFocus={({ target }) => target.select()}
                      onKeyDown={(e) => onBackSpace(e, index)}
                    />
                  </Grid>
                ))}
              </Grid>

              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
              >
                <Typography
                  sx={{ ...singInStyles.redText, cursor: "pointer" }}
                  onClick={resendCode}
                >
                  {sendCodeLabel}
                </Typography>
                <Button
                  variant="blueGradient-outlined"
                  sx={singInStyles.submitButton}
                  type="submit"
                  disabled={isSubmitting || otpValues.join("").length !== 6}
                >
                  Submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Stack>
    </Box>
  );
};
