import * as React from "react";
import Box from "@mui/material/Box";
import Informative from "../components/InformativeComponent";
import { Sign2FAForm } from "../components/SignIn/";
import { Footer } from "../components/FooterComponent";

function Sign2FA() {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "black",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "55% 45%",
            gridTemplateRows: "auto",
            gridTemplateAreas: `
                                "info   main"
                                "footer footer "`,
          }}
        >
          <Box sx={{ gridArea: "info", bgcolor: "error.main", color: "#fff" }}>
            <Informative crmMarginTop={"none"} />
          </Box>
          <Box sx={{ gridArea: "main", bgcolor: "white" }}>
            <Sign2FAForm />
          </Box>
          <Box sx={{ gridArea: "footer", bgcolor: "warning.dark" }}>
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Sign2FA;
