import React, { useEffect, useMemo, useState, useRef } from 'react';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { convertClientPermissionToId } from '../../Helpers/clientRolesPermissions';
import { selectUser } from '../../redux/features/user/userSlice';
import Icons from '../icons';
import userAPI from '../../api/modules/users';
import { SearchResultItem } from '../Search/SearchResult';
import { SearchInputField } from '../SearchInputField';
import { SearchResultNotFound } from '../Search/SearchResultNotFound';
import SearchAccount from '../SearchAccount';

const useOutsideClick = (ref, setIsAEListVisible) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsAEListVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const SelectAEContainer = styled.div``;

const AEEmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const AssignAccountsContainer = styled.div`
  position: relative;
  margin-top: 18px;
`;

const DisabledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(255,255,255,0.6);
`;

const ResultsContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: scroll;
  height: 245px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0;
`;

const RedText = styled.span`
  color: #B80A26;
`;

const HeadLabelText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #444444;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Email = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ color = '#000000' }) => color};
`;

const SelectedAccounts = styled.div`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const TitleLabel = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const Divider = styled.div`
  border-bottom: 1px solid #BDBDBD;
  margin: 14px 0 20px 0;
`;

const ShadowAccountSelectAE = ({
  allShadows,
  clientId,
  selectedAE,
  onEditShadowAccount,
  onEditShadowAccountUser,
  onCreateNewAssigment,
  onRemoveShadowAccount,
}) => {
  const [isAEListVisible, setIsAEListVisible] = useState(false);
  const [selectedAEUser, setSelectedAEUser] = useState(selectedAE);
  const [textToSearch, setTextToSearch] = useState('');
  const [lastSearch, setLastSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [accountExecutives, setAccountExecutives] = useState([]);

  const userRedux = useSelector(selectUser);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setIsAEListVisible);

  const onEditSelectedAE = () => {
    onEditShadowAccountUser(selectedAEUser.id);
    setSelectedAEUser({
      id: null,
      firstName: '',
      lastName: '',
      email: '',
      isEdittingUser: true,
      isEdittingAccount: false,
      accounts: [],
    });

    setTextToSearch('');
    setLastSearch('');
    setAccountExecutives([]);
    setIsAEListVisible(false);
  }

  const onEditSelectedAccounts = () => {
    setSelectedAEUser((prev) => ({
      ...prev,
      isEdittingAccount: true,
    }));
  }

  const onCreateAssigment = () => {
    const _selectedAE = {
      ...selectedAEUser,
      isEdittingUser: false,
      isEdittingAccount: false,
    }

    onCreateNewAssigment(_selectedAE);

    setSelectedAEUser(_selectedAE);
  }

  const onAddAccountExecutive = (account) => {
    const { email, firstName, fullName, id } = account;

    setSelectedAEUser({
      email,
      firstName,
      fullName,
      id,
      isEdittingUser: false,
      isEdittingAccount: true,
      accounts: [],
    });

    setIsAEListVisible(false);
  }

  const onSearchHandler = async (text) => {
    try {
      setIsSearching(true);

      const role = userRedux.roles.find((meRole) => meRole.name === 'ACCOUNT_EXECUTIVE');

      const { data } = await userAPI.getUsers({
        role: role.id,
        search: text
      });

      const shadowIds = allShadows.map(ae => ae.id);

      const accounts = data.users.filter(ae => !shadowIds.includes(ae.id) && ae.id !== clientId);

      setLastSearch(text);
      setIsAEListVisible(true);
      setAccountExecutives(accounts);

    } catch (error) {

    } finally {
      setIsSearching(false);
    }
  }

  useEffect(() => {
    onEditShadowAccount(selectedAEUser);
  }, [selectedAEUser]);

  const onAccountRowsChange = (rows) => {
    const _assignedAccounts = rows.map((row) => {
      const account = {
        ...row,
        accounts: row.id,
        id: row.id,
        permissions: [],
        company: row.company,
      };

      if (row.createReports)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "REPORT_CREATION"));

      if (row.userManagement)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "USER_MANAGEMENT"));

      if (row.readOnly)
        account.permissions.push(convertClientPermissionToId(userRedux.permissions, "READ_ONLY"));

      return account;
    });

    setSelectedAEUser((prev) => ({
      ...prev,
      accounts: _assignedAccounts,
    }));
  }

  const renderAccountExecutiveList = useMemo(() => (
    accountExecutives.map((account) => (
      <SearchResultItem
        showIcon={false}
        key={account.id}
        onClick={() => onAddAccountExecutive(account)}
        title={`${account.fullName}`}
        subtitle={<RedText>{account.email}</RedText>}
        id={account.id} />
    ))
  ), [accountExecutives, selectedAEUser]);

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (textToSearch.trim().length >= 4) {
        onSearchHandler(textToSearch.trim());
      } else {
        setIsSearching(false);
      }
    }, 1000);

    return () => clearTimeout(delayFn);
  }, [textToSearch]);

  return (
    <>
      <SelectAEContainer>
        {
          selectedAEUser.id && (
            <SectionContainer>
              <AEEmailContainer>
                <TitleLabel>Account Executive:</TitleLabel>
                <Email>
                  <b>{selectedAEUser.fullName}</b>
                </Email>
                <Email><RedText>{selectedAEUser.email}</RedText></Email>
              </AEEmailContainer>
              <Box>
                {
                  !selectedAEUser?.isEdittingAccount && !selectedAEUser?.isEdittingUser && (
                    <IconButton onClick={() => onRemoveShadowAccount(selectedAEUser.id)}>
                      <Icons.RemoveIcon />
                    </IconButton>
                  )
                }
              </Box>
            </SectionContainer>
          )
        }

        {
          selectedAEUser?.isEdittingUser && (
            <>
              <HeadLabelText>Select AE User</HeadLabelText>
              <Box mt={2}>
                <SearchInputField
                  placeholder="Write Name, last or email"
                  onChange={({ target }) => setTextToSearch(target.value)}
                  value={textToSearch}
                  showClose={true}
                  onFocus={() => { if (accountExecutives.length) setIsAEListVisible(true) }}
                  onSearchClose={() => {
                    setTextToSearch('');
                    setIsAEListVisible(false);
                    setAccountExecutives([]);
                  }}
                />

                {
                  isAEListVisible && (
                    <div style={{ position: 'relative' }}>
                      <ResultsContainer ref={wrapperRef}>
                        {
                          isSearching ? (
                            <LoadingContainer>
                              <CircularProgress style={{ color: '#B80A26' }} />
                            </LoadingContainer>
                          ) : (

                            accountExecutives.length > 0 ? (
                              <>
                                {renderAccountExecutiveList}
                              </>

                            ) : !isSearching && (
                              <SearchResultNotFound title={lastSearch} />
                            )
                          )
                        }
                      </ResultsContainer>
                    </div>
                  )
                }
              </Box>
            </>
          )
        }
      </SelectAEContainer>

      {
        (selectedAEUser.isEdittingUser || selectedAEUser.isEdittingAccount) && (
          <AssignAccountsContainer>
            {!selectedAEUser?.id && <DisabledContainer />}

            <Box mb={2}>
              <HeadLabelText>Assign Accounts</HeadLabelText>
            </Box>

            <SearchAccount
              onRowsChange={onAccountRowsChange}
              accounts={selectedAEUser.accounts}
              accountExecutiveId={clientId}
            />
          </AssignAccountsContainer>
        )
      }

      {
        !selectedAEUser.isEdittingAccount && !selectedAEUser.isEdittingUser && (
          <>
            <Box my={3}>
              <SectionContainer>
                <div>
                  <TitleLabel>Clients Assigned:</TitleLabel>
                  <SelectedAccounts>{selectedAEUser.accounts.map(a => a.company).join(', ')}</SelectedAccounts>
                </div>

                <IconButton onClick={onEditSelectedAccounts}>
                  <Icons.EditIcon />
                </IconButton>
              </SectionContainer>
            </Box>
            <Divider />
          </>
        )
      }

      {
        selectedAEUser?.isEdittingAccount && (
          <Box
            display="flex"
            justifyContent="end"
            margin="18px 0;"
          >
            <TitleLabel>
              {!selectedAEUser.accounts.length ? `No accounts selected. ` : ' '}
              {
                selectedAEUser.accounts.length > 0 && (
                  <RedText
                    onClick={selectedAEUser.accounts.length ? onCreateAssigment : undefined}
                    style={{ cursor: 'pointer' }}
                  >Create another assigment</RedText>
                )
              }
            </TitleLabel>
          </Box>
        )
      }
    </>
  );
}

export default ShadowAccountSelectAE;
