import React from 'react';

const ShoppingCartIcon = ({
  width = 16,
  height = 8,
  color = '#9E9E9E'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.01 3H0V5H12.01V8L16 4L12.01 0V3Z" fill={color} />
    </svg>
  );
}

export default ShoppingCartIcon;
