import React from 'react';
import styled from 'styled-components';
import Icons from './icons';

const styles = {
  links: {
    textDecoration: 'none',
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.15px',
    color: '#B80A26',
    textAlign: 'center',
    margin: '0 6px',
  },
};

const Container = styled.div`
  background-color: #252525;
  height: 160px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 0 90px;

  @media (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 1025px) {
    padding: 0 48px;
  }
`;

const Column = styled.div`
  display: flex;
  /* padding: 0 38px; */
  align-items: center;
`;

const Title = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  letter-spacing: 0.15px;
  color: #ffffff;
  margin-bottom: 7px;
`;

const LogoContainer = styled.div`
  margin-right: 30px;
`;

const PhoneNumber = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 133.4%;
  color: #ffffff;
  margin: 0 24px;
`;

const BottomContainer = styled.div`
  background-color: #000;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 59px;

  @media (max-width: 886px) {
    flex-direction: column;
  }

  padding: 0 90px;

  @media (max-width: 1025px) {
    padding: 0 48px;
  }
`;

const Rights = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #ffffff;

  @media (max-width: 612px) {
    margin-bottom: 6px;
  }
`;

const LinksContainer = styled.div`
  margin-left: 6px;
  display: flex;
`;

const Separator = styled.div`
  width: 1.2px;
  background-color: #fff;
  height: 12px;
  align-self: center;

  @media (max-width: 612px) {
    display: none;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Address = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #ffffff;
`;

export const Footer = () => {
  return (
    <>
      <Container>
        <Column>
          <LogoContainer>
            <Icons.MicroAgeLogoIcon />
          </LogoContainer>
          <TitleContainer>
            <Title>MicroAge - The Digital Transformation Experts</Title>
            <Address>
              15210 S. 50th Street, Suite 180 | Phoenix, AZ 85044
            </Address>
          </TitleContainer>
        </Column>
        <Column>
          <PhoneNumber>480 366 2000</PhoneNumber>
          <Icons.MessageIcon />
        </Column>
      </Container>
      <BottomContainer>
        <Rights>©2023 MicroAge. All Rights Reserved.</Rights>
        <LinksContainer>
          <a style={styles.links} href="https://microage.com/privacy-policy/">
            Privacy Policy
          </a>
          <Separator />
          <a
            style={styles.links}
            href="https://microage.com/terms-and-conditions/"
          >
            Terms and Conditions
          </a>
          <Separator />
          <a style={styles.links} href="https://microage.com/support/">
            Submit Services Request
          </a>
        </LinksContainer>
      </BottomContainer>
    </>
  );
};
