import React from 'react';
import styled from 'styled-components';

const Name = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const Contact = styled.span`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #44C0B4;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 20px;
  padding: 2px 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyNotFoundItem = ({ text }) => {
  return (
    <Container>
      <Row>
        <Name>{text}</Name>
        <Contact>Client Not found - Please ensure that they are in Business Central and try again</Contact>
      </Row>
    </Container>
  )
}
