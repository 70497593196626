import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { CompanyInfo } from "../Company/CompanyInfo";
import { styles as signInStyles } from "../../components/SignIn/styles";
import { CompanySelection } from "../Company/CompanySelection";
import { ClientInformationForm } from "../Client/ClientInformationForm";
import {
  clientPermissions,
  clientRoles,
  convertClientPermisionsToNames,
  convertClientPermissionsToIds,
  convertClientPermissionToId,
  convertClientRoleToId,
} from "../../Helpers/clientRolesPermissions";
import { CustomColorDataGrid } from "../Grids/CustomColorDataGrid";
import { useNotification } from "../../context/notificationContext";
import { SearchInputField } from "../SearchInputField";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { SearchResultItem } from "../Search/SearchResult";
import { SearchResultNotFound } from "../Search/SearchResultNotFound";
import { selectUser } from "../../redux/features/user/userSlice";
import userAPI from "../../api/modules/users";
import authAPI from "../../api/modules/authentication";
import Icons from "../icons";

const useOutsideClick = (ref, setIsAEListVisible) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsAEListVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #b80a26;
  margin-bottom: 24px;
  display: block;
`;

const Container = styled.div`
  padding: 32px 70px;
`;

const Body = styled.div``;

const Label = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 16px;
  display: block;
`;

const Red = styled.b`
  color: #b80a26;
`;

const ResultsContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: scroll;
  height: 245px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0;
`;

export const ClientOwnerModal = ({
  open = false,
  client,
  onClose,
  onSubmit,
}) => {
  const [selectedCompany, setSelectedCompany] = useState();
  const [currentStep, setCurrentStep] = useState(1);
  const [tableRows, setTableRows] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [payloadAction, setPayloadAction] = useState({
    action: "",
    payload: {},
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [accountExecutives, setAccountExecutives] = useState([]);
  const [isAEListVisible, setIsAEListVisible] = useState(false);

  //TODO: move
  const [lastSearch, setLastSearch] = useState("");
  const [textToSearch, setTextToSearch] = useState("");

  const userRedux = useSelector(selectUser);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setIsAEListVisible);

  const initialFormValues = {
    companyRole: "",
    email: "",
    firstName: "",
    lastName: "",
    permissions: clientPermissions.clientAdminUser
      .filter((permission) => permission.defaultChecked)
      .map((p) => p.value),
    userRole: "CLIENT_ADMIN",
  };

  const [initialValues, setInitialValues] = useState(initialFormValues);

  const { setNotification } = useNotification();
  const { action, payload } = payloadAction;

  const titleMessage = client
    ? "Edit Client"
    : "Invite New Client";

  const handlePermissionChange = (clickedRow) => {
    const { field } = clickedRow;

    const updatedData = tableRows.map((row) => {
      if (row.id === clickedRow.id) {
        const newFieldValue = !row[field];

        if (field === "readOnly" && newFieldValue) {
          return {
            ...row,
            readOnly: newFieldValue,
            createReports: false,
            userManagement: false,
          };
        }

        if (field !== "readOnly" && newFieldValue) {
          return {
            ...row,
            readOnly: false,
            [field]: newFieldValue,
          };
        }

        return {
          ...row,
          [field]: newFieldValue,
        };
      }

      return row;
    });

    setTableRows(updatedData);
  };

  const renderPermissionCheckBox = (params) => {
    return (
      <Checkbox
        checked={params.value}
        onChange={() => handlePermissionChange(params)}
        sx={{
          color: "#E0E0E0",
          "&.Mui-checked": {
            color: "#44C0B4",
          },
        }}
      />
    );
  };

  const columns = useMemo(() => [
    {
      field: "firstName",
      headerName: "First name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "createReports",
      headerName: "Report Creation",
      minWidth: 100,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "userManagement",
      headerName: "User Management",
      minWidth: 100,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "readOnly",
      headerName: "Read Only",
      minWidth: 100,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 80,
      flex: 1,
      renderCell: ({ row }) => (
        <IconButton onClick={() => onRemoveAccountExecutive(row.id)}>
          <Icons.RemoveIcon />
        </IconButton>
      ),
      type: "boolean",
      editable: false,
    },
  ]);

  const handleClose = () => {
    onClose();
    setCurrentStep(1);
    setSelectedCompany(null);
    setTableRows([]);
    setAccountExecutives([]);
    setTextToSearch("");
    setIsLoading(false);
  };

  const handleOnItemClick = (company) => {
    setSelectedCompany(company);
    getAEByCompany(company.id);
  };

  const handleOnBack = () => {
    if (currentStep === 2) {
      setCurrentStep(1);
    } else {
      setSelectedCompany(null);
      onClose();
    }
  };

  const handleOnAfterSubmit = async () => {
    onClose();
    onSubmit();
    setTimeout(() => {
      setIsConfirmOpen(false);
    }, 0);
  };

  const getUserData = async () => {
    try {
      setIsLoading(true);

      const { data } = await userAPI.getUserInfo(client.id);
      const user = data.data;

      const permissions = convertClientPermisionsToNames(
        userRedux.permissions,
        user.permissions
      );

      setInitialValues({
        ...user,
        permissions,
        userRole: user.role.name,
      });

      await getAEByCompany(client.accounts.id);

      setSelectedCompany({
        ...client.accounts,
        Name: client.accounts.company,
        Phone_No_: client.accounts.phoneNo,
        Address: client.accounts.companyAddress1,
        No_: client.accounts.id,
      });

      setCurrentStep(2);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getAEByCompany = async (id) => {
    try {
      const aeRole = convertClientRoleToId(
        userRedux.roles,
        "ACCOUNT_EXECUTIVE"
      );

      const { data } = await userAPI.getUsers({
        role: aeRole,
        limit: 100, //TODO: fix, send accountId instead
      });

      const { users } = data;

      const aeFilteredList = users.filter(({ accounts }) =>
        accounts.includes(id)
      );

      if (aeFilteredList.length) {
        const aeToAdd = [];

        aeFilteredList.forEach((ae) => {
          if (ae.accessPermissions?.length) {
            const { permissions } = ae.accessPermissions.find(
              ({ account }) => account === id
            );

            const permissionNames = convertClientPermisionsToNames(
              userRedux.permissions,
              permissions
            );

            aeToAdd.push({
              ...ae,
              readOnly: permissionNames.includes("READ_ONLY"),
              createReports: permissionNames.includes("REPORT_CREATION"),
              userManagement: permissionNames.includes("USER_MANAGEMENT"),
            });
          }
        });

        setTableRows(aeToAdd);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  useEffect(() => {
    if (client) {
      getUserData();
    }

    return () => {
      setInitialValues(initialFormValues);
      setTableRows([]);
      setTextToSearch("");
      setIsLoading(false);
    };
  }, [client]);

  const renderGrid = useMemo(
    () => (
      <CustomColorDataGrid
        tableRows={tableRows}
        columns={columns}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    ),
    [tableRows, pageSize]
  );

  const handleOnSubmit = async (values, { setSubmitting }) => {
    try {
      const list = [];

      tableRows.map((row) => {
        const permission = [];

        if (row.createReports)
          permission.push(
            convertClientPermissionToId(
              userRedux.permissions,
              "REPORT_CREATION"
            )
          );

        if (row.userManagement)
          permission.push(
            convertClientPermissionToId(
              userRedux.permissions,
              "USER_MANAGEMENT"
            )
          );

        if (row.readOnly)
          permission.push(
            convertClientPermissionToId(userRedux.permissions, "READ_ONLY")
          );

        if (row.readOnly || row.createReports || row.userManagement) {
          const data = {
            id: row.id,
            permissions: permission,
          };
          list.push(data);
        }
      });

      const role = convertClientRoleToId(userRedux.roles, values.userRole);
      const permissions = convertClientPermissionsToIds(
        userRedux.permissions,
        values.permissions
      );

      const data = {
        permissions,
        role,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        companyRole: values.companyRole,
        account: selectedCompany.No_,
        accountExecutives: list,
      };

      if (client) {
        setPayloadAction({
          action: "edit",
          payload: {
            ...data,
            id: client.id,
          },
        });

        return setIsConfirmOpen(true);
      } else {
        await authAPI.inviteClientOwner(data);

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {values.firstName} {values.lastName}
              </b>
              {" was invited!"}
            </>
          ),
        });

        handleOnAfterSubmit();
      }
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setSubmitting(false);
    }
  };

  const onRemoveAccountExecutive = (id) => {
    setTableRows((prev) => prev.filter((row) => row.id !== id));
  };

  const onAddAccountExecutive = (id) => {
    const accountToAdd = accountExecutives.find((ae) => ae.id === id);

    const exists = tableRows.find((row) => row.id === id);

    if (!exists) {
      setTableRows((prev) => [
        ...prev,
        {
          ...accountToAdd,
          createReports: false,
          userManagement: false,
          readOnly: false,
        },
      ]);
    }
  };

  const dialogText =
    action === "edit" ? (
      "If you continue, you cannot undo this action."
    ) : (
      <>
        Are you sure you want to remove{" "}
        <Red>
          {payload?.firstName} {payload?.lastName}
        </Red>
        . This action cannot be undone.{" "}
      </>
    );

  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      if (action === "edit") {
        const clientPayload = {
          account: selectedCompany.id,
          accountExecutives: payload.accountExecutives,
          companyRole: payload.companyRole,
          email: payload.email,
          firstName: payload.firstName,
          lastName: payload.lastName,
          permissions: payload.permissions,
          role: payload.role,
        };

        await userAPI.editClient({ id: payload.id }, clientPayload);

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.firstName} {payload.lastName}
              </b>
              {" user has been updated."}
            </>
          ),
        });
      } else if (action === "delete") {
        await userAPI.deleteUser({ id: payload.id });

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.firstName} {payload.lastName}
              </b>
              {" has been removed from the system."}
            </>
          ),
        });
      }

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };

  const onSearchHandler = async (text) => {
    try {
      setIsSearching(true);

      const role = userRedux.roles.find(
        (meRole) => meRole.name === "ACCOUNT_EXECUTIVE"
      );

      const { data } = await userAPI.getUsers({
        role: role.id,
        search: text,
      });

      setLastSearch(text);
      setIsAEListVisible(true);
      setAccountExecutives(data.users);
    } catch (error) {
    } finally {
      setIsSearching(false);
    }
  };

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (textToSearch.trim().length >= 4) {
        onSearchHandler(textToSearch.trim());
      } else {
        setIsSearching(false);
      }
    }, 1000);

    return () => clearTimeout(delayFn);
  }, [textToSearch]);

  if (!open) return <></>;
  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={action === "delete" ? "Delete" : "Okay"}
        isSubmitting={isSubmitting}
        title={
          action === "edit"
            ? "Are you sure you want to continue?"
            : "Remove Account"
        }
        text={dialogText}
      />

      <Dialog
        hidden={isConfirmOpen}
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
      >
        <Container>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>

          <Title>{titleMessage} </Title>

          <Body>
            {selectedCompany && (
              <div style={{ marginBottom: 32 }}>
                <CompanyInfo {...selectedCompany} />
              </div>
            )}

            {isLoading ? (
              <Box>
                <LoadingContainer>
                  <CircularProgress style={{ color: "#B80A26" }} />
                </LoadingContainer>
              </Box>
            ) : currentStep === 1 ? (
              <>
                <CompanySelection
                  onSelectCompany={handleOnItemClick}
                  selectedCompany={selectedCompany}
                />

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Button
                    variant="text"
                    color="customRed"
                    onClick={handleOnBack}
                    sx={signInStyles.textUnset}
                  >
                    {"< Back"}
                  </Button>
                  <Button
                    type="submit"
                    variant="blueGradient-outlined"
                    onClick={() => setCurrentStep(2)}
                    sx={signInStyles.submitButton}
                    disabled={!selectedCompany}
                  >
                    Next
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <ClientInformationForm
                  // handleRemoveUser={handleRemoveUser}
                  isEditting={client && true}
                  initialValues={initialValues}
                  handleOnBack={handleOnBack}
                  handleOnSubmit={handleOnSubmit}
                  clientRoles={clientRoles.clientAdminUser}
                  clientPermissions={clientPermissions}
                  setClientPermissions={() => {}}
                ></ClientInformationForm>
              </>
            )}
          </Body>
        </Container>
      </Dialog>
    </>
  );
};
