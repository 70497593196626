import React from 'react';

const DatePickerIcon = ({
  width = 20,
  height = 22,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2H17V0H15V2H5V0H3V2H2C0.9 2 0 2.9 0 4V20C0 21.1 0.9 22 2 22H18C19.1 22 20 21.1 20 20V4C20 2.9 19.1 2 18 2ZM18 20H2V7H18V20Z" fill="url(#paint0_linear_8431_7227)" />
      <defs>
        <linearGradient id="paint0_linear_8431_7227" x1="0.869565" y1="5.23653e-08" x2="21.9066" y2="19.9939" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5058F2" />
          <stop offset="1" stopColor="#44C0B4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default DatePickerIcon;
