import React from 'react';

const SuccessIcon = ({
  width = 44,
  height = 44,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.0007 0.333984C10.0407 0.333984 0.333984 10.0407 0.333984 22.0007C0.333984 33.9607 10.0407 43.6673 22.0007 43.6673C33.9607 43.6673 43.6673 33.9607 43.6673 22.0007C43.6673 10.0407 33.9607 0.333984 22.0007 0.333984ZM17.6673 32.834L6.83398 22.0007L9.88898 18.9457L17.6673 26.7023L34.1123 10.2573L37.1673 13.334L17.6673 32.834Z" fill="#44C0B4" />
    </svg>

  );
}

export default SuccessIcon;
