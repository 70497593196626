import React, { useEffect, useMemo, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { DataGrid } from '@mui/x-data-grid';
import { invoiceReportRows } from "../../mocks/invoiceReportData";
import Icons from "../icons";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import SaveIcon from "@mui/icons-material/Save";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FilterVintageIcon from "@mui/icons-material/FilterVintage";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import ColumnChooserDialogComponent from "./ReportsComponent/ColumnChooserDialogComponent";
import FilterDialogComponent from "./ReportsComponent/FilterDialogComponent";
import SaveDialogComponent from "./ReportsComponent/SaveDialogComponent";
import ScheduleDialogComponent from "./ReportsComponent/ScheduleDialogComponent";
import ExportDialogComponent from "./ReportsComponent/ExportDialogComponent";
import { invoiceColumns, columnsDefinition, columnsParser } from "./helpers/columnsOptions";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SettingsIcon from "@mui/icons-material/Settings";
import dayjs from "dayjs";
import reportsAPI from "../../api/modules/reports";
import {
  invoicesIdsToDefinition,
  ordersIdsToDefinition,
  getDisabledColumns,
} from "./helpers/columnsOptions";
import {
  invoiceReportDefault,
  orderReportDefault,
  scrollUp,
} from "./helpers/reportHelper";
import { initialFormValues } from "./helpers/filterOptions";
import { useParams } from "react-router-dom";
import { optionsParser, filtersValueFrom } from "./helpers/filterOptions";
import SalesAssociateDialog from "../OrdersInvoices/SalesAssociateDialog";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useDispatch, useSelector } from "react-redux";
import { getSelectedCompany, resetSelectedCompany, selectUser, setReportConfig, setReportPayload, setSelectedCompany } from "../../redux/features/user/userSlice";

const GradientSalesAssociateIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>

    <AccountCircleIcon
      sx={{ cursor: "pointer", fill: "url(#linearColors)" }}
      onClick={onClick}
    />
  </>
);

const CustomColorDataGrid = muiStyled(DataGrid)(({ theme }) => ({
  fontFamily: "Montserrat",
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

const SearchInput = muiStyled(TextField)(({ theme }) => ({
  "& .MuiInputBase-root:before, & .MuiInputBase-root:after": {
    border: "none !important",
  },
  "& .MuiInput-root": {
    padding: "0 16px",
  },
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
  paddingBottom: "10px",
  paddingTop: "10px",
  backgroundColor: "#FFFFFF",
  border: "1px solid #E0E0E0",
}));

const UnderlinedText = styled.span`
  cursor: pointer;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: ${({ color = "#B80A26" }) => color};
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 60px 0;
`;

const Container = styled.div`
  display: flex;

  .MuiTextField-root {
    width: 100%;
  }
`;

const GridDate = styled(Grid)`
  @media (min-width: 1024px) {
    max-width: 47% !important;
    flex-basis: 47% !important;
    width: 47% !important;
    margin: 0 1px !important;
  }
`;

const GridSearch = styled(Grid)`
  @media (min-width: 1024px) {
    max-width: 4% !important;
    flex-basis: 4% !important;
    width: 4% !important;
  }
`;

const SearchButton = styled.div`
  cursor: pointer;
  background: linear-gradient(136.27deg, #5058f2 2.13%, #44c0b4 100%);
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
`;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: "2px",
  textAlign: "center",
  borderRadius: "0px !important",
  color: "#B80A26 !important",
  display: "flex",
  gap: "5px",
  paddingLeft: "15px",
  paddingRight: "15px",
  cursor: "pointer",
}));

const defaultFormValues = {
  balance1: "",
  balance2: "",
  balance3: "",
  amount1: "",
  amount2: "",
  amount3: "",
  passedDue: {
    option: "",
  },
  invoiceDate: {
    option: "",
    dateFrom: null,
    dateTo: null,
  },
  orderDate: {
    option: "",
    dateFrom: null,
    dateTo: null,
  },
  status: "All",
};

const initialScheduleFormValues = {
  reportName: "",
  sendTo: "",
  cc: "",
  subject: "",
  frequency: 1,
  every: 1,
  startDate: null,
  endDate: null,
  startTime: dayjs(),
  format: 1,
};

const isEmpty = (object) => JSON.stringify(object) === "{}"? true : false;

const ReportsTable = () => {
  const dispatch = useDispatch();
  const userRedux = useSelector(selectUser);
  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [type, setType] = useState(userRedux.reportConfig.type || "Invoice");
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [items, setItems] = useState(invoiceReportRows);
  const [itemCount, setItemCount] = useState(50);
  const [currentColumns, setCurrentColumns] = useState(userRedux.reportConfig.columns.length? userRedux.reportConfig.columns : invoiceColumns);
  const [disabledColumns, setDisabledColumns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [optionValues, setOptionValues] = useState( 
    !isEmpty(userRedux.reportConfig.options)? userRedux.reportConfig.options : { ...initialFormValues }
  );
  const [initOptionValues, setInitOptionValues] = useState(
    !isEmpty(userRedux.reportConfig.options)? userRedux.reportConfig.options : { ...initialFormValues }
  );
  const [scheduleValues, setScheduleValues] = useState(
    initialScheduleFormValues
  );
  const [dialogData, setDialogData] = useState({});
  const [openColumnChooser, setOpenColumnChooser] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openSave, setOpenSaveDialog] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [config, setConfig] = useState(!isEmpty(userRedux.reportPayload)? userRedux.reportPayload: invoiceReportDefault);
  const [processType, setProcessType] = useState("");
  const [reportId, setReportId] = useState(-1);
  const [reportName, setReportName] = useState("");
  const [finishFetch, setFinishFetch] = useState(false);
  const [openCA, setOpenCA] = useState(false);
  let { process } = useParams();

  const handleClickOpenColumnChooser = () => {
    setOpenColumnChooser(true);
  };

  const handleCloseColumnChooser = () => {
    setOpenColumnChooser(false);
  };
  const handleClickOpenFilters = () => {
    setOpenFilter(true);
  };

  const handleCloseFilters = () => {
    setOpenFilter(false);
  };

  const handleClickOpenSave = () => {
    setOpenSaveDialog(true);
  };

  const handleCloseSave = () => {
    setOpenSaveDialog(false);
  };

  const handleClickSchedule = () => {
    setOpenSchedule(true);
  };

  const handleCloseSchedule = () => {
    setOpenSchedule(false);
  };
  const handleClickExport = () => {
    setOpenExport(true);
  };

  const handleCloseExport = () => {
    setOpenExport(false);
  };

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const handleClickOpenClientAssociate = (row) => {
    setDialogData(row);
    setOpenCA(true);
  };

  const handleCloseClientAssociate = () => {
    setOpenCA(false);
  };

  const renderCell = ({ row }) => {
    return (
      <GradientSalesAssociateIcon
        onClick={() => {
        handleClickOpenClientAssociate(row);
        }}
      />
    );
  };

  const handleAccountHeader = (accounts) => {
    for (const account of accounts) { 
      dispatch(setSelectedCompany(account));
    }
  }

  const setReportConfiguration = (reportType, reportColumns, reportOptions, reportAccounts) => {
    dispatch(setReportConfig({type: reportType, columns: reportColumns, options: reportOptions, accounts: reportAccounts}));
  }

  const setReportPayloads = (payload) => { 
    dispatch(setReportPayload(payload));
  }

  const getActiveColumns = () => {
    return currentColumns.map((column) => {
      const columns = columnsDefinition[column.id];
      if (columns.field === 'salesAssociate') {
        columns.renderCell = renderCell
      }
      return columns;
    });
  };

  const getDefaultColumns = () => {
    if (type === "Invoice") {
      const columns = invoiceReportDefault.columns.map((column) => {
        const columnsDefinition = invoicesIdsToDefinition[column];
        return {
          id: columnsDefinition.field,
          headerName: columnsDefinition.headerName,
        };
      });
      return columns;
    } else {
      const columns = orderReportDefault.columns.map((column) => {
        const columnsDefinition = ordersIdsToDefinition[column];
        return {
          id: columnsDefinition.field,
          headerName: columnsDefinition.headerName,
        };
      });
      return columns;
    }
  };

  const buildDefaultReport = async (offset = 1, limit = 100) => {
    try {
      setIsLoading(true);
      setReportConfiguration(type, currentColumns, optionValues, selectedCompanyRedux.filter(account => account.id !== -1));
      setReportPayloads(config);
      const reportType = type === 'Orders'? 1 : 2;
      const disabledColumnsIds = getDisabledColumns(reportType, columnsParser(reportType, currentColumns));
    
      if (disabledColumnsIds.length > 0) {
        const columnsDisabled = disabledColumnsIds.map((column) => {
          if (type === "Orders") {
            const columnsDefinition = ordersIdsToDefinition[column];
            return {
              id: columnsDefinition.field,
              headerName: columnsDefinition.headerName,
            };
          } else {
            const columnsDefinition = invoicesIdsToDefinition[column];
            return {
              id: columnsDefinition.field,
              headerName: columnsDefinition.headerName,
            };
          }
        });
        setDisabledColumns(columnsDisabled);
      }

      if (disabledColumns === []) {
        const columns = getDefaultColumns();
        setCurrentColumns(columns);
      }

      const { data: dataReport } = await reportsAPI.buildReport(
        { limit: limit, offset: offset },
        config
      );

      const parsedData = dataReport.map((row, counter) => {
        return { id: counter, ...row };
      });

      setItems(parsedData);
      setItemCount(dataReport.length);
      setFinishFetch(true);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const buildPresetReport = async (
    reportId = "",
    offset = 1,
    limit = 25,
    search
  ) => {
    try {
      setIsLoading(true);

      const reportsPayload = {
        offset,
        limit,
        search,
      };
      if (search) Object.assign(reportsPayload, { search });

      const { data } = await reportsAPI.getPresetInfo(reportId);

      const presetInfo = data.presetInfo;
      setReportName(presetInfo.name);
      
      dispatch(resetSelectedCompany());
      if (presetInfo.accounts?.length) {
        handleAccountHeader(presetInfo.accounts);
      }

      setType(presetInfo.group === 1 ? "Orders" : "Invoice");
      setScheduleValues({
        reportName: presetInfo.name,
      });
      if (presetInfo.schedule.length > 0) {
        const schedule = presetInfo?.schedule[0];
        const startTime = dayjs(
          `${scheduleValues.startTime.format("YYYY-MM-DD")} ${schedule.time}`
        );

        setScheduleValues({
          reportName: presetInfo.name,
          sendTo: schedule.sendTo,
          cc: schedule.cc,
          subject: schedule.subject,
          frequency: schedule.runSchedule,
          every: schedule.every,
          startDate: schedule.from,
          endDate: schedule.to,
          startTime: startTime,
          format: 1,
        });
      }
      const columns = presetInfo.columns.map((column) => {
        if (presetInfo.group === 1) {
          const columnsDefinition = ordersIdsToDefinition[column];
          return {
            id: columnsDefinition.field,
            headerName: columnsDefinition.headerName,
          };
        } else {
          const columnsDefinition = invoicesIdsToDefinition[column];
          return {
            id: columnsDefinition.field,
            headerName: columnsDefinition.headerName,
          };
        }
      });

      const disabledColumnsIds = getDisabledColumns(
        presetInfo.group,
        presetInfo.columns
      );

      if (disabledColumnsIds.length > 0) {
        const columnsDisabled = disabledColumnsIds.map((column) => {
          if (presetInfo.group === 1) {
            const columnsDefinition = ordersIdsToDefinition[column];
            return {
              id: columnsDefinition.field,
              headerName: columnsDefinition.headerName,
            };
          } else {
            const columnsDefinition = invoicesIdsToDefinition[column];
            return {
              id: columnsDefinition.field,
              headerName: columnsDefinition.headerName,
            };
          }
        });
        setDisabledColumns(columnsDisabled);
      }

      const reportOptions = [];

      presetInfo.options.forEach((option) => {
        const value = filtersValueFrom(option.key, option.value);
        reportOptions.push(value);
      });

      let optionsObject = Object.assign({}, initOptionValues);
      reportOptions.forEach((options) => {
        const name = options.name;
        optionsObject[name] = options.value;
      });
      setInitOptionValues(optionsObject);
      setCurrentColumns(columns);

      const { data: dataReport } = await reportsAPI.buildReportFromId({
        reportId: reportId,
        offset: 1,
        limit: 100,
      });
      const parsedData = dataReport.map((row, counter) => {
        return { id: counter, ...row };
      });
      setItems(parsedData);
      setItemCount(dataReport.length);
      setFinishFetch(true);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    scrollUp();
    if (process === "new") {
      setProcessType("new");
      if (userRedux.reportConfig.accounts.length) {
        dispatch(resetSelectedCompany());
        handleAccountHeader(userRedux.reportConfig.accounts);
      }
      buildDefaultReport();
    } else {
      const processType = process.split("-");
      if (processType[0] === "load") {
        buildPresetReport(processType[1]);
        setReportId(processType[1]);
        setProcessType("load");
      }
      if (processType[0] === "edit") {
        setReportId(processType[1]);
        setProcessType("edit");
        buildPresetReport(processType[1]);
      }
    }
  }, []);

  useEffect(() => {
    setConfig({
      ...config,
      accounts: selectedCompanyRedux.filter(account => account.id !== -1),
    });
  }, [selectedCompanyRedux]);

  useEffect(() => {
    setConfig({
      ...config,
      options: optionsParser(optionValues),
    });
  }, [optionValues, setConfig, optionsParser]);

  useEffect(() => {
    if (type === "Invoice" && finishFetch === true) {
      setConfig({...invoiceReportDefault, accounts: selectedCompanyRedux.filter(account => account.id !== -1)});
    }
    if (type === "Orders" && finishFetch === true) {
      setConfig({...orderReportDefault, accounts: selectedCompanyRedux.filter(account => account.id !== -1)});
    }

    if (finishFetch === true) {
      setOptionValues(defaultFormValues);
      setInitOptionValues(defaultFormValues);
    }
  }, [type, setConfig]);

  useEffect(() => {
    if (finishFetch === true) {
      buildDefaultReport();
    }
  }, [config]);

  const renderTable = useMemo(
    () => (
      <CustomColorDataGrid
        defaultGroupingExpansionDepth={1}
        onPageChange={onPageChange}
        // paginationMode="server"
        page={currentPage - 1}
        rows={items}
        columns={getActiveColumns()}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        rowCount={itemCount}
        isRowSelectable={(params) => false}
        autoHeight
      />
    ),
    [currentPage, items, pageSize, itemCount, getActiveColumns]
  );

  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            backgroundColor: "#EEEEEE",
            paddingBottom: "10px",
            paddingLeft: "10px",
            paddingRight: "10px",
            marginLeft: "0px",
          }}
        >
          <Grid item xs={5}>
            <Grid container spacing={2} direction="row" justifyContent="flex">
              <Grid item>
                <Item onClick={() => handleClickOpenFilters()}>
                  {" "}
                  <SettingsIcon />
                  Options
                </Item>
              </Grid>
              <Grid item>
                <Item onClick={() => handleClickOpenColumnChooser()}>
                  {" "}
                  <ViewColumnIcon />
                  Manage Columns
                </Item>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
            >
              <Grid item>
                <Item onClick={() => handleClickExport()}>
                  <CloudDownloadIcon /> Export
                </Item>
              </Grid>

              <Grid item>
                <Item onClick={() => handleClickOpenSave()}>
                  {" "}
                  <SaveIcon />
                  Save Report
                </Item>
              </Grid>
              <Grid item>
                <Item onClick={() => handleClickSchedule()}>
                  <AccessTimeIcon /> Schedule Report
                </Item>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ color: "#B80A26" }} size={60} />
        </LoadingContainer>
      ) : (
        renderTable
      )}
      <ColumnChooserDialogComponent
        selectedValue={dialogData}
        open={openColumnChooser}
        onClose={handleCloseColumnChooser}
        currentColumns={currentColumns}
        setCurrentColumns={setCurrentColumns}
        type={type}
        setType={setType}
        disabledColumns={disabledColumns}
        setDisabledColumns={setDisabledColumns}
        optionValues={optionValues}
        selectedCompany={selectedCompanyRedux.filter(account => account.id !== -1)} 
        setReportConfiguration={setReportConfiguration}
        processType={processType}
      />
      <FilterDialogComponent
        selectedValue={dialogData}
        open={openFilter}
        onClose={handleCloseFilters}
        optionValues={optionValues}
        initOptionValues={initOptionValues}
        setOptionValues={setOptionValues}
        type={type}
      />
      <SaveDialogComponent
        selectedValue={dialogData}
        open={openSave}
        onClose={handleCloseSave}
        currentColumns={currentColumns}
        type={type}
        optionValues={optionValues}
        selectedCompany={selectedCompanyRedux.filter(account => account.id !== -1)} 
        scheduleValues={scheduleValues}
        processType={processType}
        reportId={reportId}
        reportName={reportName}
      />
      <ScheduleDialogComponent
        selectedValue={dialogData}
        open={openSchedule}
        onClose={handleCloseSchedule}
        setScheduleValues={setScheduleValues}
        selectedCompany={selectedCompanyRedux.filter(account => account.id !== -1)} 
        scheduleValues={scheduleValues}
        processType={processType}
        type={type}
        optionValues={optionValues}
        currentColumns={currentColumns}
        reportId={reportId}
      />
      <ExportDialogComponent
        selectedValue={dialogData}
        open={openExport}
        onClose={handleCloseExport}
        config={config}
        id={reportId}
        currentColumns={currentColumns}
        selectedCompany={selectedCompanyRedux.filter(account => account.id !== -1)} 
        optionValues={optionValues}
        type={type}
      />
      <SalesAssociateDialog
        selectedValue={dialogData}
        open={openCA}
        onClose={handleCloseClientAssociate}
        setIsLoading={setIsLoading}
        CustomColorDataGrid={CustomColorDataGrid}
        LoadingContainer={LoadingContainer}
      />
    </>
  );
};

export default ReportsTable;
