import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { styled as muiStyled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

const CustomColorDataGrid = muiStyled(DataGrid)(({ theme }) => ({
  fontFamily: 'Montserrat',
  '& .MuiDataGrid-row': {
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'rgba(68, 192, 180, 0.2)',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(239, 180, 71, 0.2)',
      '&:hover, &.Mui-hovered': {
        backgroundColor: 'rgba(239, 180, 71, 0.2)',
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
}));

const AutoResizeGrid = (props) => {
  const { columns, rows } = props;
  const [resizedColumns, setResizedColumns] = useState(columns);

  const gridRef = useRef();

  const autoSizeColumns = () => {
    if (!gridRef.current) {
      return setTimeout(autoSizeColumns, 1);
    }

    const loadedGridRows = [
      ...gridRef.current?.querySelectorAll('.MuiDataGrid-row'),
    ];

    const areRowsReady = props.rows?.length === 0 || loadedGridRows.length;

    if (!areRowsReady) {
      setTimeout(autoSizeColumns, 0);
      return;
    }

    const colsContainer = document.createElement('div');
    colsContainer.style =
      'visibility: hidden; z-index: -9999999999; position: absolute;  top: 0; left: 0;';

    document.body.append(colsContainer);

    const clonedColumns = _.clone(columns);

    clonedColumns.forEach((col, index) => {
      const allWidths = [];

      [
        ...gridRef.current.querySelectorAll(`div[data-field="${col.field}"]`),
      ].forEach((e) => {
        const cell = document.createElement('div');
        cell.innerHTML = e.innerHTML;
        cell.style = 'width: max-content; max-width: none; min-width: none;';
        colsContainer.append(cell);

        allWidths.push(Math.ceil(cell.clientWidth + 1));
      });

      if (allWidths.length > 0) {
        const maxWidth = Math.max(...allWidths) + 30;

        col.width = maxWidth;
        col.minWidth = maxWidth;

        if (maxWidth >= 300) col.maxWidth = 300;
      }
    });

    colsContainer.remove();

    setResizedColumns(clonedColumns);
  };

  useEffect(() => {
    setTimeout(() => {
      autoSizeColumns();
    }, 333);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columnVisibilityModel, rows, props.page]);

  return (
    <CustomColorDataGrid
      onResize={autoSizeColumns}
      ref={gridRef}
      {...props}
      columns={resizedColumns}
    />
  );
};

export default AutoResizeGrid;
