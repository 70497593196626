import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import authAPI from '../api/modules/authentication';
import { styles as SignInStyles } from "./SignIn/styles";
import { useNotification } from "../context/notificationContext";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import styled from 'styled-components';
import { MenuItem } from "@mui/material";
import { formatAddress } from "../Helpers/format";

const OptionList = styled.div`
  background-color: white;
  width: 100% !important;
  border-radius: 0px 0px 3px 3px;
  position: absolute;
  z-index: 3;
  max-height: 250px;
  overflow: auto;
  border-right: 1px solid;
  border-left: 1px solid;
  border-bottom: 1px solid;
  border-color: #c1c1c1;
`;

const Option = styled(MenuItem)`
  width: 100%;
  padding: 10px 10px !important;
  border: 1px solid;
`;


function SignUpComponent() {
  const styles = {
    formContainer: {
      marginTop: "13%",
      width: "65%",
      marginLeft: "17%",
      marginBottom: "10%",
    },
    requestedText: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
    },
    errorMessages: {
      color: '#B80A26'
    }
  };

  const [requestSubmitted, setRequestSubmitted] = React.useState(false);
  const { setNotification } = useNotification();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    accountExecutiveEmail: '',
    company: '',
    companyAddress1: '',
    companyAddress2: '',
    city: '',
    state: '',
    zipCode: '',
    mainUserPosition: '',
  }

  const validationSchemaSignUp = Yup.object({
    firstName: Yup.string().required('First name is required').max(50, 'First name must be at most 50 characters'),
    lastName: Yup.string().required('Last name is required').max(50, 'Last name must be at most 50 characters'),
    email: Yup.string().email('Valid email address required (must have @ and . to be valid)').required('Email is required').max(250, 'Email must be at most 250 characters'),
    accountExecutiveEmail: Yup.string().email('Valid email address required (must have @ and . to be valid)').max(250, 'Account executive email must be at most 250 characters'),
    company: Yup.string().required('Company name is required').max(100, 'Company name must be at most 100 characters'),
    companyAddress1: Yup.string().required('Address 1 is required').max(35, 'Address 1 must be at most 35 characters'),
    companyAddress2: Yup.string().max(35, 'Address 2 must be at most 35 characters'),
    city: Yup.string().required('City is required').max(50, 'City must be at most 50 characters'),
    state: Yup.string().required('State is required').max(50, 'State must be at most 50 characters'),
    zipCode: Yup.string().required('Zip code is required').max(6, 'Zip code must be at most 6 characters'),
  });

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await authAPI.signUp(values);
      setRequestSubmitted(true);
      resetForm();

    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });

    } finally {
      setSubmitting(false);
    }
  }

  const handleSelect = (address, values, setValues) => {
    geocodeByAddress(address)
      .then((results) => {
        const { address, city, state, zipCode } = formatAddress(results[0].address_components);

        setValues({
          ...values,
          companyAddress1: address || values.companyAddress1,
          city,
          state,
          zipCode,
        });

      }).catch((error) => console.error('Error', error));
  };

  return (
    <Box sx={styles.formContainer}>
      <div style={SignInStyles.signInTypo}>{"Request Access"}</div>
      {
        requestSubmitted ? (
          <span style={styles.requestedText}>
            Your request has been submitted. A Microage representative will be in touch shortly.
          </span>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchemaSignUp}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={onSubmit}
            enableReinitialize
          >
            {({ errors, isSubmitting, isValid, values, handleChange, setValues }) => (
              <>
                <Form>
                  <Stack spacing={2}>
                    <TextField
                      label="Email"
                      onChange={handleChange('email')}
                      value={values.email}
                      helperText={errors.email ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="email"
                      variant="outlined"
                      inputProps={{ maxLength: 250 }}
                    />
                    <TextField
                      label="First Name"
                      variant="outlined"
                      onChange={handleChange('firstName')}
                      value={values.firstName}
                      helperText={errors.firstName ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="firstName"
                      inputProps={{ maxLength: 50 }}
                    />
                    <TextField
                      label="Last Name"
                      variant="outlined"
                      onChange={handleChange('lastName')}
                      value={values.lastName}
                      helperText={errors.lastName ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="lastName"
                      inputProps={{ maxLength: 50 }}
                    />
                    <TextField
                      label="Company"
                      variant="outlined"
                      onChange={handleChange('company')}
                      value={values.company}
                      helperText={errors.company ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="company"
                      inputProps={{ maxLength: 100 }}
                    />

                    <Box position={"relative"}>
                      <PlacesAutocomplete
                        value={values.companyAddress1}
                        onSelect={(address) => handleSelect(address, values, setValues)}
                        onChange={handleChange('companyAddress1')}
                        searchOptions={{ componentRestrictions: { country: ['us'] } }}
                      >
                        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                          <div>
                            <TextField
                              fullWidth
                              label="Company Adress 1"
                              variant="outlined"
                              helperText={errors.companyAddress1 ?? ''}
                              FormHelperTextProps={{ style: styles.errorMessages }}
                              name="companyAddress1"
                              inputProps={{ maxLength: 35 }}

                              {...getInputProps({
                                className: 'location-search-input',
                              })}
                            />
                            {
                              suggestions.length > 0 && (
                                <OptionList>
                                  {suggestions.map((suggestion) => {
                                    return (
                                      <Option
                                        key={suggestion.placeId}
                                        {...getSuggestionItemProps(suggestion, {})}
                                      >
                                        {suggestion.description}
                                      </Option>
                                    );
                                  })}
                                </OptionList>
                              )
                            }
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Box>

                    <TextField
                      label="Company Address 2"
                      variant="outlined"
                      onChange={handleChange('companyAddress2')}
                      value={values.companyAddress2}
                      helperText={errors.companyAddress2 ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="companyAddress2"
                      inputProps={{ maxLength: 35 }}
                    />

                    <Box sx={{ display: "flex", justifyContent: "initial" }}>
                      <TextField
                        sx={{ marginRight: "7px" }}
                        label="City"
                        variant="outlined"
                        onChange={handleChange('city')}
                        value={values.city}
                        helperText={errors.city ?? ''}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        name="city"
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        sx={{ marginRight: "7px" }}
                        label="State"
                        variant="outlined"
                        onChange={handleChange('state')}
                        value={values.state}
                        helperText={errors.state ?? ''}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        name="state"
                        inputProps={{ maxLength: 50 }}
                      />
                      <TextField
                        label="Zip Code"
                        variant="outlined"
                        onChange={handleChange('zipCode')}
                        value={values.zipCode}
                        helperText={errors.zipCode ?? ''}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        name="zipCode"
                        inputProps={{ maxLength: 6 }}
                      />
                    </Box>
                    <TextField
                      label="Account Executive Email"
                      variant="outlined"
                      onChange={handleChange('accountExecutiveEmail')}
                      value={values.accountExecutiveEmail}
                      helperText={errors.accountExecutiveEmail ?? ''}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                      name="accountExecutiveEmail"
                      inputProps={{ maxLength: 250 }}
                    />

                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        variant="blueGradient-outlined"
                        sx={SignInStyles.submitButton}
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Submit
                      </Button>
                    </Box>
                  </Stack>
                </Form>
              </>
            )}
          </Formik>
        )
      }
    </Box>
  );
}

export default SignUpComponent;
