
import { Snackbar } from '@mui/material';
import styled from 'styled-components';
import Icons from '../icons';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 40px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
`;

const Message = styled.span`
  font-family: 'OpenSans';
  display: block;
  margin-left: 20px;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: #444444;
`;

export const Notification = ({ message, type, open, handleClose }) => {
  const getIconType = () => {
    switch (type) {
      case 'error':
        return <Icons.ErrorIcon />
      case 'success':
        return <Icons.SuccessIcon />
      default:
        return <Icons.SuccessIcon />
    }
  }

  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Container>
        {getIconType()}
        <Message>
          {message}
        </Message>
      </Container>
    </Snackbar>
  );
};
