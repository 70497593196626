import styled from 'styled-components';

export const TitleHeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-height: 85px;
  align-items: center;
`;

export const TitleHeaderCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
