import React from 'react';

const MessageIcon = ({
  color = '#B80A26',
  fillColor = '#FFF',
  width = 56,
  height = 56,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_6657_108948)">
        <circle cx="28" cy="27" r="26" fill={color} />
      </g>
      <path d="M37.1229 15.5966H18.8773C17.6229 15.5966 16.608 16.6229 16.608 17.8773L16.5966 38.4036L21.158 33.8422H37.1229C38.3773 33.8422 39.4036 32.8159 39.4036 31.5615V17.8773C39.4036 16.6229 38.3773 15.5966 37.1229 15.5966ZM21.158 23.579H34.8422V25.8597H21.158V23.579ZM30.2808 29.2808H21.158V27.0001H30.2808V29.2808ZM34.8422 22.4387H21.158V20.158H34.8422V22.4387Z" fill={fillColor} />
      <defs>
        <filter id="filter0_d_6657_108948" x="0" y="0" width="56" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6657_108948" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6657_108948" result="shape" />
        </filter>
      </defs>
    </svg>
  );
}

export default MessageIcon;