import React from 'react';

const ThumbUpIcon = ({
  width = 20,
  height = 18,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.833496 17.5002H4.16683V7.50016H0.833496V17.5002ZM19.1668 8.3335C19.1668 7.41683 18.4168 6.66683 17.5002 6.66683H12.2418L13.0335 2.8585L13.0585 2.59183C13.0585 2.25016 12.9168 1.9335 12.6918 1.7085L11.8085 0.833496L6.32516 6.32516C6.01683 6.62516 5.8335 7.04183 5.8335 7.50016V15.8335C5.8335 16.7502 6.5835 17.5002 7.50016 17.5002H15.0002C15.6918 17.5002 16.2835 17.0835 16.5335 16.4835L19.0502 10.6085C19.1252 10.4168 19.1668 10.2168 19.1668 10.0002V8.3335Z" fill="url(#paint0_linear_8374_85167)" />
      <defs>
        <linearGradient id="paint0_linear_8374_85167" x1="1.6306" y1="0.833496" x2="17.4338" y2="19.0072" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5058F2" />
          <stop offset="1" stopColor="#44C0B4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default ThumbUpIcon;
