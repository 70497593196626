import { PluginService } from "../pluginService";

const roleService = PluginService("");

export default {
  getRoles(params) {
    return roleService({
      method: "GET",
      url: `/roles`,
      params
    });
  },
};
