import React from "react";
import * as ReactDOM from 'react-dom/client';
import './fonts/Montserrat/Montserrat-VariableFont_wght.ttf';
import './fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf';
import "./index.css";
import App from "./App";


const root = ReactDOM.createRoot(
  document.getElementById('root')
);
root.render(<App/>);

{/* // If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(); */}
