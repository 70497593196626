import React from 'react';
import styled from 'styled-components';
import {
  Box,
  Grid,
  Button,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import OrderInvoiceTable from './OrderInvoiceTable';
import { Search as SearchIcon } from '@mui/icons-material';
import { useState } from 'react';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import {
  BlueAquaSearchButtonOutline,
  BodyContainer,
  BodyGridWrapper,
} from './Shared/BodyContainer/Index';
import MixpanelHelper from '../Helpers/MixpanelHelper';
import { useSelector } from 'react-redux';
import { getSelectedCompany } from '../redux/features/user/userSlice';

const FormControlSelect = styled(FormControl)`
  background-color: #fff;

  &:before {
    content: 'Status: ';
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #9e9e9e;
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translate(0, -50%);
  }

  .MuiSelect-select {
    padding-left: 81px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #444444;
  }

  fieldset {
    border: none;
  }
`;

const styles = {
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '31%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
  orderStatusBar: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    marginTop: '9px',
  },
};

const OrderStatus = [
  {
    value: 'all',
    label: 'All',
  },
  {
    value: 'open',
    label: 'Open',
  },
  {
    value: 'released',
    label: 'Released',
  },
  {
    value: 'pending approval',
    label: 'Pending Approval',
  },
  {
    value: 'pending prepayment',
    label: 'Pending Prepayment',
  },
];

const OrderInvoicesDashboardComponent = () => {
  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [selectedFilter, setSelectedFilter] = useState('open_orders');
  const [orderStatus, setOrderStatus] = useState('all');
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const onFilterChange = (filter) => {
    setSelectedFilter(filter);

    const tabName = filter === 'open_orders' ? 'Open Orders' : filter === 'open_invoices' ? 'Open Invoices' : 'Paid Invoices';
    const company = selectedCompanyRedux?.[0]?.name ?? 'All';
    MixpanelHelper.track('Order Invoice Dashboard', { 'tab': tabName, company: company })
  };

  const isOrder = selectedFilter === 'open_orders' ? true : false;

  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ minHeight: 'inherit', display: 'inherit' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopContainer>
              <TitleHeaderRow>
                <TitleHeaderCol>
                  <Box
                    sx={{
                      fontFamily: 'OpenSans',
                      fontWeight: 400,
                      fontSize: '14px',
                      textTransform: 'uppercase',
                      fontStyle: 'normal',
                      letterSpacing: '0.15px',
                      lineHeight: '20px',
                    }}
                  >
                    End-to-End Services
                  </Box>

                  <Box
                    sx={{
                      height: '65px',
                      fontFamily: 'OpenSans',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '48px',
                      lineHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Orders / Invoices
                  </Box>
                </TitleHeaderCol>
              </TitleHeaderRow>
            </TopContainer>
          </Grid>

          <BodyGridWrapper item xs={12} md={12}>
            <BodyContainer>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                {!isSearchOpen && (
                  <>
                    <Button
                      style={{ flexGrow: 1, marginRight: '24px' }}
                      onClick={() => onFilterChange('open_orders')}
                      color="customRed"
                      variant={
                        selectedFilter === 'open_orders'
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={styles.filterButton}
                    >
                      Open Orders
                    </Button>
                    <Button
                      style={{ flexGrow: 1, marginRight: '24px' }}
                      onClick={() => onFilterChange('open_invoices')}
                      color="customRed"
                      variant={
                        selectedFilter === 'open_invoices'
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={styles.filterButton}
                    >
                      Open Invoices
                    </Button>
                    <Button
                      style={{ flexGrow: 1, marginRight: '24px' }}
                      onClick={() => onFilterChange('closed_invoices')}
                      color="customRed"
                      variant={
                        selectedFilter === 'closed_invoices'
                          ? 'contained'
                          : 'outlined'
                      }
                      sx={styles.filterButton}
                    >
                      Paid Invoices
                    </Button>
                    <BlueAquaSearchButtonOutline
                      onClick={() => setIsSearchOpen(true)}
                      variant="blueGradient-outlined"
                    >
                      <SearchIcon fontSize={'large'} />
                    </BlueAquaSearchButtonOutline>
                  </>
                )}
              </Box>
              {isOrder && !isSearchOpen && false && (
                <Grid container>
                  <Grid item xs={12} md={3} sx={styles.orderStatusBar}>
                    <FormControlSelect fullWidth>
                      <Select
                        fullWidth
                        labelId="ddlFilter"
                        value={orderStatus}
                        onChange={({ target }) => setOrderStatus(target.value)}
                      >
                        {OrderStatus.map(({ label, value }) => (
                          <MenuItem key={value} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControlSelect>
                  </Grid>
                </Grid>
              )}
              <OrderInvoiceTable
                selectedFilter={selectedFilter}
                isSearchOpen={isSearchOpen}
                setIsSearchOpen={setIsSearchOpen}
                orderStatus={orderStatus}
              />
            </BodyContainer>
          </BodyGridWrapper>
        </Grid>
      </Box>
    </>
  );
};

export default OrderInvoicesDashboardComponent;
