import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import MicroAgeLogo from '../components/MicroAgeLogo';
import MicroAgeWaves from '../components/MicroAgeWaves';
import Image from '../images/micro_age_cover.png';
import usePageTracking from '../hooks/usePageTracking';

const Container = styled.div`
  height: 100vh;
  position: relative;
`;

const Text404 = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 160px;
  line-height: 187px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 10px;
  color: #FFFFFF;
`;

const OopsText = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 300;
  font-size: 34px;
  line-height: 123.5%;
  letter-spacing: 0.25px;
  text-align:center;
  color: #FFFFFF;
  margin-bottom: 14px;
`;

const ImageWrapper = styled.div`
  display: flex;
  height: 100%;
  max-height: 100%;
  background-image: url(${Image});
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  background-position: center;
`;

const NotFoundText = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align:center;
  color: #FFFFFF;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Link = styled(NavLink)`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  color: #FFFFFF;
  margin-top: 36px;
  border: 3px solid #fff;
  padding: 4px 24px;
  text-decoration: none;
`;

const LogoWrapper = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
`;

const WavesWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  .svg{
    height: 100%;
    width: 100%;
  }
`;

const PageNotFound = () => {
  usePageTracking();

  return (
    <Container>
      <WavesWrapper>
        <MicroAgeWaves />
      </WavesWrapper>
      <LogoWrapper>
        <MicroAgeLogo />
      </LogoWrapper>
      <ImageWrapper>
        <TextContainer>
          <Text404>404</Text404>
          <OopsText>Oops.. Something went wrong.</OopsText>
          <NotFoundText>The page you are looking for can't be found.</NotFoundText>
          <Link to={'/dashboard'}>GO BACK HOME</Link>
        </TextContainer>
      </ImageWrapper>
    </Container>
  );
}

export default PageNotFound;
