import React, { useEffect, useMemo, useState } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  IconButton,
} from "@mui/material";
import styled from "styled-components";
import { DataGrid } from "@mui/x-data-grid";
import { SearchOutlined } from "@mui/icons-material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { trackingRows } from "../mocks/trackingData";
import orderInvoicesAPI from "../api/modules/orderInvoices";
import { useSelector } from "react-redux";
import { getSelectedCompany } from "../redux/features/user/userSlice";
import Icons from "./icons";
import dayjs from "dayjs";

const CustomColorDataGrid = muiStyled(DataGrid)(({ theme }) => ({
  fontFamily: "Montserrat",
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

const UnderlinedText = styled.span`
  cursor: pointer;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: ${({ color = "#B80A26" }) => color};
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 60px 0;
`;

const Container = styled.div`
  display: flex;

  .MuiTextField-root {
    width: 100%;
  }
`;

const GridDate = styled(Grid)`
  @media (min-width: 1024px) {
    max-width: 47% !important;
    flex-basis: 47% !important;
    width: 47% !important;
    margin: 0 1px !important;
  }
`;

const GridSearch = styled(Grid)`
  @media (min-width: 1024px) {
    max-width: 4% !important;
    flex-basis: 4% !important;
    width: 4% !important;
  }
`;

const SearchButton = styled.div`
  cursor: pointer;
  background: linear-gradient(136.27deg, #5058f2 2.13%, #44c0b4 100%);
  height: 100%;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchInput = styled(TextField)`
  // border: 1px solid #e0e0e0 !important;

  .MuiOutlinedInput-input {
    &::placeholder {
      opacity: 1;
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }
  borderRadius: 0px !important;

  fieldset {
    border: 1px solid #e0e0e0;
   
    
  }
`;
const SelectInput = styled(Select)`
  border: 1px solid #e0e0e0 !important;

  .MuiOutlinedInput-input {
    &::placeholder {
      opacity: 1;
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }

  fieldset {
    border: none;
  }
`;

const TrackingTable = ({ selectedFilter }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [items, setItems] = useState(trackingRows);
  const [itemCount, setItemCount] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("All");
  const [search, setSearch] = useState("");

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  console.log("startDate", dayjs(startDate).format("YYYY-MM-DD"));
  const handleChangeDatePicker = (type, newValue) => {
    if (type === "startDate") {
      setStartDate(newValue);
      return;
    }
    if (type === "endDate") {
      setEndDate(newValue);
      return;
    }
  };

  const handleSubmit = () => {
    getTrackingData(
      selectedCompanyRedux,
      currentPage,
      pageSize,
      filter,
      startDate,
      endDate,
      search
    );
  };

  const handleClose = () => {
    setSearch("");
  };

  const getTrackingData = async (
    selectedCompany,
    offset = 1,
    limit = 25,
    filter,
    startDate,
    endDate,
    search
  ) => {
    try {
      setIsLoading(true);

      const ordersPayload = {
        filter: filter === "All" ? "" : filter,
        offset,
        limit,
        search,
        startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "",
        endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "",
      };

      if (search) Object.assign(ordersPayload, { search });

      if (selectedCompany?.id && selectedCompany.id > -1) {
        Object.assign(ordersPayload, {
          accountId: selectedCompany.id,
        });
      }

      const { data } = await orderInvoicesAPI.getOrders(ordersPayload);

      setItems(data.orders);
      setItemCount(data.count);
      console.log("items", data.orders);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };


  useEffect(() => {
    getTrackingData(
      selectedCompanyRedux,
      currentPage,
      pageSize,
      filter,
      startDate,
      endDate,
      search
    );
  }, [selectedCompanyRedux, currentPage, pageSize, filter, startDate, endDate]);

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const columns = [
    {
      field: "poNo",
      headerName: "PO #",
      flex: 1,
      valueGetter: ({ row }) => {
        return row.poNo;
      },
    },
    {
      field: "invoice",
      headerName: "Invoice",
      flex: 1,
      valueGetter: ({ row }) => row.invoice,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 180,
      flex: 1,
      valueGetter: ({ row }) => row.description,
    },
    {
      field: "partNo",
      headerName: "Part N",
      flex: 1,
    },
    {
      field: "manufacturerDate",
      headerName: "Manufacturer Date",
      flex: 1,
    },
    {
      field: "serialNo",
      headerName: "Serial N",
      flex: 1,
    },
    {
      field: "qBoxes",
      headerName: "Q. Boxes",
      flex: 1,
    },
    {
      field: "trackingN",
      headerName: "Tracking N",
      flex: 1,
      renderCell: ({ row }) => <UnderlinedText>{row.trackingN}</UnderlinedText>,
    },
  ];

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const renderTable = useMemo(
    () => (
      <CustomColorDataGrid
        defaultGroupingExpansionDepth={1}
        onPageChange={onPageChange}
        // paginationMode="server"
        page={currentPage - 1}
        sx={{ marginTop: "10px" }}
        rows={items}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        rowCount={itemCount}
        isRowSelectable={(params) => false}
        autoHeight
      />
    ),
    [currentPage, items, pageSize, itemCount]
  );

  return (
    <>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <SearchInput
              fullWidth
              placeholder="Search Order#, PO#, Invoice#"
              value={search}
              autoFocus
              onChange={({ target }) => setSearch(target.value)}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") handleSubmit();
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <>
                      <IconButton title="Search" onClick={handleSubmit}>
                        <Icons.SearchIcon />
                      </IconButton>

                      <IconButton title="Close" onClick={handleClose}>
                        <Icons.CloseIcon />
                      </IconButton>
                    </>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <Grid container spacing={1}>
              <GridDate item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(value) => {
                      handleChangeDatePicker("startDate", value);
                    }}
                    value={startDate}
                    label="Start Date"
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <SearchInput {...params} />}
                    components={{
                      OpenPickerIcon: Icons.DatePickerIcon,
                    }}
                  />
                </LocalizationProvider>
              </GridDate>

              <GridDate item xs={5}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    onChange={(value) => {
                      handleChangeDatePicker("endDate", value);
                    }}
                    value={endDate}
                    label="End Date"
                    inputFormat="MM/DD/YYYY"
                    renderInput={(params) => <SearchInput {...params} />}
                    components={{
                      OpenPickerIcon: Icons.DatePickerIcon,
                    }}
                  />
                </LocalizationProvider>
              </GridDate>
            </Grid>
          </Grid>

          <Grid item xs={12} md={2}>
            <FormControl fullWidth>
              <InputLabel id="ddlFilter">Status</InputLabel>
              <Select
                fullWidth
                labelId="ddlFilter"
                value={filter}
                label="Status"
                onChange={handleChange}
              >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"Open"}>Open</MenuItem>
                <MenuItem value={"Closed"}>Closed</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Container>

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ color: "#B80A26" }} size={60} />
        </LoadingContainer>
      ) : (
        renderTable
      )}
    </>
  );
};

export default TrackingTable;
