import React, { useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  DialogTitle,
  Dialog,
  Box,
  IconButton,
  Checkbox,
  Grid,
  TextField,
  FormControl,
  CircularProgress,
  Stack,
  Button,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { styles } from "./AccountExecutiveInvite/styles";
import { SearchResultItem } from "./Search/SearchResult";
import { SearchResultNotFound } from "./Search/SearchResultNotFound";
import { selectUser } from "../redux/features/user/userSlice";
import {
  convertClientPermisionsToNames,
  convertClientPermissionToId,
  convertClientRoleToId,
} from "../Helpers/clientRolesPermissions";
import { SearchInputField } from "./SearchInputField";
import { styles as signInStyles } from "./SignIn/styles";
import Icons from "./icons";
import accountAPI from "../api/modules/accounts";
import authAPI from "../api/modules/authentication";
import userAPI from "../api/modules/users";
import { useNotification } from "../context/notificationContext";
import { ConfirmDialog } from "./Dialogs/ConfirmDialog";
import { AeInfo } from "./AccountExecutiveInvite/AeInfo";

const Label = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 16px;
  display: block;
`;

const CustomColorDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

const ResultsContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: scroll;
  height: 245px;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0 40px 0;
`;

const RemoveUser = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: unset !important;
  color: #b80a26;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const useOutsideClick = (ref, setIsAccountListVisible) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsAccountListVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
};

const AccountExecutiveModal = (props) => {
  const { client, open, handleSubmit, onClose } = props;
  const [pageSize, setPageSize] = useState(25);
  const [tableRows, setTableRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payloadAction, setPayloadAction] = useState({
    action: "",
    payload: {},
  });
  const [isSearching, setIsSearching] = useState(false);
  const [assignedAccounts, setAssignedAccounts] = useState([]);
  const [aeAccounts, setAeAccounts] = useState([]);
  const [isAccountListVisible, setIsAccountListVisible] = useState(false);
  const [lastSearch, setLastSearch] = useState("");
  const [textToSearch, setTextToSearch] = useState("");
  const [emailToSearch, setEmailToSearch] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [step, setStep] = useState(client !== null ? 1 : 0);
  const [hasAccounts, setHasAccounts] = useState(true);
  const [accountsError, setAccountsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const initialValues = {
    fullName: "",
    email: emailToSearch,
  };
  const [initialFormValues, setInitialFormValues] = useState(initialValues);

  const userRedux = useSelector(selectUser);
  const { setNotification } = useNotification();

  const wrapperRef = useRef(null);
  const titleMessage = client
    ? "Edit Account Executive User"
    : "Invite New Account Executive User";
  const { action, payload } = payloadAction;

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Valid email address required (must have @ and . to be valid)")
      .required("Email is required")
      .max(250, "Email must be at most 250 characters"),
      fullName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be at most 50 characters"),
  });

  useOutsideClick(wrapperRef, setIsAccountListVisible);

  const handlePermissionChange = (clickedRow) => {
    const { field } = clickedRow;
    const updatedData = tableRows.map((row) => {
      if (row.No === clickedRow.id) {
        const newFieldValue = !row[field];
        if (field === "readOnly" && newFieldValue) {
          return {
            No: row.No,
            Name: row.Name,
            readOnly: newFieldValue,
            createReports: false,
            userManagement: false,
          };
        }

        if (field === "createReports" && newFieldValue) {
          return {
            No: row.No,
            Name: row.Name,
            readOnly: false,
            [field]: newFieldValue,
          };
        }
        if (field === "userManagement" && newFieldValue) {
          return {
            No: row.No,
            Name: row.Name,
            readOnly: false,
            [field]: newFieldValue,
            createReports: row.createReports,
          };
        }
      }

      return row;
    });

    setTableRows(updatedData);
  };

  const renderPermissionCheckBox = (params) => {
    return (
      <Checkbox
        checked={params.value}
        onChange={() => handlePermissionChange(params)}
        sx={{
          color: "#E0E0E0",
          "&.Mui-checked": {
            color: "#44C0B4",
          },
        }}
      />
    );
  };

  const handleClose = () => {
    setTableRows([]);
    onClose();
    setLastSearch("");
    setTextToSearch("");
    setAssignedAccounts([]);
    setIsAccountListVisible(false);
    setShowAccounts(false);
    setEmailToSearch("");
    setInitialFormValues({
      fullName: "",
      email: "",
    });
    setStep(0);
    setAccountsError(false);
    setErrorMessage("");
    setUserInfo({});
  };

  const onSubmit = async (values, { resetForm }) => {
    try {
      const data = {
        ...values,
        role: convertClientRoleToId(userRedux.roles, "ACCOUNT_EXECUTIVE"),
        access: tableRows.map((row) => {
          const account = {
            accounts: row.No,
            permissions: [],
          };

          if (row.createReports)
            account.permissions.push(
              convertClientPermissionToId(
                userRedux.permissions,
                "REPORT_CREATION"
              )
            );

          if (row.userManagement)
            account.permissions.push(
              convertClientPermissionToId(
                userRedux.permissions,
                "USER_MANAGEMENT"
              )
            );

          if (row.readOnly)
            account.permissions.push(
              convertClientPermissionToId(userRedux.permissions, "READ_ONLY")
            );

          return account;
        }),
      };

      if (client) {
        setPayloadAction({
          action: "edit",
          payload: {
            ...data,
            id: client.id,
          },
        });

        return setIsConfirmOpen(true);
      }

      setIsSubmitting(true);

      await authAPI.inviteExecutive(data);

      setNotification({
        open: true,
        message: (
          <>
            <b>
              {data.fullName}
            </b>
            {" has been invited!"}
          </>
        ),
      });

      handleOnAfterSubmit();
      resetForm();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const onAddAccountExecutive = (No) => {
    const accountToAdd = assignedAccounts.find((ae) => ae.No === No);

    const exists = tableRows.find((row) => row.No === No);

    if (!exists) {
      setTableRows((prev) => [
        ...prev,
        {
          ...accountToAdd,
          createReports: false,
          userManagement: false,
          readOnly: false,
        },
      ]);
    }
  };

  const onSearchHandler = async (text) => {
    try {
      setIsSearching(true);
      const searchText = text.toLowerCase();
      const filteredData = aeAccounts.filter((account) => {
        return account.Name.toLowerCase().includes(searchText);
      });
      setLastSearch(text);
      setIsAccountListVisible(true);
      setAssignedAccounts(filteredData);
    } catch (error) {
    } finally {
      setIsSearching(false);
    }
  };

  const onRemoveAccountExecutive = (id) => {
    setTableRows((prev) => prev.filter((row) => row.id !== id));
  };

  const renderAccountExecutiveList = useMemo(
    () =>
      assignedAccounts.map((company) => (
        <SearchResultItem
          key={company.No}
          onClick={onAddAccountExecutive}
          title={`${company.Name}`}
          subtitle=""
          isSelected={tableRows.find((row) => row.No === company.No)}
          id={company.No}
        />
      )),
    [tableRows, assignedAccounts]
  );

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (textToSearch.trim().length >= 1) {
        onSearchHandler(textToSearch.trim());
      } else {
        setIsSearching(false);
      }
    }, 1000);

    return () => clearTimeout(delayFn);
  }, [textToSearch]);

  const getUserData = async () => {
    try {
      setIsLoading(true);

      const { data } = await userAPI.getUserInfo(client.id);
      const user = data.data;

      setInitialFormValues({
        fullName: user.fullName,
        email: user.email,
      });
      setUserInfo({
        name: user.fullName,
        email: user.email,
        phoneNumber: user.phoneNumber,
      });

      const aeAccounts = await accountAPI.getUserAccounts({
        userId: client.id,
      });
      // Find assigned accounts to the AE
      const _assignedAccounts = aeAccounts.data.data.companyInfo.map(
        (account) => {
          let readOnly = false;
          let createReports = false;
          let userManagement = false;

          const permissionNames = convertClientPermisionsToNames(
            userRedux.permissions,
            account.permissions
          );

          permissionNames.forEach((permissionName) => {
            if (permissionName === "REPORT_CREATION") {
              createReports = true;
            }

            if (permissionName === "USER_MANAGEMENT") {
              userManagement = true;
            }

            if (permissionName === "READ_ONLY") {
              readOnly = true;
            }
          });

          return {
            No: account.id,
            Name: account.company,
            ...account,
            readOnly,
            createReports,
            userManagement,
          };
        }
      );

      setTableRows(_assignedAccounts);
    } catch (error) {
      console.log({ error });
    } finally {
      setShowAccounts(true);
      setIsLoading(false);
      setStep(1);
    }
  };
  const getUserDataBC = async (email) => {
    try {
      setIsLoading(true);

      const aeAccounts = await accountAPI.getAEAccounts({
        aemail: email,
      });

      setInitialFormValues({
        email: email,
        fullName: aeAccounts.data.name,
      });
      setAeAccounts(aeAccounts.data.accounts);
      const accounts = aeAccounts.data.accounts.map((account) => {
        let createReports = false;
        let userManagement = false;
        let readOnly = true;

        account.permissions.forEach((permission) => {
          if (permission === 5) {
            createReports = true;
            readOnly = false;
          }
          if (permission === 6) {
            userManagement = true;
            readOnly = false;
          }
        });

        return {
          ...account,
          createReports,
          readOnly,
          userManagement,
        };
      });
      setTableRows(accounts);
      if (accounts.length > 0) {
        setStep(1);
      }
      setUserInfo({
        name: aeAccounts.data.name,
        email: aeAccounts.data.E_Mail,
        phoneNumber: aeAccounts.data.Phone_No_,
      });
      setShowAccounts(true);
    } catch (error) {
      console.log({ error });
      setHasAccounts(false);
      setAccountsError(true);
      setErrorMessage(`${error.response.data.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (client) {
      getUserData();
    }

    return () => {
      setTableRows([]);
      setInitialFormValues(initialValues);
    };
  }, [client]);

  const handleRemoveUser = async () => {
    setPayloadAction({
      action: "delete",
      payload: {
        id: client.id, 
        fullName: client.fullName
      }
    });

    setIsConfirmOpen(true);
  };

  const handleOnAfterSubmit = async () => {
    handleClose();
    handleSubmit();
    setTimeout(() => {
      setIsConfirmOpen(false);
    }, 0);
  };

  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      if (action === "edit") {
        await userAPI.editExecutive({ id: client.id }, payload);

        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.fullName}
              </b>
              {" has been updated."}
            </>
          ),
        });
      } else if (action === "delete") {
        await userAPI.deleteUser({ id: payload.id });
        setNotification({
          open: true,
          message: (
            <>
              <b>
                {payload.fullName}
              </b>
              {" has been removed from the system."}
            </>
          ),
        });
      }

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };

  const columns = [
    {
      field: "Name",
      headerName: "Company",
      minWidth: 195,
      flex: 1,
      valueGetter: ({ row }) => {
        return row.Name;
      },
    },
    {
      field: "createReports",
      headerName: "Create Reports",
      minWidth: 111,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "userManagement",
      headerName: "User Management",
      minWidth: 125,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "readOnly",
      headerName: "Read Only",
      minWidth: 125,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
  ];
  const renderAccountsTable = useMemo(
    () => (
      <CustomColorDataGrid
        disableSelectionOnClick
        sx={{ marginTop: "10px", fontFamily: "Montserrat" }}
        getRowId={(row) => row.No}
        autoHeight
        rows={tableRows}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
      />
    ),
    [pageSize, tableRows]
  );

  const dialogText =
    action === "edit" ? (
      "If you continue, you cannot undo this action."
    ) : (
      <>
        Are you sure you want to remove{" "}
        <b color="#b80a26">
          {payload?.fullName}
        </b>
        . This action cannot be undone.{" "}
      </>
    );

  if (!open) return <></>;

  const checkEmailButton = (
    <Button
      variant="blueGradient-outlined"
      sx={signInStyles.submitButton}
      onClick={() => {
        getUserDataBC(emailToSearch.trim());
      }}
      disabled={emailToSearch.length < 4}
    >
      Check Email
    </Button>
  );

  const removeUserButton = (
    <Box alignSelf="center" onClick={handleRemoveUser} sx={{ mr: 2 }}>
      <RemoveUser>Remove User</RemoveUser>
    </Box>
  );

  const backButton = (
    <Box
      alignSelf="center"
      onClick={() => {
        if (step === 1) {
          setStep(0);
          setIsLoading(true);
          setShowAccounts(false);
          const delayFn = setTimeout(() => {
            setIsLoading(false);
          }, 500);

          return () => clearTimeout(delayFn);
        }
        if (step === 0) {
          handleClose();
        }
      }}
      sx={{ mr: 2 }}
    >
      <RemoveUser>{"< Back"}</RemoveUser>
    </Box>
  );
  const inviteUserButton = (
    <Button
      variant="blueGradient-outlined"
      sx={signInStyles.submitButton}
      type="submit"
      disabled={isSubmitting}
    >
      {client ? "Save" : "Invite →"}
    </Button>
  );

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={action === "delete" ? "Delete" : "Okay"}
        isSubmitting={isSubmitting}
        title={
          action === "edit"
            ? "Are you sure you want to continue?"
            : "Remove Account"
        }
        text={dialogText}
      />

      <Dialog
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"md"}
        scroll="body"
        hidden={isConfirmOpen}
      >
        <DialogTitle sx={styles.DialogHeader}>
          {titleMessage}{" "}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        {isLoading ? (
          <Box>
            <LoadingContainer>
              <CircularProgress style={{ color: "#B80A26" }} />
            </LoadingContainer>
          </Box>
        ) : (
          <Box sx={styles.formContainer}>
            <Formik
              validationSchema={validationSchema}
              initialValues={initialFormValues}
              onSubmit={onSubmit}
              enableReinitialize
            >
              {({ errors, values, handleChange, setFieldValue }) => (
                <>
                  {step === 1 && <AeInfo {...userInfo} />}

                  <Form style={styles.columnForm}>
                    <FormControl
                      sx={{ mb: 2, display: step === 1 ? "none" : "inherit" }}
                    >
                      <TextField
                        label="Email"
                        onChange={({ target }) => {
                          setFieldValue("email", target.value);
                          setEmailToSearch(target.value);
                          setAccountsError(false);
                          setErrorMessage("");
                        }}
                        value={emailToSearch}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        helperText={
                          accountsError
                            ? errorMessage
                            : ""
                        }
                        name="email"
                        variant="outlined"
                        error={accountsError}
                      />
                    </FormControl>
                    <FormControl
                      sx={{
                        display: "none",
                      }}
                    >
                      <TextField
                        label="First Name"
                        variant="outlined"
                        onChange={handleChange("fullName")}
                        value={values.fullName}
                        FormHelperTextProps={{ style: styles.errorMessages }}
                        helperText={errors.fullName ?? ""}
                        name="fullName"
                        disabled
                        sx={{ display: "none" }}
                      />
                    </FormControl>
                    {showAccounts && (
                      <Grid container mt={2}>
                        <Stack sx={{ width: "100%" }}>
                          <Label>Accounts assigned</Label>

                          {isAccountListVisible && (
                            <div style={{ position: "relative" }}>
                              <ResultsContainer ref={wrapperRef}>
                                {isSearching ? (
                                  <LoadingContainer>
                                    <CircularProgress
                                      style={{ color: "#B80A26" }}
                                    />
                                  </LoadingContainer>
                                ) : assignedAccounts.length > 0 ? (
                                  <>{renderAccountExecutiveList}</>
                                ) : (
                                  !isSearching && (
                                    <SearchResultNotFound title={lastSearch} />
                                  )
                                )}
                              </ResultsContainer>
                            </div>
                          )}

                          <Grid item xs={12}>
                            {renderAccountsTable}
                          </Grid>
                        </Stack>
                      </Grid>
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mt: 3,
                      }}
                    >
                      {client && step === 1 ? removeUserButton : backButton}
                      {step === 0 ? checkEmailButton : inviteUserButton}
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default AccountExecutiveModal;

AccountExecutiveModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
