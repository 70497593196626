import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field, Formik, Form } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import Icons from "../../icons";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloseIcon from "../../icons/CloseIcon";
import reportsAPI from "../../../api/modules/reports";
import { useNotification } from "../../../context/notificationContext";
import { columnsParser } from "./../helpers/columnsOptions";
import { optionsParser } from "./../helpers/filterOptions";
import MixpanelHelper from "../../../Helpers/MixpanelHelper";
import { getSelectedCompany } from "../../../redux/features/user/userSlice";
import { useSelector } from "react-redux";

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #b80a26;
  margin-bottom: 32px;
  display: block;
`;

const Container = styled.div`
  padding: 48px 40px;
`;

const FilterTextField = muiStyled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));

const styles = {
  errorMessages: {
    color: "#B80A26",
  },
}

const initialFormValues = {
  fileName: "",
  format: 1,
};

const ExportDialogComponent = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    config,
    id,
    selectedCompany,
    currentColumns,
    optionValues,
    type,
  } = props;
  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const { setNotification } = useNotification();
  const handleClose = () => {
    onClose(selectedValue);
  };

  const validationSchema = Yup.object({
    fileName: Yup.string()
      .required('File name is required'),
  });

  const onSubmit = async (values) => {
    try {
      const sendData = {
        fileName: values.fileName,
        group: type === "Orders" ? 1 : 2,
        accounts: selectedCompany,
        columns: columnsParser(type === "Invoice" ? 2 : 1, currentColumns), //Come back
        options: optionsParser(optionValues),
      };
      await reportsAPI.exportReport(sendData, values.format);
      setNotification({
        open: true,
        message: (
          <>
            <b>{values.fileName}:</b>
            {" Report will be emailed to you shortly!"}
          </>
        ),
      });

      const company = selectedCompanyRedux?.[0]?.name ?? 'All';
      MixpanelHelper.track("Reports", { 'Action': 'Export Report', 'Report Name': values.fileName, company: company });
      MixpanelHelper.peopleIncrement("Reports Export", 1);

      handleClose();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? "An error occured. Please try again.",
        type: "error",
      });
    }
  };

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} scroll="body">
      <DialogTitle style={{ padding: 0 }} sx={{ padding: 0 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Container>
        <Title>Export Report</Title>

        <Formik initialValues={initialFormValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ errors, values, handleChange, setFieldValue }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <Box>
                    <FilterTextField
                      label="File Name"
                      name="fileName"
                      value={values.fileName}
                      onChange={handleChange("fileName")}
                      helperText={errors.fileName ?? ""}
                      FormHelperTextProps={{ style: styles.errorMessages }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={9}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Render Format
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.format}
                        label="Render Format"
                        onChange={handleChange("format")}
                      >
                        <MenuItem value={1}>PDF</MenuItem>
                        <MenuItem value={2}>Excel</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Grid>
                <Grid item xs={3}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Button
                      variant="blueGradient-outlined"
                      type="submit"
                      sx={{ borderRadius: "0px", height: "56px" }}
                    >
                      DOWNLOAD
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Container>
    </Dialog>
  );
};
export default ExportDialogComponent;
