import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from './redux/store';
// import configureStore from './store/configureStore'
import './index.css';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import ChangePassword from './pages/ChangePassword';
import SetPassword from './pages/SetPassword';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Sign2FA from './pages/Sign2FA';
import { NotificationProvider } from './context/notificationContext';
import { AuthProvider } from './context/authContext';
import OrderInvoicesDashboard from './pages/OrderInvoicesDashboard';
import ReportsDashboard from './pages/ReportsDashboard';
import { AuthLayoutRoute } from './routes/AuthLayoutRoute';
import { PersistGate } from 'redux-persist/integration/react';
import PrivateRoute from './routes/PrivateRoute';
import PageNotFound from './pages/PageNotFound';
import TrackingDashboard from './pages/TrackingDashboard';
import ShadowAccountsDashboard from './pages/ShadowAccountsDashboard';
import ReportsHomePage from './pages/ReportsHomePage';
import MixpanelHelper from './Helpers/MixpanelHelper';
import LogsHomePage from './pages/LogDashboard';

// const history = createBrowserHistory();
// history.listen((location) => {
//   console.log(location);
//   alert("c");
// });
// initialize mixpanel tracker
MixpanelHelper.initialize();

// note: url paths are just to test the UI, in next pr the navigation will be complete
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    customRed: {
      light: '#d00b2b',
      main: '#B80A26',
      dark: '#a00921',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'contained', color: 'customRed' },
          style: {
            color: 'white',
          },
        },
        {
          props: { variant: 'outlined', color: 'customRed' },
          style: {
            color: '#B80A26',
          },
        },
        {
          props: { variant: 'blueGradient-contained' },
          style: {
            background:
              'linear-gradient(136.27deg, #5058F2 2.13%, #44C0B4 100%)',
            boxShadow:
              ' 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',
          },
        },
        {
          props: { variant: 'blueGradient-outlined' },
          style: {
            boxShadow:
              ' 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14),0 1px 5px rgba(0, 0, 0, 0.12)',
            border: 'solid 4px transparent',
            backgroundImage:
              'linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), linear-gradient(136.27deg, #5058f2 2.13%, #44c0b4 100%)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'content-box, border-box',
            boxShadow: '2px 1000px 1px #fff inset',
            color: '#1976d2',
            '&:hover': {
              boxShadow: 'none',
              color: 'white',
            },
          },
        },
      ],
    },
  },
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AuthProvider>
          <BrowserRouter>
            <ThemeProvider theme={theme}>
              <NotificationProvider>
                <Routes>
                  <Route path="/" element={<SignIn />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/dashboard" element={<AuthLayoutRoute />} />
                  <Route path="/2fa" element={<Sign2FA />} />
                  <Route path="/forgotpwd" element={<ForgotPassword />} />
                  <Route
                    path="/user/reset-password"
                    element={<ChangePassword />}
                  />
                  <Route path="/user/set-password" element={<SetPassword />} />
                  <Route path="/orders" element={<OrderInvoicesDashboard />} />
                  <Route path="/tracking" element={<TrackingDashboard />} />
                  <Route path="/reports" element={<ReportsHomePage />} />
                  <Route path="/logs" element={<LogsHomePage />} />
                  <Route
                    path="/build-report/:process"
                    element={<ReportsDashboard />}
                  />

                  <Route
                    path="/shadow-accounts"
                    element={<ShadowAccountsDashboard />}
                  />
                  {/* Example of private route */}
                  <Route
                    path="private-route"
                    element={
                      <PrivateRoute roles={['ADMIN', 'SUPER_ADMIN']}>
                        <div>Private route for admins and super admins</div>
                      </PrivateRoute>
                    }
                  />
                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </NotificationProvider>
            </ThemeProvider>
          </BrowserRouter>
        </AuthProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
