import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Field, Form, Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { styles as signInStyles } from "../../components/SignIn/styles";
import { clientPermissions } from "../../Helpers/clientRolesPermissions";

const Label = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 16px;
  display: block;
`;

const StCheckBoxLabel = styled(Box)(({ theme }) => ({
  fontfamily: "Montserrat",
  fontstyle: "normal",
  fontweight: "400",
  fontsize: "16px",
  lineheight: "24px",
  letterspacing: "0.15px",
  color: "#444444",
}));

const RemoveUser = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: unset !important;
  color: #b80a26;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Logger = ({ setInitialValues, setClientPermissions }) => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    let permissions = [];
    switch (values.userRole) {
      case "CLIENT_ADMIN":
        permissions = clientPermissions.clientAdminUser
          .filter((p) => p.defaultChecked)
          .map((permission) => permission.value.toString());

        setClientPermissions(clientPermissions.clientAdminUser);
        break;

      case "CLIENT_STANDARD_USER":
        permissions = clientPermissions.clientStandardUser
          .filter((p) => p.defaultChecked)
          .map((permission) => permission.value.toString());
        setClientPermissions(clientPermissions.clientStandardUser);
        break;

      default:
        break;
    }
    setFieldValue("permissions", permissions);
  }, [values.userRole]);

  return null;
};

export const ClientInformationForm = ({
  clientRoles,
  clientPermissions,
  children,
  initialValues,
  isEditting = false,
  handleOnBack,
  handleOnSubmit,
  handleRemoveUser,
  setClientPermissions,
  setInitialValues,
}) => {
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Valid email address required (must have @ and . to be valid)")
      .required("Email is required")
      .max(250, "Email must be at most 250 characters"),
    firstName: Yup.string()
      .required("First name is required")
      .max(50, "First name must be at most 50 characters"),
    lastName: Yup.string()
      .required("Last name is required")
      .max(50, "Last name must be at most 50 characters"),
    companyRole: Yup.string()
      .required("Company role is required")
      .max(60, "Company role must be at most 60 characters"),
  });

  const renderPermissions = (role, setFieldValue) => {
    let roleConversion = "";
    switch (role) {
      case "CLIENT_ADMIN":
        roleConversion = "clientAdminUser";

        break;

      case "CLIENT_STANDARD_USER":
        roleConversion = "clientStandardUser";
        break;

      default:
        roleConversion = "";
        break;
    }
    return clientPermissions[roleConversion].map((permission) => (
      <Field
        key={permission.value}
        as={FormControlLabel}
        control={
          <Checkbox
            style={{
              color: permission.disabled
                ? !permission.defaultChecked
                  ? "rgba(0, 0, 0, 0.26) "
                  : "#1976d2"
                : undefined,
            }}
            disabled={permission.disabled}
          />
        }
        type="checkbox"
        name="permissions"
        value={permission.value}
        label={<StCheckBoxLabel>{permission.description}</StCheckBoxLabel>}
      />
    ));
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {({ errors, isSubmitting, values, handleChange, setFieldValue }) => (
        <>
          <Label>New Client Information</Label>
          <Form>
            <Logger
              setInitialValues={setInitialValues}
              setClientPermissions={setClientPermissions}
            />
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="First Name"
                  variant="outlined"
                  onChange={handleChange("firstName")}
                  value={values.firstName}
                  helperText={errors.firstName ?? ""}
                  FormHelperTextProps={{ style: signInStyles.errorMessages }}
                  fullWidth
                  name="firstName"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Last Name"
                  variant="outlined"
                  onChange={handleChange("lastName")}
                  value={values.lastName}
                  helperText={errors.lastName ?? ""}
                  FormHelperTextProps={{ style: signInStyles.errorMessages }}
                  fullWidth
                  name="lastName"
                  inputProps={{ maxLength: 50 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Email"
                  onChange={handleChange("email")}
                  value={values.email}
                  helperText={errors.email ?? ""}
                  FormHelperTextProps={{ style: signInStyles.errorMessages }}
                  fullWidth
                  name="email"
                  variant="outlined"
                  inputProps={{ maxLength: 250 }}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Company Role"
                  variant="outlined"
                  onChange={handleChange("companyRole")}
                  value={values.companyRole}
                  helperText={errors.companyRole ?? ""}
                  FormHelperTextProps={{ style: signInStyles.errorMessages }}
                  fullWidth
                  name="companyRole"
                  inputProps={{ maxLength: 60 }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} mt={2}>
              <Grid item xs={12} md={6}>
                <Label>User Role</Label>

                <RadioGroup
                  defaultValue={clientRoles.defaultRole}
                  name="radio-buttons-group"
                >
                  {clientRoles.roles.map((role) => (
                    <Field
                      key={role.value}
                      as={FormControlLabel}
                      control={<Radio disabled={role.disabled} />}
                      type="radio"
                      name="userRole"
                      value={role.value}
                      label={
                        <StCheckBoxLabel>{role.description}</StCheckBoxLabel>
                      }
                      disabled={isEditting}
                    />
                  ))}
                </RadioGroup>
              </Grid>

              <Grid item xs={12} md={6}>
                <Label>User Permissions</Label>

                <div role="group" aria-labelledby="checkbox-group">
                  <FormGroup>
                    {renderPermissions(values.userRole, setFieldValue)}
                  </FormGroup>
                </div>
              </Grid>
            </Grid>

            {children && (
              <Grid container mt={2}>
                {children}
              </Grid>
            )}

            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}
            >
              <Button
                variant="text"
                color="customRed"
                onClick={handleOnBack}
                sx={signInStyles.textUnset}
              >
                {"< Back"}
              </Button>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                {isEditting && handleRemoveUser && (
                  <Box
                    alignSelf="center"
                    onClick={handleRemoveUser}
                    sx={{ mr: 2 }}
                  >
                    <RemoveUser>Remove User</RemoveUser>
                  </Box>
                )}

                <Button
                  variant="blueGradient-outlined"
                  sx={signInStyles.submitButton}
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isEditting ? "Save" : "Invite →"}
                </Button>
              </Box>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};

ClientInformationForm.propTypes = {
  clientRoles: PropTypes.object.isRequired,
  clientPermissions: PropTypes.array.isRequired,
  children: PropTypes.any,
  initialValues: PropTypes.object.isRequired,
  handleOnBack: PropTypes.func.isRequired,
  handleOnSubmit: PropTypes.func.isRequired,
};
