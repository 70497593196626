import React from 'react'

const MicroAgeLogo = () => {
  return (
    <svg width="188" height="86" viewBox="0 0 188 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M99.1073 18.0703L92.6768 25.304L102.512 35.7527H115.373L99.1073 18.0703Z" fill="white" />
      <path d="M99.1074 14.0655L92.6769 21.2992L85.868 14.0655L65.4415 35.7665H52.2021L85.868 0L92.2986 7.23368L99.1074 0L132.773 35.7665H119.534L99.1074 14.0655Z" fill="white" />
      <path d="M85.869 18.0703L92.6779 25.304L82.4646 35.7527H69.6035L85.869 18.0703Z" fill="white" />
      <path d="M52.579 47.4277H47.2832V52.2502H52.579V47.4277Z" fill="white" />
      <path d="M52.579 55.4629H47.2832V79.5752H52.579V55.4629Z" fill="white" />
      <path d="M77.923 71.1305C76.41 76.3549 71.8707 79.9717 66.1967 79.9717C59.3879 79.9717 53.7139 74.3455 53.7139 67.1118C53.7139 59.8782 59.3879 54.252 66.1967 54.252C71.8707 54.252 76.41 57.8688 77.923 63.0931H71.8707C70.7359 61.0838 68.4663 59.8781 66.1967 59.8781C62.414 59.8781 59.3879 63.0931 59.3879 67.1118C59.3879 71.1305 62.414 74.3455 66.1967 74.3455C68.8446 74.3455 70.7359 73.1399 71.8707 71.1305H77.923Z" fill="white" />
      <path d="M84.7323 65.5087V79.5742H79.4365V55.4619H84.7323V57.4713C85.1105 55.4619 89.6498 54.6582 89.6498 54.6582V60.2844C89.6498 60.2844 84.7323 60.6863 84.7323 65.5087Z" fill="white" />
      <path d="M108.564 67.1169C108.564 71.1356 105.537 74.3506 101.755 74.3506C97.972 74.3506 94.9458 71.1356 94.9458 67.1169C94.9458 63.0982 97.972 59.8833 101.755 59.8833C105.537 59.8833 108.564 63.0982 108.564 67.1169ZM113.859 67.1169C113.859 59.8833 108.185 54.2571 101.376 54.6589C94.5676 54.6589 88.8936 60.2851 88.8936 67.5188C88.8936 74.7525 94.5676 80.3787 101.376 80.3787C108.564 79.9768 113.859 74.3506 113.859 67.1169Z" fill="white" />
      <path d="M21.183 47.4277L0 79.5774H6.80883L20.0482 59.082V79.5774H25.7222L39.3399 59.082V79.5774H45.0139V47.4277H40.0964L25.7222 69.1288V47.4277H21.183Z" fill="white" />
      <path d="M130.881 70.3344H123.315L117.263 79.5774H110.454L131.259 47.4277H136.176V79.5774H130.502V70.3344H130.881ZM130.881 59.082L127.098 65.1101H130.881V59.082Z" fill="white" />
      <path d="M149.416 55.0547C152.442 55.0547 154.711 56.2603 156.603 58.6715V56.2603H161.142V74.3445C161.142 74.3445 161.142 85.195 149.416 85.9988C149.416 85.9988 142.985 85.9988 139.581 79.167H144.877C144.877 79.167 145.255 81.5782 149.794 81.5782C151.307 81.5782 155.468 80.3726 156.225 76.3539C154.333 78.3632 152.064 79.167 149.416 79.167C143.742 79.167 138.824 73.5408 138.824 66.709C138.824 60.6809 143.742 55.0547 149.416 55.0547ZM150.172 73.9426C153.577 73.9426 156.225 71.1295 156.225 67.1108C156.225 63.494 153.577 60.279 150.172 60.279C146.768 60.279 144.12 63.0921 144.12 67.1108C143.742 71.1295 146.768 73.9426 150.172 73.9426Z" fill="white" />
      <path d="M168.708 70.3321C169.843 73.1452 172.491 75.5564 175.517 75.5564C177.787 75.5564 179.678 74.7527 180.813 73.1452H186.865C184.974 77.9677 180.435 81.1826 175.517 81.1826C168.708 81.1826 163.034 75.5564 163.034 68.3228C163.034 61.0891 168.708 55.4629 175.517 55.4629C182.326 55.4629 188 61.0891 188 68.3228C188 69.1265 188 69.9303 187.622 70.734H168.708V70.3321ZM182.326 65.9115C181.569 62.6966 178.543 60.2853 175.517 60.2853C172.113 60.2853 169.465 62.6966 168.708 65.9115H182.326Z" fill="white" />
    </svg>

  );
}

export default MicroAgeLogo;
