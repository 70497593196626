import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import authAPI from '../api/modules/authentication';
import { styles } from "./SignIn/styles";
import { useNotification } from "../context/notificationContext";

function ForgotPasswordComponent() {
  const [emailSent, setEmailSent] = useState(false);
  const { setNotification } = useNotification();

  const onSubmit = async ({ email }, { setSubmitting, resetForm }) => {
    try {
      await authAPI.forgetPassword({ email });
      resetForm();
      setEmailSent(true);

    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });

    } finally {
      setSubmitting(false);
    }
  }

  const validationSchema = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
  });

  return (
    <Box sx={styles.formContainer}>
      <Stack spacing={2}>
        <div style={styles.signInTypo}>{"Forgot Your Password?"}</div>

        {
          emailSent ? (
            <span>We have sent you a link to reset your password.</span>
          ) : (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchema}
              enableReinitialize
              onSubmit={onSubmit}
            >
              {({ errors, isSubmitting, isValid, values, handleChange }) => (
                <>
                  <Form style={styles.form}>
                    <FormControl sx={{ mb: 2 }} variant="outlined">
                      <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        id="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange('email')}
                        helperText={errors.email ?? ''}
                      />
                    </FormControl>

                    <Box sx={{ display: "flex", justifyContent: "end" }}>
                      <Button
                        type="submit"
                        variant="blueGradient-outlined"
                        sx={styles.submitButton}
                        disabled={isSubmitting || !isValid || !values.email}
                      >
                        Send
                      </Button>
                    </Box>
                  </Form>
                </>
              )}
            </Formik>
          )
        }
      </Stack>
    </Box>
  );
}

export default ForgotPasswordComponent;
