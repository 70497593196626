import { Box, Button, Grid } from '@mui/material';
import styled from 'styled-components';

export const BodyGridWrapper = styled(Grid)`
  justify-content: center;
  display: flex;
  width: 100% !important;
`;

export const BodyContainer = styled(Box)`
  max-width: 1900px;
  width: 100% !important;

  margin: 24px 90px;

  @media (min-width: 1940px) {
    margin: 47px 90px;
  }

  @media (max-width: 1025px) {
    margin: 31px 46px;
  }
`;

export const BlueAquaSearchButtonOutline = styled(Button)`
  padding: 1rem !important;
  height: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  min-width: 50px !important;
  border-radius: 0px !important;
  margin-right: 1px !important;
  border-width: 3px !important;
  svg {
    width: 32px !important;
    height: 32px !important;
  }
`;
