import React from 'react';

const ErrorIcon = ({
  width = 44,
  height = 44,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.834 28.5007H24.1673V32.834H19.834V28.5007ZM19.834 11.1673H24.1673V24.1673H19.834V11.1673ZM21.979 0.333984C10.019 0.333984 0.333984 10.0407 0.333984 22.0007C0.333984 33.9607 10.019 43.6673 21.979 43.6673C33.9607 43.6673 43.6673 33.9607 43.6673 22.0007C43.6673 10.0407 33.9607 0.333984 21.979 0.333984ZM22.0007 39.334C12.424 39.334 4.66732 31.5773 4.66732 22.0007C4.66732 12.424 12.424 4.66732 22.0007 4.66732C31.5773 4.66732 39.334 12.424 39.334 22.0007C39.334 31.5773 31.5773 39.334 22.0007 39.334Z" fill="#F44336" />
    </svg>
  );
}

export default ErrorIcon;
