export const PHRASE_TO_FILTER = [
  'referral fees',
]

export const dataRemoveRowsWithPhraseFiltered = (keyName, data) => {
  const filteredData = data.filter((detail) => {
    const valueToFilter = detail[keyName]

    const hasKeyWordsToFilter = PHRASE_TO_FILTER.some((phrase) => {
      const hasPhraseToFilter = valueToFilter.toLowerCase().includes(phrase)
      return hasPhraseToFilter
    })

    return !hasKeyWordsToFilter
  })

  return filteredData
}
