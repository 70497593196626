export const globalStyles = {
  body: {
    gridArea: 'main',
    bgcolor: 'white',
    minHeight: '100vh',
    minWidth: 'auto',
  },
  footer: {
    gridArea: 'footer',
    bgcolor: 'black',
    minHeight: '150px',
  },
};
