import { PluginService } from "../pluginService";
import { createFilterUrl } from "../helper/manageFilter";

const reportsService = PluginService("");

export default {
  getSavedReports(params) {
    return reportsService({
      method: "GET",
      url: `/saved-reports`,
      params,
    });
  },

  getPresetInfo(id) {
    return reportsService({
      method: "GET",
      url: `/preset/${id}`,
    });
  },

  getDefaultPresets(params) {
    return reportsService({
      method: "GET",
      url: `/default-presets`,
      params,
    });
  },

  savePreset(data) {
    return reportsService({
      method: "POST",
      url: `/preset`,
      data,
    });
  },
  editPreset(id,data) {
    return reportsService({
      method: "PUT",
      url: `/edit-preset/${id}`,
      data,
    });
  },
  deletePreset(id) {
    return reportsService({
      method: "DELETE",
      url: `/preset/${id}`,
    });
  },
  buildReportFromId(params) {
    return reportsService({
      method: "POST",
      url: `/build-report`,
      params,
    });
  },
  buildReport(params, data) {
    return reportsService({
      method: "POST",
      url: `/build-report`,
      params,
      data,
    });
  },
  exportReportFromId(params, type) {
    return reportsService({
      method: "POST",
      url: `/export-report/${type}`,
      params,
    });
  },
  exportReport(data, type) {
    return reportsService({
      method: "POST",
      url: `/export-report/${type}`,
      data,
    });
  },
  updateReportStatus(data, id) {
    return reportsService({
      method: "PUT",
      url: `/update-report-status/${id}`,
      data,
    });
  },
};
