import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Field, Formik, Form, useFormikContext } from "formik";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import Icons from "../../icons";
import CloseIcon from "../../icons/CloseIcon";

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #b80a26;
  margin-bottom: 32px;
  display: block;
`;

const Container = styled.div`
  padding: 48px 40px;
`;

const ResetText = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #b80a26;
  margin-right: 24px;
  cursor: pointer;
`;

const TitleOption = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 16px;
  display: block;
`;

const ClearButton = styled(Box)`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #b80a26;
  margin-top: 5px;
  opacity: 0.4;
`;

const DateOverlay = styled.div`
  position: absolute;
  top: -6px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.6);
  display: ${({ hidden }) => (hidden ? "none" : "inital")};
`;

const StCheckBoxLabel = muiStyled(Box)(({ theme }) => ({
  fontfamily: "Montserrat",
  fontstyle: "normal",
  fontweight: "400",
  fontsize: "16px",
  lineheight: "24px",
  letterspacing: "0.15px",
  color: "#444444",
}));

const MoneyIcon = muiStyled(AttachMoneyIcon)(({ theme }) => ({
  width: 16,
  color: "#444444",
}));

const FilterTextField = muiStyled(TextField)(({ theme }) => ({
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));

const FilterDialogComponent = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    optionValues,
    setOptionValues,
    type,
    initOptionValues,
  } = props;

  const [filters, setFilters] = useState(initOptionValues);

  const isInvoice = type === "Invoice" ? false : true;
  const [isSaved, setIsSaved] = useState(false);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onSubmit = (values) => {
    setOptionValues(values);
    setIsSaved(true);
    handleClose();
  };
  useEffect(() => {
    setFilters(initOptionValues);
  }, [initOptionValues]);

  const AutoRefresh = ({ initOptionValues }) => {
    const { resetForm } = useFormikContext();

    React.useEffect(() => {
      resetForm();
    }, [initOptionValues]);
    return null;
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={"sm"}
      scroll="body"
    >
      <DialogTitle style={{ padding: 0 }} sx={{ padding: 0 }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <Container>
        <Title>Report Options: {type} </Title>
        <Formik
          initialValues={isSaved ? optionValues : filters}
          onSubmit={onSubmit}
        >
          {({ values, handleChange, setFieldValue, resetForm }) => (
            <>
              <AutoRefresh initOptionValues={filters} />
              <Form>
                <Grid container>
                  <Grid item xs={6} md={6}>
                    <Box display="flex" flexDirection="column">
                      <Grid item>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <TitleOption sx={{ marginTop: "13px" }}>
                            Status
                          </TitleOption>
                          <Field
                            as={FormControlLabel}
                            control={<Radio />}
                            type="radio"
                            name="status"
                            value="Open"
                            label={<StCheckBoxLabel>Open</StCheckBoxLabel>}
                          />
                          <Field
                            as={FormControlLabel}
                            control={<Radio />}
                            type="radio"
                            name="status"
                            value="Closed"
                            label={<StCheckBoxLabel>{!isInvoice? "Closed": "Released"}</StCheckBoxLabel>}
                          />
                          {/* {isInvoice? (
                            <>
                            <Field
                              as={FormControlLabel}
                              control={<Radio />}
                              type="radio"
                              name="status"
                              value="PendingApproval"
                              label={<StCheckBoxLabel>Pending Approval</StCheckBoxLabel>}
                            />
                            <Field
                              as={FormControlLabel}
                              control={<Radio />}
                              type="radio"
                              name="status"
                              value="PendingPrepayment"
                              label={<StCheckBoxLabel>Pending Prepayment</StCheckBoxLabel>}
                            /> 
                            </> ) : null
                          } */}
                          <Field
                            as={FormControlLabel}
                            control={<Radio />}
                            type="radio"
                            name="status"
                            value="All"
                            label={<StCheckBoxLabel>All</StCheckBoxLabel>}
                          />
                        </Box>
                      </Grid>
                      <Grid item>
                        <Stack>
                          {type === "Invoice" ? (
                            <Box sx={{ marginTop: "21px" }}>
                              <TitleOption>Passed Due</TitleOption>
                              <RadioGroup name="order-date-radio-group">
                                <Box display="flex" flexDirection="column">
                                  <Field
                                    as={FormControlLabel}
                                    control={<Radio />}
                                    type="radio"
                                    name="passedDue.option"
                                    value="30 days"
                                    label={
                                      <StCheckBoxLabel>30 Days</StCheckBoxLabel>
                                    }
                                    disabled={isInvoice}
                                  />
                                  <Field
                                    as={FormControlLabel}
                                    control={<Radio />}
                                    type="radio"
                                    name="passedDue.option"
                                    value="60 days"
                                    label={
                                      <StCheckBoxLabel>60 Days</StCheckBoxLabel>
                                    }
                                    disabled={isInvoice}
                                  />
                                  <Field
                                    as={FormControlLabel}
                                    control={<Radio />}
                                    type="radio"
                                    name="passedDue.option"
                                    value="90 days"
                                    label={
                                      <StCheckBoxLabel>90 Days</StCheckBoxLabel>
                                    }
                                    disabled={isInvoice}
                                  />
                                  <Field
                                    as={FormControlLabel}
                                    control={<Radio />}
                                    type="radio"
                                    name="passedDue.option"
                                    value="all"
                                    label={
                                      <StCheckBoxLabel>All</StCheckBoxLabel>
                                    }
                                    disabled={isInvoice}
                                  />
                                </Box>
                              </RadioGroup>
                            </Box>
                          ) : (
                            <></>
                          )}
                        </Stack>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <Stack>
                      <Box>
                        {type === "Invoice" ? (
                          <>
                            <TitleOption>Invoice Date</TitleOption>
                            <RadioGroup name="invoice-date-radio-group">
                              <Box display="flex" flexDirection="column">
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="invoiceDate.option"
                                  value="this week"
                                  label={
                                    <StCheckBoxLabel>This Week</StCheckBoxLabel>
                                  }
                                  disabled={isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="invoiceDate.option"
                                  value="this month"
                                  label={
                                    <StCheckBoxLabel>This Month</StCheckBoxLabel>
                                  }
                                  disabled={isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="invoiceDate.option"
                                  value="this year"
                                  label={
                                    <StCheckBoxLabel>This Year</StCheckBoxLabel>
                                  }
                                  disabled={isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="invoiceDate.option"
                                  value="dateRange"
                                  label={
                                    <StCheckBoxLabel>Between Dates</StCheckBoxLabel>
                                  }
                                  disabled={isInvoice}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                position="relative"
                              >
                                <DateOverlay
                                  hidden={
                                    values.invoiceDate.option === "dateRange"
                                  }
                                />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    onChange={(value) =>
                                      setFieldValue(
                                        "invoiceDate.dateFrom",
                                        dayjs(value).format("YYYY-MM-DD"),
                                        true
                                      )
                                    }
                                    value={values.invoiceDate.dateFrom}
                                    label="From"
                                    renderInput={(params) => (
                                      <FilterTextField
                                        name="invoiceDate.dateFrom"
                                        fullWidth
                                        {...params}
                                      />
                                    )}
                                    components={{
                                      OpenPickerIcon: Icons.DatePickerIcon,
                                    }}
                                  />
                                </LocalizationProvider>
                                <Box width={16} />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    onChange={(value) =>
                                      setFieldValue(
                                        "invoiceDate.dateTo",
                                        dayjs(value).format("YYYY-MM-DD"),
                                        true
                                      )
                                    }
                                    value={values.invoiceDate.dateTo}
                                    label="To"
                                    renderInput={(params) => (
                                      <FilterTextField
                                        name="invoiceDate.dateTo"
                                        sx={{ marginTop: "15px" }}
                                        fullWidth
                                        {...params}
                                      />
                                    )}
                                    components={{
                                      OpenPickerIcon: Icons.DatePickerIcon,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <ClearButton
                                style={{ opacity: !isInvoice ? 1 : 0.4, cursor: 'pointer' }}
                                onClick={() => {
                                  setFieldValue(
                                    "invoiceDate.dateFrom",
                                    null,
                                    true
                                  );
                                  setFieldValue(
                                    "invoiceDate.dateTo",
                                    null,
                                    true
                                  );
                                }}
                              >
                                Clear
                              </ClearButton>
                            </RadioGroup>
                          </>
                        ) : (
                          <>
                            {" "}
                            <TitleOption>Order Date</TitleOption>
                            <RadioGroup name="order-date-radio-group">
                              <Box display="flex" flexDirection="column">
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="orderDate.option"
                                  value="this week"
                                  label={
                                    <StCheckBoxLabel>This Week</StCheckBoxLabel>
                                  }
                                  disabled={!isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="orderDate.option"
                                  value="this month"
                                  label={
                                    <StCheckBoxLabel>This Month</StCheckBoxLabel>
                                  }
                                  disabled={!isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="orderDate.option"
                                  value="this year"
                                  label={
                                    <StCheckBoxLabel>This Year</StCheckBoxLabel>
                                  }
                                  disabled={!isInvoice}
                                />
                                <Field
                                  as={FormControlLabel}
                                  control={<Radio />}
                                  type="radio"
                                  name="orderDate.option"
                                  value="dateRange"
                                  label={
                                    <StCheckBoxLabel>Between Dates</StCheckBoxLabel>
                                  }
                                  disabled={!isInvoice}
                                />
                              </Box>
                              <Box
                                display="flex"
                                flexDirection="column"
                                position="relative"
                              >
                                <DateOverlay
                                  hidden={
                                    values.orderDate.option === "dateRange"
                                  }
                                />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    onChange={(value) =>
                                      setFieldValue(
                                        "orderDate.dateFrom",
                                        dayjs(value).format("YYYY-MM-DD"),
                                        true
                                      )
                                    }
                                    value={values.orderDate.dateFrom}
                                    label="From"
                                    renderInput={(params) => (
                                      <FilterTextField
                                        name="orderDate.dateFrom"
                                        fullWidth
                                        {...params}
                                      />
                                    )}
                                    components={{
                                      OpenPickerIcon: Icons.DatePickerIcon,
                                    }}
                                  />
                                </LocalizationProvider>
                                <Box width={16} />
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    onChange={(value) =>
                                      setFieldValue(
                                        "orderDate.dateTo",
                                        dayjs(value).format("YYYY-MM-DD"),
                                        true
                                      )
                                    }
                                    value={values.orderDate.dateTo}
                                    label="To"
                                    renderInput={(params) => (
                                      <FilterTextField
                                        name="orderDate.dateTo"
                                        sx={{ marginTop: "15px" }}
                                        fullWidth
                                        {...params}
                                      />
                                    )}
                                    components={{
                                      OpenPickerIcon: Icons.DatePickerIcon,
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                              <ClearButton
                                style={{ opacity: isInvoice ? 1 : 0.4, cursor: 'pointer' }}
                                onClick={() => {
                                  setFieldValue(
                                    "orderDate.dateFrom",
                                    null,
                                    true
                                  );
                                  setFieldValue("orderDate.dateTo", null, true);
                                }}
                              >
                                Clear
                              </ClearButton>
                            </RadioGroup>
                          </>
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      alignItems="center"
                      mt={10}
                    >
                      <ResetText
                        onClick={() => {
                          resetForm();
                        }}
                      >
                        Reset
                      </ResetText>
                      <Button variant="blueGradient-outlined" type="submit">
                        APPLY
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </Container>
    </Dialog>
  );
};
export default FilterDialogComponent;
