import React from "react";
import styled from "styled-components";
import { DialogTitle, Dialog, Paper, Box, IconButton } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import { invoiceColumns, orderColumns } from "../helpers/columnsOptions";
import CloseIcon from "../../icons/CloseIcon";

const PresetItems = styled(Box)(({ theme }) => ({
  fontFamily: "Montserrat",

  textAlign: "center",
  display: "flex",

  alignItems: "center",
  gap: "18px",
  color: "#B80A26",
}));
const ItemDisabled = styled(Paper)(({ theme }) => ({
  fontFamily: "Montserrat",
  backgroundColor: "#F5F5F5 !important",
  padding: "2px",
  textAlign: "center",
  display: "flex",
  paddingLeft: "15px",
  paddingRight: "15px",
  cursor: "grab",
  borderRadius: "4px",
  marginTop: "3px",
  marginBottom: "3px",
  height: "32px",
  alignItems: "center",
  gap: "18px",
  color: "#444444 !important",
  opacity: "0.4",
}));

const BlueAquaButtonOutline = styled(Button)({
  padding: "1rem",
  height: "50px",
  width: "25%",
  borderRadius: 0,
  marginRight: "10px",
});

const ItemEnabled = styled(Paper)(({ theme }) => ({
  fontFamily: "Montserrat",
  backgroundColor: "#fff !important",
  padding: "2px",
  textAlign: "center",
  color: "#444444 !important",
  display: "flex",
  paddingLeft: "15px",
  paddingRight: "15px",
  cursor: "grab",
  borderRadius: "4px",
  marginTop: "3px",
  marginBottom: "3px",
  height: "32px",
  alignItems: "center",
  gap: "18px",
}));

const styles = {
  dialogHeader: {
    fontFamily: "OpenSans",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "123.5%",
    color: "#B80A26",
    display: "flex",
    justifyContent: "space-between",
  },
  dialogSubtitle: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    marginBottom: "25px",
  },
  dotted: {
    border: "1px solid #E0E0E0",
  },
  removeUser: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "1px",
    textTransform: "unset !important",
    color: "#B80A26",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  submitButton: {
    borderRadius: "1px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: "1px",
    textTransform: "unset !important",
  },
  typeButton: {
    borderRadius: "1px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: "1px",
    textTransform: "unset !important",
  },
};

// fake data generator
const getItems = (count, offset = 0) =>
  Array.from({ length: count }, (v, k) => k).map((k) => ({
    id: `item-${k + offset}`,
    headerName: `item ${k + offset}`,
  }));

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "grey",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgba(68, 192, 180, 0.2)" : "#fff",
  padding: grid,
  width: 250,
});

function ColumnChooserDialogComponent(props) {
  const {
    onClose,
    selectedValue,
    open,
    currentColumns,
    setCurrentColumns,
    type,
    setType,
    disabledColumns,
    setDisabledColumns,
    optionValues,
    selectedCompany,
    setReportConfiguration,
    processType,
  } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleClick = (item) => { 

    const enabledColumn = currentColumns.find(column => column.id === item.id);
    
    if (enabledColumn) {
      const filterCurrentColumns = currentColumns.filter(column => column.id !== item.id);
      setCurrentColumns(filterCurrentColumns);
      setDisabledColumns([...disabledColumns, item]);
      isNewProcess && setReportConfiguration(type, filterCurrentColumns, optionValues, selectedCompany);
    } else {
      const filterDisabledColumns = disabledColumns.filter(column => column.id !== item.id);
      setDisabledColumns(filterDisabledColumns);
      setCurrentColumns([...currentColumns, item]);
      isNewProcess && setReportConfiguration(type, currentColumns, optionValues, selectedCompany);
    }

  };
  
  const isNewProcess = processType === 'new'? true : false;
  const getList = (id) => (id === "droppable" ? disabledColumns : currentColumns);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const reorderedItems = reorder(
        getList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId === "droppable2") {
        // setSelected(reorderedItems);
        setCurrentColumns(reorderedItems);
        isNewProcess && setReportConfiguration(type, reorderedItems, optionValues, selectedCompany);
      }
      if (source.droppableId === "droppable") {
        setDisabledColumns(reorderedItems);
      }
    } else {
      const result = move(
        getList(source.droppableId),
        getList(destination.droppableId),
        source,
        destination
      );
      setDisabledColumns(result.droppable);
      setCurrentColumns(result.droppable2);
      isNewProcess && setReportConfiguration(type, result.droppable2, optionValues, selectedCompany);
      // setSelected(result.droppable2);
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
      scroll="body"
    >
      <DialogTitle sx={styles.dialogHeader}>
        Build Report - {type}
        {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ marginBottom: "10px" }}>
          <Box sx={{ marginBottom: "10px", fontWeight: 600, fontSize: "18px" }}>
            Report Type
          </Box>

          <BlueAquaButtonOutline
            variant={type !== "Invoice" ? "outlined" : "contained"}
            onClick={() => {
              setType("Invoice");
              setCurrentColumns(invoiceColumns);
              setDisabledColumns([]);
            }}
            sx={styles.typeButton}
          >
            Invoice
          </BlueAquaButtonOutline>
          <BlueAquaButtonOutline
            variant={type !== "Orders" ? "outlined" : "contained"}
            onClick={() => {
              setType("Orders");
              setCurrentColumns(orderColumns);
              setDisabledColumns([]);
            }}
            sx={styles.typeButton}
          >
            Orders
          </BlueAquaButtonOutline>
        </Box>

        <Grid container spacing={2} sx={{ fontFamily: "Montserrat" }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <Grid item xs={6}>
                  <Box sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {" "}
                    Disabled
                  </Box>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {disabledColumns.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ItemDisabled
                            onClick={()=>{handleClick(item)}}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                          >
                            <AddIcon />
                            {item.headerName}
                          </ItemDisabled>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </Grid>
              )}
            </Droppable>
            <Droppable droppableId="droppable2">
              {(provided, snapshot) => (
                <Grid item xs={4}>
                  <Box sx={{ fontWeight: 600, fontSize: "16px" }}>
                    {" "}
                    Enabled{" "}
                  </Box>
                  <div
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {currentColumns.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <ItemEnabled
                            onClick={()=>{handleClick(item)}}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            // style={getItemStyle(
                            //   snapshot.isDragging,
                            //   provided.draggableProps.style
                            // )}
                          >
                            <DragHandleIcon />
                            {item.headerName}
                          </ItemEnabled>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <PresetItems sx={{ fontWeight: 400, fontSize: "14px" }}>
          {/* <SaveIcon />
          Save Current As Preset */}
        </PresetItems>
        <Box sx={{ display: "flex-end" }}>
          <Button
            variant="blueGradient-outlined"
            sx={styles.submitButton}
            type="submit"
            onClick={() => handleClose()}
          >
            APPLY
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
export default ColumnChooserDialogComponent;
