import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import Box from '@mui/material/Box';
import styled from 'styled-components';

const Container = styled.div`
  justify-content: center;
  display: flex;

  .MuiPickerStaticWrapper-content {
    border-radius: 5px;
  }

  .MuiPickersDay-root.Mui-selected.MuiDateRangePickerDay-day {
    background: linear-gradient(136.27deg, #5058F2 2.13%, #44C0B4 100%);
  }

  .MuiDateRangePickerDay-root.MuiDateRangePickerDay-rangeIntervalDayHighlight {
    background-color: #44C0B4;
  }

  .MuiPickersDay-root.MuiDateRangePickerDay-day.MuiDateRangePickerDay-dayInsideRangeInterval{
    color: #FFFFFF !important;
  }

  .MuiPickersDay-root{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #444444;
  }

  .css-l9bevp-MuiPaper-root-MuiDialog-paper{
    width: auto;
  }

  .MuiPaper-root {
    background: red;
  }

  .PrivatePickersSlideTransition-root{
    min-width: 380px;
  }

  .MuiTypography-root.MuiTypography-caption{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #737373;
  }

  .MuiTypography-root.MuiTypography-subtitle1{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #444444;
  }
`;

const DateRangePicker = styled(StaticDateRangePicker)`
  display: flex;
  justify-content: space-evenly;
  background: #FBFBFB;
  border: 1px solid #CCCCCC;
  border-radius: 5px;
`;

const RangeDatePicker = ({ initialDate, onChooseDate }) => {
  const [date, setDate] = useState([null, null]);

  const onChange = (date) => {
    setDate(date);
    onChooseDate(date);
  }

  useEffect(() => {
    setDate(initialDate ?? [null, null]);
  }, [initialDate]);

  return (
    <Container>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          disable
          displayStaticWrapperAs="desktop"
          value={date}
          onChange={onChange}
          renderInput={(startProps, endProps) => (
            <>
              <TextField {...startProps} />
              <Box sx={{ mx: 2 }}> to </Box>
              <TextField {...endProps} />
            </>
          )}
        />
      </LocalizationProvider>
    </Container>
  );
}

export default RangeDatePicker;

RangeDatePicker.propTypes = {
  onChooseDate: PropTypes.func.isRequired,
}