import React from 'react';

const RightChevronIcon = ({
  color = '#FFF',
  height = 12,
  width = 8,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.99997 0L0.589966 1.41L5.16997 6L0.589966 10.59L1.99997 12L7.99997 6L1.99997 0Z" fill={color} />
    </svg>
  );
}

export default RightChevronIcon;
