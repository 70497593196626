export const styles = {
  errorMessages: {
    color: '#B80A26'
  },
    formContainer: {
      width: "83%",
      marginLeft: "7%",
      marginBottom: "10%",
    },
    stackContainer: {
      width: "100%",
      marginBottom: "10px",
      marginTop: "38px",

    },
    DialogHeader: {
      fontFamily: "OpenSans",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: "123.5%",
      color: "#B80A26",
      marginBottom: "24px",
      marginLeft: "7%",
      paddingLeft: 0,
    },
    submitButton: {
      borderRadius: "1px",
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "15px",
      letterSpacing: "1px",
      textTransform: "unset !important",
    },
    backBox: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "1px",
      textTransform: "unset !important",
      color: "#B80A26",
      display: "flex",
      alignItems: "center",
    },
    userPermissons: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      textTransform: "unset !important",
      display: "flex",
      alignItems: "center",
    },
    stepTitle: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "16px",
      textTransform: "unset !important",
      display: "flex",
      alignItems: "center",
  },
  columnForm: {
    flexDirection: 'column',
    display: 'flex',
  },
  };