export const orderColumns = [
  { id: "customer", headerName: "Customer" },
  { id: "soldTo", headerName: "Sold To" },
  { id: "orderDate", headerName: "Order Date" },
  { id: "orderNo", headerName: "Order Number" },
  { id: "status", headerName: "Status" },
  { id: "terms", headerName: "Terms" },
  { id: "salesAssociate", headerName: "Sales Associate" },
  { id: "poNumber", headerName: "PO Number" },
  { id: "orderedBy", headerName: "Ordered By" },
];

export const invoiceColumns = [
  { id: "customer", headerName: "Customer" },
  { id: "status", headerName: "Status" },
  { id: "orderNo", headerName: "Order Number" },
  { id: "orderDate", headerName: "Order Date" },
  { id: "invoiceDate", headerName: "Invoice Date" },
  { id: "invoiceNo", headerName: "Invoice Number" },
  { id: "dueDate", headerName: "Due Date" },
  { id: "originalAmount", headerName: "Original Amount" },
  { id: "balanceDue", headerName: "Balance Due" },
  { id: "daysPassedDue", headerName: "Days Past Due" },
  { id: "postedDate", headerName: "Posted Date" },
  { id: "terms", headerName: "Terms" },
  { id: "salesAssociate", headerName: "Sales Associate" },
  { id: "poNumber", headerName: "PO Number" },
];

export const columnsDefinition = {
  customer: {
    field: "customer",
    headerName: "Customer",
    flex: 1,
    minWidth: 350,
    maxWidth: 420,
  },
  shipTo: {
    field: "shipTo",
    headerName: "Ship To",
    flex: 1,
    minWidth: 150,
  },
  terms: {
    field: "terms",
    headerName: "Terms",
    flex: 1,
    minWidth: 150,
  },
  description: {
    field: "description",
    headerName: "Description",
    flex: 1,
    minWidth: 150,
  },
  aE: {
    field: "aE",
    headerName: "Account Excecutive",
    flex: 1,
    minWidth: 150,
  },
  totalCost: {
    field: "totalCost",
    headerName: "Total Cost",
    flex: 1,
    minWidth: 150,
  },
  companyName: {
    field: "companyName",
    headerName: "Company",
    flex: 1,
    minWidth: 150,
  },
  orderNo: {
    field: "orderNo",
    headerName: "Order Number",
    flex: 1,
    minWidth: 150,
  },
  invoiceNo: {
    field: "invoiceNo",
    headerName: "Invoice Number",
    flex: 1,
    minWidth: 150,
  },
  orderDate: {
    field: "orderDate",
    headerName: "Order Date",
    flex: 1,
    minWidth: 150,
  },
  poDate: {
    field: "poDate",
    headerName: "PO Date",
    flex: 1,
    minWidth: 150,
  },
  shipDate: {
    field: "shipDate",
    headerName: "Ship Date",
    flex: 1,
    minWidth: 150,
  },
  soldTo: {
    field: "soldTo",
    headerName: "Sold To",
    flex: 1,
    minWidth: 150,
  },
  salesAssociate: {
    field: "salesAssociate",
    headerName: "Sales Associate",
    minWidth: 150,
    flex: 1,
    align: "center",
  },
  salesContact: {
    field: "salesContact",
    headerName: "Sales Contact",
    flex: 1,
    minWidth: 150,
  },
  balance: {
    field: "balance",
    headerName: "Balance",
    flex: 1,
    minWidth: 150,
  },
  paid: {
    field: "paid",
    headerName: "Paid",
    flex: 1,
    minWidth: 150,
  },
  billTo: {
    field: "billTo",
    headerName: "Bill To",
    flex: 1,
    minWidth: 150,
  },
  invoiceDate: {
    field: "invoiceDate",
    headerName: "Invoice Date",
    flex: 1,
    minWidth: 150,
  },
  daysPassedDue: {
    field: "daysPassedDue",
    headerName: "Days Past Due",
    flex: 1,
    minWidth: 150,
  },
  dueDate: {
    field: "dueDate",
    headerName: "Due Date",
    flex: 1,
    minWidth: 150,
  },
  originalAmount: {
    field: "originalAmount",
    headerName: "Original Amount",
    flex: 1,
    minWidth: 150,
  },
  amount: {
    field: "amount",
    headerName: "Amount",
    flex: 1,
    minWidth: 150,
  },
  balanceDue: {
    field: "balanceDue",
    headerName: "Balance Due",
    flex: 1,
    minWidth: 150,
  },
  status: {
    field: "status",
    headerName: "Status",
    flex: 1,
    minWidth: 150,
  },
  poNumber: {
    field: "poNumber",
    headerName: "PO Number",
    flex: 1,
    minWidth: 200,
    maxWidth: 250,
  },
  orderedBy: {
    field: "orderedBy",
    headerName: "Ordered By",
    flex: 1,
    minWidth: 150,
  },
  daysDue: {
    field: "daysPassedDue",
    headerName: "Days Past Due",
    flex: 1,
    minWidth: 150,
  },
  postedDate: {
    field: "postedDate",
    headerName: "Posted Date",
    flex: 1,
    minWidth: 150,
  },
};
const ordersColumsIds = {
  status: 1,
  orderNo: 2,
  customer: 3,
  soldTo: 4,
  orderDate: 5,
  terms: 7,
  salesAssociate: 8,
  poNumber: 10,
  orderedBy: 11,
};
export const ordersIdsToName = {
  1: columnsDefinition.status.headerName,
  2: columnsDefinition.orderNo.headerName,
  3: columnsDefinition.customer.headerName,
  4: columnsDefinition.soldTo.headerName,
  5: columnsDefinition.orderDate.headerName,
  7: columnsDefinition.terms.headerName,
  8: columnsDefinition.salesAssociate.headerName,
  10: columnsDefinition.poNumber.headerName,
  11: columnsDefinition.orderedBy.headerName,
};
export const ordersIdsToDefinition = {
  1: columnsDefinition.status,
  2: columnsDefinition.orderNo,
  3: columnsDefinition.customer,
  4: columnsDefinition.soldTo,
  5: columnsDefinition.orderDate,
  7: columnsDefinition.terms,
  8: columnsDefinition.salesAssociate,
  10: columnsDefinition.poNumber,
  11: columnsDefinition.orderedBy,
};

const invoicesColumsIds = {
  customer: 17,
  orderNo: 18,
  invoiceNo: 19,
  invoiceDate: 20,
  dueDate: 21,
  postedDate: 22,
  originalAmount: 23,
  balanceDue: 24,
  terms: 25,
  salesAssociate: 26,
  orderDate: 28,
  status: 30,
  daysPassedDue: 31,
  poNumber: 32,
};

export const invoicesIdsToName = {
  17: columnsDefinition.customer.headerName,
  18: columnsDefinition.orderNo.headerName,
  19: columnsDefinition.invoiceNo.headerName,
  20: columnsDefinition.invoiceDate.headerName,
  21: columnsDefinition.dueDate.headerName,
  22: columnsDefinition.postedDate.headerName,
  23: columnsDefinition.originalAmount.headerName,
  24: columnsDefinition.balanceDue.headerName,
  25: columnsDefinition.terms.headerName,
  26: columnsDefinition.salesAssociate.headerName,
  28: columnsDefinition.orderDate.headerName,
  30: columnsDefinition.status.headerName,
  31: columnsDefinition.daysPassedDue.headerName,
  32: columnsDefinition.poNumber.headerName,
};
export const invoicesIdsToDefinition = {
  17: columnsDefinition.customer,
  18: columnsDefinition.orderNo,
  19: columnsDefinition.invoiceNo,
  20: columnsDefinition.invoiceDate,
  21: columnsDefinition.dueDate,
  22: columnsDefinition.postedDate,
  23: columnsDefinition.originalAmount,
  24: columnsDefinition.balanceDue,
  25: columnsDefinition.terms,
  26: columnsDefinition.salesAssociate,
  28: columnsDefinition.orderDate,
  30: columnsDefinition.status,
  31: columnsDefinition.daysPassedDue,
  32: columnsDefinition.poNumber
};
const invoiceIds = [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 31, 32];
const orderIds = [1, 2, 3, 4, 5, 7, 8, 10, 11];

export const columnsParser = (type = 1, currentColumns) => {
  let columnIds = [];
  currentColumns.forEach((column) => {
    if (type === 1) {
      if (ordersColumsIds[column.id]) {
        columnIds.push(ordersColumsIds[column.id]);
      }
    }
    if (type === 2) {
      if (invoicesColumsIds[column.id]) {
        columnIds.push(invoicesColumsIds[column.id]);
      }
    }
  });
  return columnIds;
};

export const getDisabledColumns = (type = 1, currentColumns) => {
  if (type === 1) {
    return orderIds.filter((x) => !currentColumns.includes(x));
  }
  if (type === 2) {
    return invoiceIds.filter((x) => !currentColumns.includes(x));
  }
};
