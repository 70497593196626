import React, { useMemo, useRef, useState, useEffect } from 'react';
import { Box, Grid, Button, CircularProgress, styled } from '@mui/material';
import Moment from 'moment';
import TopContainer from '../TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from '../Shared/TitleHeader/Index';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/features/user/userSlice';
import {
  BlueAquaSearchButtonOutline,
  BodyContainer,
  BodyGridWrapper,
} from '../Shared/BodyContainer/Index';
import { Search as SearchIcon } from '@mui/icons-material';
import SearchTableComponent from '../SearchTableComponent';
import AutoResizeGrid from '../AutoResizeGrid/AutoResizeGrid';
import logsAPI from '../../api/modules/logs';
import { NavLink } from 'react-router-dom';
import download from 'downloadjs';
import { useNotification } from '../../context/notificationContext';
import { useCallback } from 'react';
import styledC from 'styled-components';

const logTypes = {
  admin_invited: 'Admin Invited',
  client_invited: 'Client Invited',
  executive_invited: 'Executive Invited',
  invitation_resent: 'Invitation Resent',
  login: 'Login',
  pdf_download: 'Pdf Downloaded',
  preset_report_saved: 'Preset Report Saved',
  report_exported: 'Report Exported',
  report_saved: 'Report Saved',
  report_scheduled: 'Report Scheduled',
  request_accepted: 'Request Accepted',
  user_deleted: 'User Deleted',
  open_invoice_pdf_download: 'Open Invoice PDF Downloaded',
  closed_invoice_pdf_download: 'Closed Invoice PDF Downloaded',
  invoice_pdf_download: 'Invoice PDF Downloaded',
  order_pdf_download: 'Order PDF Downloaded',
};

const styles = {
  loadingContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const BackButton = styled(Box)`
  width: auto;
  padding: 0 12px;
  height: 42px;
  background: #44c0b4;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
`;

const LoadingOverlay = styledC.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background: rgba(255, 255, 255, 0.33);
`;

const LogDashboardComponent = () => {
  const [selectedRoleFilter, setSelectedRoleFilter] = useState('');
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState('ALL_USERS');
  const [isLoading, setIsLoading] = useState(true);
  const [isGeneratingFile, setIsGeneratingFile] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [textToSearch, setTextToSearch] = useState('');
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [logsCount, setLogsCount] = useState(0);
  const { setNotification } = useNotification();

  const userRedux = useSelector(selectUser);
  const tableRef = useRef(null);

  const scrollToTable = () => tableRef.current.scrollIntoView();

  const onPageChange = (page) => {
    scrollToTable();
    setCurrentPage(page + 1);
  };

  const onCloseSearch = () => {
    setIsSearchOpen(false);
    setTextToSearch('');
    handleReloadTable();
  };

  const handleReloadTable = async () => {
    await getLogsByRole(selectedRoleFilter, currentPage, pageSize);
  };

  const onOpenSearch = () => {
    setIsSearchOpen(true);
  };

  const columns = [
    {
      field: 'userFullName',
      headerName: 'User',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'userType',
      headerName: 'User Type',
      flex: 1,
      renderCell: ({ row }) => row.userType.replace(/_/g, ' '),
    },
    {
      field: 'logType',
      headerName: 'Event',
      flex: 1,
      renderCell: ({ row }) => logTypes[row.logType],
    },
    {
      field: 'involvedUserFullName',
      headerName: 'Target User',
      flex: 1,
      renderCell: ({ row }) => row.involvedUserFullName ?? 'NA',
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      flex: 1,
      renderCell: ({ row }) => Moment(row.createdAt).format('MM/DD/YYYY'),
    },
    {
      field: 'createdAtTime',
      headerName: 'Time',
      flex: 1,
      renderCell: ({ row }) => Moment(row.createdAt).format('HH:mm:ss'),
    },
    {
      field: 'reportName',
      headerName: 'Report Name',
      flex: 1,
      renderCell: ({ row }) => row.reportName ?? 'NA',
    },
    {
      field: 'sendTo',
      headerName: 'Send To',
      flex: 1,
      renderCell: ({ row }) => row.sendTo ?? 'NA',
    },
    {
      field: 'invoiceNumber',
      headerName: 'Invoice Number',
      flex: 1,
      renderCell: ({ row }) => row.invoiceNumber ?? 'NA',
    },
  ];

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const renderTable = useMemo(
    () => (
      <AutoResizeGrid
        sx={{ marginTop: '10px' }}
        rows={logs}
        columns={columns}
        onPageChange={onPageChange}
        pageSize={pageSize}
        paginationMode="server"
        page={currentPage - 1}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        rowCount={logsCount}
        autoHeight
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, logs, pageSize, logsCount]
  );

  const getXlsx = useCallback(async () => {
    try {
      setIsGeneratingFile(true);

      const response = await logsAPI.exportToXlsx({
        role: selectedRoleFilter,
      });

      const blob = new Blob([response.data], {
        type: response.headers['content-type'],
      });

      download(blob, 'User_Activity_Log.xlsx');
    } catch (error) {
      console.log(error);
      setNotification({
        open: true,
        message:
          error.response?.data?.message ??
          'An error has ocurred, please try again later.',
        type: 'error',
      });
    } finally {
      setIsGeneratingFile(false);
    }
  }, [selectedRoleFilter, setNotification]);

  const getLogsByRole = async (role = '', offset = 1, limit = 25, search) => {
    try {
      setIsLoading(true);

      const getLogsPayload = {
        offset,
        limit,
        role,
      };

      if (search) Object.assign(getLogsPayload, { search });

      const { data } = await logsAPI.getLogs(getLogsPayload);

      const _logs = data.logs.map((r) => ({
        ...r,
        createdAtTime: Moment(r.createdAt).format('HH:mm:ss'),
      }));

      setLogs(_logs);
      setLogsCount(data.count);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchLog = (text, selectedValue) => {
    setTextToSearch(text);
    setSelectedDropdownFilter(selectedValue);

    getLogsByRole(
      selectedValue === 'ALL_USERS' ? '' : selectedValue,
      currentPage,
      pageSize,
      text
    );
  };

  useEffect(() => {
    getLogsByRole(
      selectedRoleFilter,
      currentPage,
      pageSize,
      isSearchOpen ? textToSearch : null
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRoleFilter, currentPage, pageSize]);

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ minHeight: 'inherit', display: 'inherit' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopContainer>
            <TitleHeaderRow>
              <TitleHeaderCol>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    fontStyle: 'normal',
                    letterSpacing: '0.15px',
                  }}
                >
                  <span style={{ fontWeight: 700 }}>
                    {`${userRedux.firstName} ${userRedux.lastName}`}
                  </span>{' '}
                  - {`${userRedux.role?.name?.replace(/_/g, ' ') ?? ''}`}
                </Box>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: '48px',
                    lineHeight: '65px',
                  }}
                >
                  User Activity Log
                </Box>
              </TitleHeaderCol>
              <TitleHeaderCol>
                <Box display="flex" gap="18px" flexDirection="row">
                  <BackButton onClick={getXlsx}>Export to Excel</BackButton>
                  <BackButton>
                    <NavLink
                      style={{
                        textDecoration: 'none',
                        color: 'white',
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      to={'/dashboard'}
                    >
                      {'< Back'}
                    </NavLink>
                  </BackButton>
                </Box>
              </TitleHeaderCol>
            </TitleHeaderRow>
          </TopContainer>
        </Grid>

        <BodyGridWrapper ref={tableRef} item xs={12} md={12}>
          {isGeneratingFile && (
            <LoadingOverlay>
              <CircularProgress style={{ color: '#B80A26' }} size={60} />
            </LoadingOverlay>
          )}
          <BodyContainer>
            <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              {!isSearchOpen ? (
                <>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ALL_USERS'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('');
                      setSelectedDropdownFilter('ALL_USERS');
                    }}
                  >
                    All Users
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ADMIN'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('ADMIN');
                      setSelectedDropdownFilter('ADMIN');
                    }}
                  >
                    Admin Users
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter === 'ACCOUNT_EXECUTIVE'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter('ACCOUNT_EXECUTIVE');
                      setSelectedDropdownFilter('ACCOUNT_EXECUTIVE');
                    }}
                  >
                    Account Executives
                  </Button>
                  <Button
                    style={{ flexGrow: 1, marginRight: '24px' }}
                    color="customRed"
                    variant={
                      selectedDropdownFilter ===
                      'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                        ? 'contained'
                        : 'outlined'
                    }
                    sx={styles.filterButton}
                    onClick={() => {
                      setSelectedRoleFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                      );
                      setSelectedDropdownFilter(
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER'
                      );
                    }}
                  >
                    Clients
                  </Button>
                  <BlueAquaSearchButtonOutline
                    onClick={onOpenSearch}
                    variant="blueGradient-outlined"
                  >
                    <SearchIcon fontSize={'large'} />
                  </BlueAquaSearchButtonOutline>
                </>
              ) : (
                <SearchTableComponent
                  onCloseSearch={onCloseSearch}
                  onSubmitSearch={handleSearchLog}
                  defaultValue={selectedDropdownFilter}
                  items={[
                    {
                      value: 'ALL_USERS',
                      label: 'All Users',
                    },
                    {
                      value: 'ADMIN',
                      label: 'Admin Users',
                    },
                    {
                      value: 'ACCOUNT_EXECUTIVE',
                      label: 'Account Executives',
                    },
                    {
                      value:
                        'CLIENT_ADMIN,CLIENT_STANDARD_USER,CLIENT_ACCOUNT_OWNER',
                      label: 'Clients',
                    },
                  ]}
                />
              )}
            </Box>

            {isLoading ? (
              <div style={styles.loadingContainer}>
                <CircularProgress style={{ color: '#B80A26' }} size={60} />
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>{renderTable}</div>
            )}
          </BodyContainer>
        </BodyGridWrapper>
      </Grid>
    </Box>
  );
};

export default LogDashboardComponent;
