import { PluginService } from "../pluginService";

const authenticationService = PluginService("");

export default {
  login(data) {
    return authenticationService({
      method: "POST",
      url: `/login`,
      data,
    });
  },

  logout() {
    return authenticationService({
      method: "POST",
      url: `/logout`,
    });
  },

  signUp(data) {
    return authenticationService({
      method: "POST",
      url: `/signup`,
      data,
    });
  },

  forgetPassword(data) {
    return authenticationService({
      method: "POST",
      url: `/forget-password`,
      data,
    });
  },

  inviteAdmin(data) {
    return authenticationService({
      method: "POST",
      url: `/invite-admin`,
      data,
    });
  },

  inviteExecutive(data) {
    return authenticationService({
      method: "POST",
      url: `/invite-executive`,
      data,
    });
  },

  inviteClientOwner(data) {
    return authenticationService({
      method: "POST",
      url: `/invite-client`,
      data,
    });
  },

  resetPassword(data) {
    return authenticationService({
      method: "POST",
      url: `/reset-password`,
      data,
    });
  },

  invite(data) {
    return authenticationService({
      method: "POST",
      url: `/invite`,
      data,
    });
  },

  setPassword(data) {
    return authenticationService({
      method: "POST",
      url: `/set-password`,
      data,
    });
  },

  validateOTP(data) {
    return authenticationService({
      method: "POST",
      url: `/login/otp`,
      data,
    });
  },

  resendInvite(data) {
    return authenticationService({
      method: "POST",
      url: `/resendInvite`,
      data,
    });
  },
  resendOTPcode(data) {
    return authenticationService({
      method: "POST",
      url: `/resend-otp`,
      data,
    });
  },
};
