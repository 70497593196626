import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AppBar,
  Box,
  Toolbar,
  Menu,
  Button,
  MenuItem,
  Divider,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { CloseOutlined, Settings as SettingsIcon } from '@mui/icons-material';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/authContext';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SearchIcon from '@mui/icons-material/Search';
import styledC from 'styled-components';
import { InputAdornment } from '@mui/material';
import accountAPI from '../api/modules/accounts';
import usersAPI from '../api/modules/users';
import Icons from './icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedCompany,
  getSelectedCompany,
  resetSelectedCompany,
  selectUser,
  removeSelectedCompany,
} from '../redux/features/user/userSlice';
import BugReportingDialog from './Dialogs/BugReportingDialog';
import Microage_Logo from './../images/Microage_Logo.svg';

const StMenu = styled(Menu)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    width: '203px',
    '& .MuiMenu-list': {
      padding: '0px ',
    },
  },
}));

const StContainer = styledC.div`
  max-width: none;
  height: 90px;
  align-items: center;
  display: flex;
  width: 100%;
`;

const StFilterButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: 'white',
  },
  width: 'auto',
  fontFamily: 'OpenSans',
  display: 'flex',
  height: '100%',
  borderRadius: '0px',
  borderStyle: 'none',
  backgroundColor: 'white',
  color: '#444444',
  textTransform: 'unset !important',
  '&:disabled': {
    backgroundColor: 'transparent',
  },
  margin: '0 12px',
  padding: 0,
  lineHeight: '20px',
}));
const StDivider = styled(Divider)(({ theme }) => ({
  marginTop: '0px !important',
  marginBottom: '0px !important',
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 0,
    minWidth: 180,
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '0px ',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        marginRight: theme.spacing(1.5),
      },
    },
  },
}));

const StMenuItem = styled(MenuItem)(({ theme }) => ({
  justifyContent: 'space-between',
  fontFamily: 'OpenSans',
  '&:hover': {
    backgroundColor: 'rgba(239, 180, 71, 0.2)',
    fontWeight: 'bold',
  },
}));
const StCheckBox = styled(Checkbox)(({ theme }) => ({
  color: '#E0E0E0',
  '&.Mui-checked': {
    color: '#44C0B4',
  },
}));

const SearchInput = styled(TextField)(({ theme }) => ({
  width: '85%',
  '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root': {
    padding: '0 16px',
  },
  '& .MuiInput-input': {
    fontFamily: 'OpenSans',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',

    '&::placeholder': {
      color: '#000',
      opacity: 0.54,
      fontFamily: 'OpenSans',
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  paddingBottom: '10px',
  paddingTop: '10px',
  border: 'none',
  backgroundColor: '#FAFAFA',
}));

const StLink = styledC(NavLink)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 950;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.54);
  text-decoration: none;

  &.active {
    font-weight: 1050;
    color: #B80A26;
  }
`;

const CompanyDetaillWrapper = ({ name = '', email = '' }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-start',
      }}
    >
      <span
        style={{
          fontFamily: 'OpenSans',
          fontStyle: 'normal',
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '143%',
        }}
      >
        {name}
      </span>
    </Box>
  );
};

const LoadingContainer = styledC.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 10px 0 30px;
`;

const MainToolbar = styled(Toolbar)`
  padding: 0 26px;
  height: 100%;
  display: flex;
  width: 100%;
`;

const ToolbarRow = styledC.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

const ToolbarCol = styledC.div`
  display: flex;
  align-items: center;
  height: 100%;
`;

const DivisorBox = styledC(Box)`
  height: 100%;
  display: flex;
  align-items: center;
  border-right: 1px solid #E0E0E0;
`;

const ResponsiveAppBar = () => {
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { onLogout } = useAuth();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const userRedux = useSelector(selectUser);
  const [companyList, setCompanyList] = useState([]);
  const [openBugModel, setOpenBugModel] = useState(false);
  const [formType, setFormType] = useState('');
  const openSettings = Boolean(anchorElSettings);
  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };
  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  const handleCloseBugAndFeedbackModel = () => {
    setOpenBugModel(false);
    setFormType('');
  };

  const handleBugReporting = () => {
    setOpenBugModel(true);
    setFormType('bug');
  };

  const handleFeedBack = () => {
    setOpenBugModel(true);
    setFormType('feedback');
  };

  const handleLogout = async () => {
    try {
      onLogout();
      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };
  const isCientUser =
    userRedux.role.name === 'CLIENT_STANDARD_USER' ||
    userRedux.role.name === 'CLIENT_ADMIN';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const styles = {
    logo: {
      height: '49.1px',
      width: '114px',
      left: '0px',
      top: '21px',
    },
  };

  const searchAccounts = useCallback(async (search) => {
    try {
      setIsLoading(true);

      const searchPayload = {
        search,
      };

      if (userRedux.role.name === 'ACCOUNT_EXECUTIVE') {
        Object.assign(searchPayload, {
          accountExecutiveId: userRedux.role.id,
        });
      }

      const { data } = await accountAPI.getAccounts(searchPayload);

      const companies = data.value.map(({ No_, Name }) => ({
        id: No_,
        name: Name,
      }));

      setCompanies(companies);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCheckBoxChange = useCallback(
    (id, checked) => {
      const company = companies.find((_company) => _company.id === id);

      if (checked) {
        const companyExists = companyList.find(
          (_company) => _company.id === id
        );

        if (!companyExists)
          setCompanyList((prev) => [
            {
              ...company,
            },
            ...prev,
          ]);
        dispatch(setSelectedCompany(company));
      } else {
        setCompanyList((prev) => prev.filter((_company) => _company.id !== id));
        dispatch(removeSelectedCompany(id));
      }
    },
    [companies, companyList, dispatch]
  );

  const onCompanyListCheckBoxChange = useCallback(
    (id, checked) => {
      if (checked) {
        const company = companyList.find((_company) => _company.id === id);
        dispatch(setSelectedCompany(company));
      } else {
        dispatch(removeSelectedCompany(id));
      }
    },
    [companyList, dispatch]
  );

  const onCompanyListRemove = useCallback(
    (id) => {
      setCompanyList((prev) => prev.filter((_company) => _company.id !== id));
    },
    [setCompanyList]
  );

  useEffect(() => {
    if (location.pathname.includes('build-report')) {
      setCompanyList(
        selectedCompanyRedux.filter((company) => company.id !== -1)
      );
    }
  }, [location, selectedCompanyRedux, setCompanyList, companyList]);

  useEffect(() => {
    setIsLoading(true);
    const getClientData = async () => {
      try {
        const searchPayload = {
          limit: 5,
          offset: 2,
        };
        if (userRedux.role.name === 'ACCOUNT_EXECUTIVE') {
          Object.assign(searchPayload, {
            accountExecutiveId: userRedux.role.id,
          });
        }
        let selectedCompanyExists = false;
        const { data } = await accountAPI.getAccounts(searchPayload);

        const accountsData = data.value.map((account) => {
          if (
            selectedCompanyRedux.find((company) => company.id === account.No_)
          ) {
            selectedCompanyExists = true;
          }
          return { id: account.No_, name: account.Name };
        });

        if (selectedCompanyExists === false && !allCompanies) {
          accountsData.unshift(selectedCompanyRedux);
        }

        setCompanyList(accountsData);
      } catch (error) {
        console.log(error);
      } finally {
      }
    };
    const getClientDataClientUser = async () => {
      try {
        const { data } = await usersAPI.getUserInfo(userRedux.id);
        const accountsData = data.value ?? data.data.accounts;
        const accountInfo = accountsData[0] ?? accountsData;

        dispatch(resetSelectedCompany());

        dispatch(
          setSelectedCompany({
            id: accountInfo.id,
            name: accountInfo.company,
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    if (isCientUser === true) {
      getClientDataClientUser();
    } else {
      getClientData();
    }

    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allCompanies = selectedCompanyRedux.find(
    (selectedCompany) => selectedCompany.id === -1
  );

  const renderCompanies = useMemo(
    () =>
      companies.map((company) => {
        const isChecked = selectedCompanyRedux?.find(
          (selectedCompany) => selectedCompany.id === company.id
        );
        const checked = isChecked ? true : false;
        return (
          <div
            key={company.id}
            onClick={() => onCheckBoxChange(company.id, !checked)}
          >
            <StDivider sx={{ my: 0.5 }} />
            <StMenuItem
              disableRipple
              style={{ justifyContent: 'space-between' }}
              sx={{ justifyContent: 'space-between' }}
            >
              <FormGroup>
                <CompanyDetaillWrapper
                  name={company.name}
                  email={company.email}
                />
              </FormGroup>
              <div>{checked ? <Icons.CheckIcon /> : <Icons.AddIcon />}</div>
            </StMenuItem>
          </div>
        );
      }),
    [companies, selectedCompanyRedux, onCheckBoxChange]
  );

  const renderSelectedCompanies = useMemo(
    () =>
      companyList.map((company) => {
        const isChecked = selectedCompanyRedux?.find(
          (selectedCompany) => selectedCompany.id === company.id
        );
        const checked = isChecked ? true : false;
        return (
          <div key={company.id}>
            <StDivider sx={{ my: 0.5 }} />
            <StMenuItem disableRipple>
              <FormGroup>
                <FormControlLabel
                  control={
                    <StCheckBox
                      onChange={(e, checked) =>
                        onCompanyListCheckBoxChange(company.id, checked)
                      }
                      checked={checked}
                    />
                  }
                  label={
                    <CompanyDetaillWrapper
                      name={company.name}
                      email={company.email}
                    />
                  }
                />
              </FormGroup>
              <IconButton onClick={() => onCompanyListRemove(company.id)}>
                <Icons.RemoveIcon />
              </IconButton>
            </StMenuItem>
          </div>
        );
      }),
    [
      companyList,
      onCompanyListCheckBoxChange,
      onCompanyListRemove,
      selectedCompanyRedux,
    ]
  );

  const showCompanies = () => searchText.trim() !== '';

  const onSelectAllChanges = (e, checked) => {
    if (!allCompanies) {
      dispatch(resetSelectedCompany());
      return;
    }
  };

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  const navigateToShadowAccounts = () => {
    navigate('/shadow-accounts');
  };

  useEffect(() => {
    if (searchText.trim().length < 4) {
      return setCompanies([]);
    }

    setIsLoading(true);

    const delayFn = setTimeout(() => {
      if (searchText.trim().length >= 4) {
        searchAccounts(searchText.trim());
      }
    }, 600);

    return () => clearTimeout(delayFn);
  }, [searchText, searchAccounts]);

  return (
    <>
      <AppBar position="sticky" sx={{ backgroundColor: 'white', zIndex: 1250 }}>
        <StContainer>
          <MainToolbar disableGutters sx={{ color: 'black' }}>
            <ToolbarRow>
              <NavLink to={'/orders'}>
                <Box sx={styles.logo}>
                  <img src={Microage_Logo} alt="microLogo" />
                </Box>
              </NavLink>

              <ToolbarCol>
                <DivisorBox>
                  {/* //Workaround */}

                  {userRedux.role.name !== 'CLIENT_STANDARD_USER' && (
                    <StLink style={{ margin: '0 12px' }} to={'/dashboard'}>
                      User Management
                    </StLink>
                  )}

                  <StLink style={{ margin: '0 12px' }} to={'/orders'}>
                    Orders / Invoices
                  </StLink>
                  <StLink to={'/reports'} style={{ margin: '0 24px 0 12px' }}>
                    Reports
                  </StLink>
                </DivisorBox>
                {/* WIP */}
                <DivisorBox style={{ padding: '0 12px' }}>
                  <StFilterButton
                    id="demo-customized-button"
                    aria-controls={open ? 'demo-customized-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    variant="contained"
                    disableElevation
                    onClick={isCientUser === false ? handleClick : () => {}}
                    endIcon={
                      isCientUser === true ? <></> : <Icons.ArrowDropDown />
                    }
                  >
                    {selectedCompanyRedux.length ? (
                      <CompanyDetaillWrapper
                        name={
                          selectedCompanyRedux.length === 1
                            ? selectedCompanyRedux[0].name
                            : 'Multiple Companies Selected'
                        }
                      />
                    ) : (
                      <CompanyDetaillWrapper name={'Select a client'} />
                    )}
                  </StFilterButton>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      'aria-labelledby': 'demo-customized-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      style: {
                        maxHeight: '400px',
                      },
                    }}
                  >
                    <StMenuItem
                      disableRipple
                      sx={{ width: '364px' }}
                      onKeyDown={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <SearchIcon sx={{ width: '24px', height: '24px' }} />
                      <SearchInput
                        placeholder="Search Clients"
                        autoComplete="off"
                        variant="standard"
                        value={searchText}
                        onChange={({ target }) => setSearchText(target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {showCompanies() && (
                                <CloseOutlined
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    setSearchText('');
                                  }}
                                />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </StMenuItem>
                    <StDivider sx={{ my: 0.5 }} />

                    <StMenuItem
                      disableRipple
                      sx={{ backgroundColor: '#FAFAFA' }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <StCheckBox
                              onChange={onSelectAllChanges}
                              checked={allCompanies ? true : false}
                            />
                          }
                          label={
                            <span
                              style={{
                                color: '#000',
                                opacity: 0.54,
                                fontFamily: 'OpenSans',
                                fontStyle: 'italic',
                                fontWeight: '400',
                                fontSize: '14px',
                                lineHeight: '24px',
                              }}
                            >
                              Select all Clients
                            </span>
                          }
                        />
                      </FormGroup>
                    </StMenuItem>

                    {showCompanies() ? (
                      isLoading && searchText.trim().length >= 4 ? (
                        <LoadingContainer>
                          <CircularProgress
                            style={{ color: '#B80A26' }}
                            size={30}
                          />
                        </LoadingContainer>
                      ) : (
                        renderCompanies
                      )
                    ) : (
                      renderSelectedCompanies
                    )}
                  </StyledMenu>
                </DivisorBox>
                <Box
                  sx={{
                    height: '100%',
                    alignItems: 'center',
                    display: 'flex',
                  }}
                >
                  <SettingsIcon
                    sx={{
                      fill: openSettings ? '#B80A26' : 'rgba(0, 0, 0, 0.38)',
                      marginLeft: '27px',
                      marginRight: '1px',
                      cursor: 'pointer',
                      width: 30,
                      height: 30,
                    }}
                    id="basic-button"
                    aria-controls={openSettings ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openSettings ? 'true' : undefined}
                    onClick={handleClickSettings}
                    variant={openSettings ? 'contained' : 'outlined'}
                  />
                  <StMenu
                    id="basic-menu"
                    anchorEl={anchorElSettings}
                    open={openSettings}
                    onClose={handleCloseSettings}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                    sx={{
                      fontFamily: 'Montserrat',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      fontSize: '14px',
                      letterSspacing: '0.15px',
                      color: 'rgba(0, 0, 0, 0.54)',
                      borderRadius: '0 px !important',
                      paddingTop: '0px !important',
                    }}
                  >
                    <StMenuItem onClick={navigateToDashboard}>
                      Manage Users
                    </StMenuItem>
                    <StDivider />
                    {userRedux.role.name !== 'CLIENT' &&
                      userRedux.role.name !== 'CLIENT_ADMIN' && (
                        <div>
                          <StMenuItem onClick={navigateToShadowAccounts}>
                            Shadow Account
                          </StMenuItem>
                          <StDivider />
                        </div>
                      )}
                    <StMenuItem onClick={handleBugReporting}>
                      Bug Reporting
                    </StMenuItem>
                    <StDivider />
                    <StMenuItem onClick={handleFeedBack}>Feedback</StMenuItem>
                    <StDivider />
                    <StMenuItem onClick={handleLogout}>Log Out</StMenuItem>
                  </StMenu>
                </Box>
              </ToolbarCol>
            </ToolbarRow>
          </MainToolbar>
        </StContainer>
      </AppBar>
      <BugReportingDialog
        open={openBugModel}
        onClose={handleCloseBugAndFeedbackModel}
        type={formType}
      />
    </>
  );
};
export default ResponsiveAppBar;
