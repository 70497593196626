import React, { useEffect, useState, useMemo } from "react";
import { styled as muiStyled } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";
import { SearchOutlined } from "@mui/icons-material";
import PresetReport from "./ReportsComponent/PresetReport";
import Icons from "../icons";
import { getSelectedCompany } from "../../redux/features/user/userSlice";
import reportsAPI from "../../api/modules/reports";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { ConfirmDialog } from "../Dialogs/ConfirmDialog";
import { useNotification } from "../../context/notificationContext";
import { validateNullValue } from "../../Helpers/formatters";
import { scrollUp } from "./helpers/reportHelper";

const CustomColorDataGrid = muiStyled(DataGrid)(({ theme }) => ({
  fontFamily: "Montserrat",
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

const FilterTextField = muiStyled(TextField)(({ theme }) => ({
  "& .MuiInput-input": {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "1px",
    lineHeight: "24px",

    "&::placeholder": {
      color: "#444444",
      opacity: 1,
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
}));
const StatusTextField = muiStyled(Box)(() => ({
  fontFamily: "Montserrat",
  fontStyle: "italic",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "24px",
  display: "contents",
}));

const SearchReportInput = styled(TextField)`
  .MuiOutlinedInput-input {
    &::placeholder {
      opacity: 1;
      font-family: "Montserrat";
      font-style: italic;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #444444;
    }
  }

  fieldset {
    border: 1px solid #e0e0e0;
  }
`;

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 60px 0;
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 24px;

  .MuiTextField-root {
    width: 100%;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const FilterButton = styled(Button)`
  width: 100%;
  border-radius: 0 !important;
  font-family: "Montserrat" !important;
  font-weight: 700 !important;
  text-transform: unset !important;
  height: 56px;
`;

const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const textOptions = {
  edit: {
    confirm: "Delete",
    title: "Delete Report",
    dialogText: "",
  },
  run: {
    confirm: "Run",
    title: "Run Report",
    dialogText: "",
  },
  pause: {
    confirm: "Pause",
    title: "Pause Report",
    dialogText: "",
  },
};

const runScheduleOptions = {
  1: "Daily",
  2: "Weekly",
  3: "Monthly",
};

const ReportsHomePageTable = ({ selectedFilter, setSelectedFilter }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [presetReports, setPresetReports] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [actionSubmit, setActionSubmit] = useState(false);
  const [dialogRow, setDialogRow] = useState({});
  const [action, setAction] = useState("edit");

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const { setNotification } = useNotification();

  const onPageChange = (page) => setCurrentPage(page + 1);

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };
  const handleOnAfterSubmit = () => {
    setIsConfirmOpen(false);
    setDialogRow({});
  };
  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      if (action === "edit") {
        const response = await reportsAPI.deletePreset(dialogRow.id);

        if (response.data.success === false) {
          throw response;
        }

        setNotification({
          open: true,
          message: (
            <>
              <b>{dialogRow.name}</b>
              {" Report has been removed from the system."}
            </>
          ),
        });
      }
      if (action === "pause") {
        const response = await reportsAPI.updateReportStatus({ runningStatus: 2 }, dialogRow.id);

        if (response.data.success === false) {
          throw response;
        }

        setNotification({
          open: true,
          message: (
            <>
              <b>{dialogRow.name}</b>
              {" has been paused."}
            </>
          ),
        });
      }
      if (action === "run") {
        const response = await reportsAPI.updateReportStatus({ runningStatus: 1 }, dialogRow.id);
        
        if (response.data.success === false) {
          throw response;
        }

        setNotification({
          open: true,
          message: (
            <>
              <b>{dialogRow.name}</b>
              {" is running."}
            </>
          ),
        });
      }

      setActionSubmit(!actionSubmit);

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ? response.data.message :  "An error occured. Please try again.",
        type: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    {
      field: "runningStatus",
      headerName: "Status",
      flex: 1,
      renderCell: ({ row }) => {
        if (row.runningStatus === 1) {
          return (
            <StatusTextField>
              <IconButton
                title="Pause"
                onClick={() => {
                  setAction("pause");
                  setIsConfirmOpen(true);
                  setDialogRow(row);
                }}
              >
                <Icons.PauseCircleIcon />
              </IconButton>
              <Box style={{ fontWeight: "bold" }}> Running</Box>
            </StatusTextField>
          );
        }
        if (row.runningStatus === 2) {
          return (
            <StatusTextField>
              <IconButton
                title="Run"
                onClick={() => {
                  setAction("run");
                  setIsConfirmOpen(true);
                  setDialogRow(row);
                }}
              >
                <Icons.PlayCircleIcon color="#5058F2" />
              </IconButton>
              <Box style={{ opacity: "0.5" }}> Paused</Box>
            </StatusTextField>
          );
        }
      },
    },

    {
      field: "name",
      headerName: "Report Name",
      minWidth: 400,
      maxWidth: 600,
      flex: 1,
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      flex: 1,
      valueGetter: ({ row }) =>
      validateNullValue(row.createdDate, true),
    },
    {
      field: "group",
      headerName: "Group",
      flex: 1,
      valueGetter: ({ row }) => {
        const { group } = row;

        return group === 1 ? "Orders" : "Invoices";
      },
    },
    // {
    //   field: "schedule",
    //   headerName: "Run Schedule",
    //   flex: 1,
    // },
    {
      field: "lastRunDate",
      headerName: "Last Run Date",
      minWidth: 250,
      flex: 1,
      valueGetter: ({ row }) => {
        const lastRunDate = validateNullValue(row.lastRunDate, true);
        return lastRunDate === "Not Available" ? "-" : lastRunDate;
      },
    },
    {
      field: "frequency",
      headerName: "Run Schedule",
      flex: 1,
      valueGetter: ({ row }) => {
        const { schedule } = row;

        if (schedule.length > 0) {
          return runScheduleOptions[schedule[0].runSchedule];
        } else {
          return "Not Set";
        }
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 186,
      renderCell: ({ row }) => {
        return (
          <ActionWrapper>
            <IconButton
              title="Edit Report"
            >
              <NavLink to={`/build-report/edit-${row.id}`}>
                <Icons.EditIcon color="#BDBDBD" />
              </NavLink>
            </IconButton>
            <IconButton
              title="Delete Report"
              onClick={() => {
                setAction("edit");
                setIsConfirmOpen(true);
                setDialogRow(row);
              }}
            >
              <Icons.RemoveIcon color="#BDBDBD" />
            </IconButton>
          </ActionWrapper>
        );
      },
    },
  ];

  const handleSubmit = () => {
    getReports(
      selectedCompanyRedux,
      currentPage,
      pageSize,
      selectedFilter,
      startDate,
      endDate,
      searchText
    );
  };

  const renderTable = useMemo(
    () => (
      <CustomColorDataGrid
        columns={columns}
        defaultGroupingExpansionDepth={1}
        onPageChange={onPageChange}
        // paginationMode="server"
        page={currentPage - 1}
        rows={items}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        rowCount={itemCount}
        isRowSelectable={(params) => false}
        autoHeight
      />
    ),
    [currentPage, items, pageSize, itemCount]
  );

  const renderPresets = useMemo(
    () => presetReports.map((preset) => <PresetReport preset={preset} />),
    [presetReports]
  );

  const handleChangeDatePicker = (type, newValue) => {
    if (type === "startDate") {
      return setStartDate(newValue);
    }
    if (type === "endDate") {
      return setEndDate(newValue);
    }
  };

  const getPresetReports = async (
    status = "",
    offset = 1,
    limit = 25,
    search
  ) => {
    try {
      setIsLoading(true);
      const reportsPayload = {
        offset,
        limit,
        search,
        status,
      };

      if (search) Object.assign(reportsPayload, { search });

      const { data } = await reportsAPI.getDefaultPresets(reportsPayload);
      setPresetReports(data.defaultPresets);
      setItemCount(data.count);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const getReports = async (status = "", offset = 1, limit = 25, search) => {
    try {
      setIsLoading(true);

      const reportsPayload = {
        offset,
        limit,
        search,
        status,
      };

      if (search) Object.assign(reportsPayload, { search });
      if (action === "run") {
        setTimeout(function () {
          //your code to be executed after 1 second
        }, 3000);
      }

      const { data } = await reportsAPI.getSavedReports(reportsPayload);
      setItems(data.reports);
      setItemCount(data.length);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    scrollUp();
  },[selectedFilter]);

  useEffect(() => {
    if (selectedFilter === "recent_reports") {
      getReports("recent");
    }
    if (selectedFilter === "saved_reports") {
      getReports();
    }
    if (selectedFilter === "preset_reports") {
      getPresetReports();
    }
  }, [
    selectedCompanyRedux,
    currentPage,
    pageSize,
    selectedFilter,
    startDate,
    endDate,
    actionSubmit,
  ]);

  return (
    <>
      <Container>
        <Box width="100%">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <SearchReportInput
              onKeyPress={(ev) => {
                if (ev.key === "Enter") handleSubmit();
              }}
              onChange={({ target }) => setSearchText(target.value)}
              value={searchText}
              fullWidth
              placeholder="Search reports in this table"
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton title="Search" onClick={handleSubmit}>
                      <SearchOutlined />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FilterButton
              color="customRed"
              variant={
                selectedFilter === "recent_reports" ? "contained" : "outlined"
              }
              onClick={() => setSelectedFilter("recent_reports")}
              sx={{ marginX: 3 }}
            >
              Recent Reports
            </FilterButton>
            <FilterButton
              color="customRed"
              variant={
                selectedFilter === "saved_reports" ? "contained" : "outlined"
              }
              onClick={() => setSelectedFilter("saved_reports")}
            >
              Saved Reports
            </FilterButton>
          </Box>
        </Box>
      </Container>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={textOptions[action].confirm}
        isSubmitting={isSubmitting}
        title={textOptions[action].title}
        text={textOptions[action].text}
      />

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ color: "#B80A26" }} size={60} />
        </LoadingContainer>
      ) : (
        <>{selectedFilter === "preset_reports" ? renderPresets : renderTable}</>
      )}
    </>
  );
};

export default ReportsHomePageTable;
