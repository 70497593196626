import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  DialogTitle,
  Dialog,
  TextField,
  Box,
  Stack,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik, Field } from "formik";
import * as Yup from "yup";
import { useNotification } from "../context/notificationContext";
import { styled as muiStyled } from "@mui/material/styles";
import styled from 'styled-components';
import authAPI from "../api/modules/authentication";
import userAPI from "../api/modules/users";
import { ConfirmDialog } from "./Dialogs/ConfirmDialog";

const styles = {
  formContainer: {
    width: "65%",
    marginLeft: "17%",
    marginBottom: "10%",
  },
  DialogHeader: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "123.5%",
    color: "#B80A26",
    marginBottom: "24px",
    marginLeft: "17%",
    paddingLeft: 0,
  },
  submitButton: {
    borderRadius: "1px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: "1px",
    textTransform: "unset !important",
  },
  removeUser: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    letterSpacing: "1px",
    textTransform: "unset !important",
    color: "#B80A26",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  userPermissons: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    textTransform: "unset !important",
    display: "flex",
    alignItems: "center",
  },
  errorMessages: {
    color: "#B80A26",
  },
  cancelButton: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'capitalize',
    color: '#B80A26',
  },
};

const StCheckBoxLabel = muiStyled(Box)(({ theme }) => ({
  fontFamily: "Montserrat",
}));

const Red = styled.b`
  color: #B80A26;
`;

const AdminModalComponent = (props) => {
  const { admin, open, onClose, handleSubmit } = props;
  const { setNotification } = useNotification();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    role: 2,
    permissions: ["2", "3", "4"],
  };

  const [initialFormValues, setInitialFormValues] = useState(initialValues);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [payloadAction, setPayloadAction] = useState({ action: '', payload: {} });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const titleMessage = admin ? "Edit Admin User" : "Invite New Admin User";

  const { action, payload } = payloadAction;

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First name is required')
      .max(50, 'First name must be at most 50 characters'),
    lastName: Yup.string()
      .required('Last name is required')
      .max(50, 'Last name must be at most 50 characters'),
    email: Yup.string()
      .email('Valid email address required (must have @ and . to be valid)')
      .required('Email is required')
      .max(250, 'Email must be at most 250 characters'),
    permissions: Yup.array()
      .min(1, 'Permissions are required')
      .required('Permissions are required'),
  });

  const handleClose = () => {
    onClose();
    setInitialFormValues(initialValues);
  };


  const onSubmit = async (values, { setSubmitting }) => {
    try {
      if (admin) {
        const editUserPayload = {
          id: values.id,
          firstName: values.firstName,
          lastName: values.lastName,
          permissions: values.permissions,
          email: values.email,
        }

        setPayloadAction({
          action: 'edit',
          payload: editUserPayload,
        });

        return setIsConfirmOpen(true);

      } else {
        await authAPI.inviteAdmin(values);

        setNotification({
          open: true,
          message:
            (
              <>
                <b>{values.firstName} {values.lastName}</b>{" was invited!"}
              </>
            ),
        });

        handleOnAfterSubmit();
      }
    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  const handleRemoveUser = async () => {
    setPayloadAction({
      action: 'delete',
      payload: admin,
    });

    setIsConfirmOpen(true);
  };

  const getAdminData = async () => {
    try {
      setIsLoading(true);

      const { data } = await userAPI.getUserInfo(admin.id);

      setInitialFormValues({
        ...data.data,
        permissions: data.data?.permissions?.map((permission) => permission.toString())
      });
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  };

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  };

  const handleOnAfterSubmit = async () => {
    onClose();
    handleSubmit();
    setTimeout(() => {
      setIsConfirmOpen(false);
    }, 0);
  }

  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      if (action === 'edit') {

        await userAPI.editAdmin({ id: payload.id }, payload);

        setNotification({
          open: true,
          message: (
            <>
              <b>{payload.firstName} {payload.lastName}</b>{" user has been updated."}
            </>
          ),
        });

      } else if (action === 'delete') {
        await userAPI.deleteUser({ id: payload.id });

        setNotification({
          open: true,
          message: (
            <>
              <b>{payload.firstName} {payload.lastName}</b>{" has been removed from the system."}
            </>
          ),
        });
      }

      handleOnAfterSubmit();

    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    if (admin) {
      getAdminData();
    }

    return () => {
      setIsLoading(false);
      setInitialFormValues(initialValues);
    };
  }, [admin]);

  const dialogText = action === 'edit'
    ? 'If you continue, you cannot undo this action.'
    : (
      <>Are you sure you want to remove <Red>{payload?.firstName} {payload?.lastName}</Red>. This action cannot be undone. </>
    )

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={action === 'delete' ? 'Delete' : 'Okay'}
        isSubmitting={isSubmitting}
        title={action === 'edit' ? 'Are you sure you want to continue?' : 'Remove Account'}
        text={dialogText}
      />

      <Dialog
        hidden={isConfirmOpen}
        onClose={handleClose}
        open={open}
        fullWidth={true}
        maxWidth={"sm"}
        scroll={"body"}
      >
        <DialogTitle sx={styles.DialogHeader}>
          {titleMessage}{" "}
          {onClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>

        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ errors, isSubmitting, isValid, values, handleChange }) => (
            <Form>
              <Stack sx={styles.formContainer} spacing={2}>
                <TextField
                  disabled={isLoading}
                  label="First Name"
                  variant="outlined"
                  onChange={handleChange("firstName")}
                  value={values.firstName}
                  helperText={errors.firstName ?? ""}
                  FormHelperTextProps={{ style: styles.errorMessages }}
                  name="firstName"
                />
                <TextField
                  disabled={isLoading}
                  label="Last Name"
                  variant="outlined"
                  onChange={handleChange("lastName")}
                  value={values.lastName}
                  helperText={errors.lastName ?? ""}
                  FormHelperTextProps={{ style: styles.errorMessages }}
                  name="lastName"
                />
                <TextField
                  disabled={isLoading}
                  label="Email"
                  onChange={handleChange("email")}
                  value={values.email}
                  helperText={errors.email ?? ""}
                  FormHelperTextProps={{ style: styles.errorMessages }}
                  name="email"
                  variant="outlined"
                />
                <Box sx={styles.userPermissons}>Permissions</Box>
                <div role="group" aria-labelledby="checkbox-group">
                  <FormGroup>
                    <Field
                      as={FormControlLabel}
                      control={<Checkbox disabled={isLoading} />}
                      type="checkbox"
                      name="permissions" value="2"
                      label={
                        <StCheckBoxLabel>
                          {"Can invite other Admin"}
                        </StCheckBoxLabel>
                      }
                    />

                    <Field
                      as={FormControlLabel}
                      control={<Checkbox disabled={isLoading} />}
                      type="checkbox"
                      name="permissions" value="4"
                      label={
                        <StCheckBoxLabel>
                          {"Can invite/approve Clients"}
                        </StCheckBoxLabel>
                      }
                    />

                    <Field
                      as={FormControlLabel}
                      control={<Checkbox disabled={isLoading} />}
                      type="checkbox"
                      name="permissions" value="3"
                      label={
                        <StCheckBoxLabel>
                          {"Can add/invite Account Executives"}
                        </StCheckBoxLabel>
                      }
                    />

                    {
                      errors.permissions && (
                        <FormHelperText style={styles.errorMessages} id="filled-weight-helper-text">
                          {errors.permissions}
                        </FormHelperText>
                      )
                    }
                  </FormGroup>
                </div>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: admin ? "space-between" : "end",
                  }}
                >
                  {admin && (
                    <Box onClick={handleRemoveUser} sx={styles.removeUser}>
                      Remove User
                    </Box>
                  )}
                  <Button
                    variant="blueGradient-outlined"
                    sx={styles.submitButton}
                    type="submit"
                    disabled={isSubmitting || !isValid || isLoading}
                  >
                    {admin ? "Save" : "Invite"}
                  </Button>
                </Box>
              </Stack>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
}

export default AdminModalComponent;

AdminModalComponent.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
