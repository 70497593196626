import { createContext, useContext, useState, } from 'react';
import { Notification } from '../components/Notification/Notification';

const NotificationContext = createContext({});

export const NotificationProvider = (props) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotification({ open: false, message: '', type: undefined, handleClose });
  }

  const [notification, setNotification] = useState({
    open: false,
    message: '',
    type: undefined,
    handleClose,
  });

  return (
    <NotificationContext.Provider
      value={{ setNotification }}
    >
      {props.children}
      {notification?.open ? (
        <Notification
          open={notification?.open}
          message={notification.message}
          type={notification.type}
          handleClose={handleClose}
        />
      ) : null}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);