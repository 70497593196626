import { createContext, useContext, useEffect, useState } from "react";
import { deleteToken, getToken, setToken } from "../Helpers/auth";
import userAPI from "../api/modules/users";
import roleAPI from "../api/modules/roles";
import permissionsAPI from "../api/modules/permissions";
import { useDispatch, useSelector } from "react-redux";
import {
  initUser,
  resetUser,
  selectUser,
  setUserRoles,
  setUserPermissions,
} from "../redux/features/user/userSlice";
import MixpanelHelper from "../Helpers/MixpanelHelper";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();
  const userRedux = useSelector(selectUser);
  const onLogin = async (token) => {
    setToken(token);
    await getUser();
    checkRolesAndPermissions();
  };

  const getUser = async () => {
    const { data } = await userAPI.userInfo();
    MixpanelHelper.setUser(data.data);
    dispatch(initUser(data.data));
  };

  const getRoles = async () => {
    try {
      const { data } = await roleAPI.getRoles();
      dispatch(setUserRoles(data));
    } catch (error) {
      console.log({ error });
    }
  };

  const getPermissions = async () => {
    try {
      const { data } = await permissionsAPI.getPermissions();
      dispatch(setUserPermissions(data));
    } catch (error) {
      console.log({ error });
    }
  };

  const checkRolesAndPermissions = async () => {
    if (!userRedux.permissions.length) await getPermissions();

    if (!userRedux.roles.length) await getRoles();
  };

  const onLogout = () => {
    MixpanelHelper.track('Logout');
    deleteToken();
    dispatch(resetUser());
  };

  useEffect(() => {
    const token = getToken();

    if (!token) {
      dispatch(resetUser());
      return;
    } else {
      onLogin(token);
    }
  }, []);
  return (
    <AuthContext.Provider
      value={{
        onLogin,
        onLogout,
        getUser,
        checkRolesAndPermissions,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
