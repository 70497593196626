import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Dialog, IconButton, Stack } from '@mui/material';
import styled from 'styled-components';
import Moment from 'moment';
import { styles } from './styles';
import Icons from '../icons';
import RangeDatePicker from '../RangeDatePicker';
import userAPI from '../../api/modules/users';
import { useNotification } from '../../context/notificationContext';
import EditShadowAccountSelectAE from './EditShadowAccountSelectAE';
import { convertClientPermisionsToNames } from '../../Helpers/clientRolesPermissions';
import { useSelector } from 'react-redux';
import { selectUser } from '../../redux/features/user/userSlice';
import { ConfirmDialog } from '../Dialogs/ConfirmDialog';

const Label = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 35px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const Email = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ color = '#000000' }) => color};
`;

const Container = styled.div`
  padding: 32px 70px;
`;

const ChooseDateText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-top: 20px !important;
`;

const SelectedDatesText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #444444;
`;

const Title = styled.span`
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #b80a26;
  margin-bottom: 24px;
  display: block;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Divider = styled.div`
  border-bottom: 1px solid #BDBDBD;
  margin: 14px 0;
`;

const HeadLabelText = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 143%;
  letter-spacing: 0.15px;
  color: #444444;
`;

const RedText = styled.span`
  color: #B80A26;
`;

const EditShadowAccountExecutive = ({
  clientId,
  shadowInfo,
  open,
  onClose,
  handleOnSubmit
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedDates, setSelectedDates] = useState(null);
  const [shadowAccountsToAdd, setShadowAccountsToAdd] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userRedux = useSelector(selectUser);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({
    fullName: '',
    email: '',
  });

  const { setNotification } = useNotification();

  const handleClose = () => {
    onClose();
  }

  const onChooseDate = (dates) => {
    setSelectedDates(dates);
  }

  const getShadowAccountInfo = async () => {
    try {
      setSelectedDates([Moment(shadowInfo.fromDate).format("MM-DD-YYYY"), Moment(shadowInfo.toDate).format("MM-DD-YYYY")]);

      setSelectedClient({
        fullName: shadowInfo.fullName,
        email: shadowInfo.email,
      });


      setShadowAccountsToAdd([{
        userId: shadowInfo.shadowing.userId,
        fullName: shadowInfo.shadowing.fullName,
        email: shadowInfo.shadowing.email,
        isEdittingUser: false,
        isEdittingAccounts: false,
        accounts: shadowInfo.accountDetails.map(({ accountId, company, permissions }) => {
          const permissionNames = convertClientPermisionsToNames(userRedux.permissions, permissions);

          const account = {
            id: accountId,
            accountId,
            company,
            permissions,
            readOnly: permissionNames.includes("READ_ONLY"),
            createReports: permissionNames.includes("REPORT_CREATION"),
            userManagement: permissionNames.includes("USER_MANAGEMENT"),
          }

          return account;

        }),

      }]);

      setCurrentStep(2);

    } catch (error) {

    } finally {

    }
  }

  const onPrevStep = () => {
    if (currentStep === 1) {
      return handleClose();
    }

    setCurrentStep((prev) => prev - 1);
  }

  const onNextStep = () => {
    setCurrentStep((prev) => prev + 1);
  }

  const onRemoveShadowAccount = () => {
    try {
      setIsConfirmOpen(true);

    } catch (error) {
      console.log({ error });
    } finally {

    }
  }

  const onSubmit = async () => {
    try {
      setIsSubmitting(true);

      const [shadowAccountToAdd] = shadowAccountsToAdd;

      const editShadowAccountPayload = {
        access: shadowAccountToAdd.accounts.map(({ accountId, permissions }) => ({
          accounts: accountId,
          permissions,
          startAt: Moment(selectedDates[0]).format("MM-DD-YYYY"),
          endAt: Moment(selectedDates[1]).format("MM-DD-YYYY"),
        })),
        userId: shadowInfo.shadowing.userId,
        shadowUserId: shadowInfo.shadowUserId,
      }

      const { data } = await userAPI.editShadowAccount(editShadowAccountPayload);

      setNotification({
        message: data.message,
        open: true,
      });

      handleClose();
      handleOnSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const isFormValid = useCallback(() => {
    const hasAccounts = shadowAccountsToAdd.every(sa => sa?.accounts?.length > 0);

    return selectedDates !== null && shadowAccountsToAdd.length > 0 && hasAccounts;

  }, [selectedDates, shadowAccountsToAdd]);

  useEffect(() => {
    getShadowAccountInfo();

    return () => {
      console.log('unmounted');
    }
  }, []);

  const isSelectedDatesValid = () => {
    const valid = selectedDates?.filter(date => date !== null);

    return valid?.length === 2;
  }

  const getMySelection = (step = 1) => {
    if (!isSelectedDatesValid()) return `Select a date range`;

    const [fromDate, toDate] = selectedDates;

    return step === 1
      ? `Your selection: ${Moment(fromDate).format("D MMM, YYYY")} to ${Moment(toDate).format("D MMM, YYYY")}`
      : `From ${Moment(fromDate).format("D MMM, YYYY")} to ${Moment(toDate).format("D MMM, YYYY")}`;
  }

  const handleOnAfterSubmit = async () => {
    handleClose();
    handleOnSubmit();
    setTimeout(() => {
      setIsConfirmOpen(false);
    }, 0);
  };

  const onEditShadowAccount = (shadowAccount) => {
    if (!shadowAccount.userId) return;

    const exists = shadowAccountsToAdd.find(ae => ae.userId === shadowAccount.userId);

    if (!exists) return;

    setShadowAccountsToAdd(prev =>
      prev.map(ae => ae.userId === shadowAccount.userId ? shadowAccount : ae)
    );
  }

  const handleOnConfirm = async () => {
    try {
      setIsSubmitting(true);
      await userAPI.removeShadowAccount({
        shadowingUserId: shadowInfo.shadowing.userId,
        shadowUserId: shadowInfo.shadowUserId,
      });

      setNotification({
        open: true,
        message: (
          <>
            <b>Shadow account</b>
            {" correctly removed."}
          </>
        ),
      });

      handleOnAfterSubmit();
    } catch ({ response }) {
      setNotification({
        open: true,
        message: response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setIsSubmitting(false);
    }
  }

  const handleCancelConfirm = () => {
    setIsConfirmOpen(false);
  }

  const renderSelectedShadowAE = useMemo(() => (
    shadowAccountsToAdd.map((shadow, index) => (
      <div key={`${shadow.id ?? index}`}>
        <EditShadowAccountSelectAE
          clientId={clientId}
          selectedAE={shadow}
          onEditShadowAccount={onEditShadowAccount}
        />
      </div>
    ))
  ), [shadowAccountsToAdd]);

  return (
    <>
      <ConfirmDialog
        isOpen={isConfirmOpen}
        onCancel={handleCancelConfirm}
        onConfirm={handleOnConfirm}
        confirmText={"Delete"}
        isSubmitting={isSubmitting}
        title={"Remove Shadow Account?"}
        text={"This action cannot be undone."}
      />
      <Dialog
        onClose={handleClose}
        hidden={isConfirmOpen}
        open={open}
        fullWidth={true}
        maxWidth="md"
        scroll="body"
      >
        <Container>
          <Title>Shadow Account</Title>

          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Icons.CloseIcon />
          </IconButton>

          <Stack spacing={1.5}>
            <Label>
              <b>{`${selectedClient.fullName}`}</b>
            </Label>
            <Email>Email: <RedText>{selectedClient.email}</RedText></Email>

            {
              currentStep === 1 && (
                <>
                  <ChooseDateText>Choose beginning and end date.</ChooseDateText>
                  <RangeDatePicker
                    onChooseDate={onChooseDate}
                    initialDate={selectedDates}

                  />
                  <SelectedDatesText>
                    {getMySelection()}
                  </SelectedDatesText>
                </>
              )
            }

            {
              currentStep === 2 && (
                <Stack>
                  <SectionContainer>
                    <HeadLabelText>{getMySelection(2)}</HeadLabelText>
                    <IconButton onClick={onPrevStep}>
                      <Icons.EditIcon />
                    </IconButton>
                  </SectionContainer>

                  <Divider />
                  <div>
                    {renderSelectedShadowAE}
                  </div>
                </Stack>
              )
            }

            <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
              <Button
                color="customRed"
                sx={styles.submitButton}
                onClick={onPrevStep}
              >
                {'< Back'}
              </Button>
              <Box sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
                <Button
                  color="customRed"
                  sx={{
                    ...styles.submitButton,
                    marginRight: 2,
                  }}
                  onClick={onRemoveShadowAccount}
                >
                  {'Remove Shadow Account'}
                </Button>

                <Button
                  onClick={currentStep === 1 ? onNextStep : onSubmit}
                  sx={styles.submitButton}
                  variant="blueGradient-outlined"
                  disabled={
                    (currentStep === 2 && !isFormValid()) ||
                    (currentStep === 1 && !isSelectedDatesValid()) ||
                    isSubmitting
                  }
                >
                  {currentStep === 1 ? 'Next' : 'Save'}
                </Button>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Dialog>
    </>
  );
}

export default EditShadowAccountExecutive;

EditShadowAccountExecutive.propType = {
  clientId: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
