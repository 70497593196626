import React from 'react';

const MicroAgeLogoIcon = ({
  width = 71,
  height = 30,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 71 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M40.9279 14.7585L35.3524 20.662L43.8796 29.1891H55.0305L40.9279 14.7585Z" fill="white" />
      <path d="M40.9278 11.4789L35.3523 17.3823L29.4489 11.4789L11.7386 29.1891H0.259766L29.4489 0L35.0244 5.90342L40.9278 0L70.1169 29.1891H58.638L40.9278 11.4789Z" fill="white" />
      <path d="M29.4491 14.7585L35.3525 20.662L26.4974 29.1891H15.3465L29.4491 14.7585Z" fill="white" />
    </svg>

  );
}

export default MicroAgeLogoIcon;