import * as React from "react";
import Box from "@mui/material/Box";
import Header from "../components/HeaderMenu";
import OrderInvoicesDashboardComponent from "../components/OrderInvoicesDashboardComponent";
import { Footer } from "../components/FooterComponent";
import { globalStyles } from "../styles/global";
import usePageTracking from "../hooks/usePageTracking";

const OrderInvoicesDashboard = () => {
  usePageTracking();

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "black",
          color: "#fff",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "100%",
            gridTemplateRows: "auto",
            gridTemplateAreas: `"header"
                                "main"
                                "footer"`,
          }}
        >
          <Header />

          <Box sx={globalStyles.body}>
            <OrderInvoicesDashboardComponent />
          </Box>
          <Box sx={globalStyles.footer}>
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OrderInvoicesDashboard;
