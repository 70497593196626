import React from 'react';

const AddIcon = ({
  width = 20,
  height = 20,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM15 11H11V15H9V11H5V9H9V5H11V9H15V11Z" fill="url(#paint0_linear_7544_34852)" />
      <defs>
        <linearGradient id="paint0_linear_7544_34852" x1="0.869565" y1="4.76048e-08" x2="20" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5058F2" />
          <stop offset="1" stopColor="#44C0B4" />
        </linearGradient>
      </defs>
    </svg>

  );
}

export default AddIcon;
