import { PluginService } from "../pluginService";

const permissionService = PluginService('');

  export default {

   getPermissions() {

      return permissionService({
        method: "GET",
        url: `/permissions`,
      });
    },
  }