import React, { useEffect, useMemo, useState } from 'react';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useSelector } from 'react-redux';
import {
  getSelectedCompany,
  selectUser,
  setClosedInvoices,
  setOpenInvoices,
  setOpenOrders,
} from '../redux/features/user/userSlice';
import {
  formatAccountPayload,
  toCurrency,
  validateNullValue,
} from '../Helpers/formatters';
import orderInvoicesAPI from '../api/modules/orderInvoices';
import { capitalizeWord } from '../Helpers/strings';
import SearchTableComponent from './SearchTableComponent';
import DescriptionDialog from './OrdersInvoices/DescriptionDialog';
import SalesAssociateDialog from './OrdersInvoices/SalesAssociateDialog';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PdfDialog from './OrdersInvoices/PdfDialog';
import { useDispatch } from 'react-redux';
import AutoResizeGrid from './AutoResizeGrid/AutoResizeGrid';
import { dataRemoveRowsWithPhraseFiltered } from '../Helpers/orderInvoiceTable';
import MixpanelHelper from '../Helpers/MixpanelHelper';

const CustomColorDataGrid = muiStyled(DataGrid)(({ theme }) => ({
  fontFamily: 'Montserrat',
  '& .MuiDataGrid-row': {
    '&:hover, &.Mui-hovered': {
      backgroundColor: 'rgba(68, 192, 180, 0.2)',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
    '&.Mui-selected': {
      backgroundColor: 'rgba(239, 180, 71, 0.2)',
      '&:hover, &.Mui-hovered': {
        backgroundColor: 'rgba(239, 180, 71, 0.2)',
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
}));

const Status = styled.span`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: ${({ color }) => color};
`;

const UnderlinedText = styled.span`
  cursor: pointer;
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: ${({ color = '#B80A26' }) => color};
`;

const statusColors = {
  completed: '#4CAF50',
  closed: '#4CAF50',
  processing: '#EFB447',
  shipped: '#44C0B4',
  open: '#5058F2',
  partial: '#B80A26',
  released: '#4CAF50',
  'pending approval': '#EFB447',
  'pending prepayment': '#B80A26',
};

const LoadingContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  padding: 60px 0;
`;

const columnFilters = {
  invoices: {
    age: true,
    amount: false,
    balanceDue: true,
    contact: true,
    description: true,
    invoiceNo: true,
    invoiceStatus: true,
    orderBy: true,
    orderDate: false,
    orderNo: true,
    paidAmount: true,
    poNumber: true,
    recipient: false,
    salesAssociate: true,
    status: true,
    terms: false,
    customer: true,
    soldTo: false,
    invoiceDate: true,
    originalAmount: true,
    daysPassedDue: true,
  },
  invoicesClosed: {
    age: true,
    amount: false,
    balanceDue: false,
    contact: true,
    description: true,
    invoiceNo: true,
    invoiceStatus: true,
    orderBy: true,
    orderDate: false,
    orderNo: true,
    paidAmount: true,
    poNumber: true,
    recipient: false,
    salesAssociate: true,
    status: true,
    terms: false,
    customer: true,
    soldTo: false,
    invoiceDate: true,
    originalAmount: true,
    daysPassedDue: false,
  },
  orders: {
    age: false,
    amount: true,
    balanceDue: false,
    contact: true,
    description: true,
    invoiceNo: false,
    invoiceStatus: false,
    orderBy: false,
    orderDate: true,
    orderNo: true,
    paidAmount: false,
    poNumber: true,
    recipient: false,
    salesAssociate: true,
    status: true,
    terms: true,
    customer: true,
    soldTo: true,
    invoiceDate: false,
    originalAmount: false,
    daysPassedDue: false,
  },
  activity: {
    age: false,
    amount: true,
    balanceDue: false,
    contact: true,
    description: true,
    invoiceNo: false,
    invoiceStatus: false,
    orderBy: false,
    orderDate: true,
    orderNo: true,
    outstanding: false,
    paidAmount: false,
    poNumber: false,
    recipient: false,
    salesAssociate: true,
    status: true,
    terms: true,
    customer: true,
    soldTo: true,
    invoiceDate: false,
    originalAmount: false,
    daysPassedDue: false,
  },
};

const GradientOpendDialogIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>

    <AssignmentIcon
      sx={{ cursor: 'pointer', fill: 'url(#linearColors)' }}
      onClick={onClick}
    />
  </>
);
const GradientSalesAssociateIcon = ({ onClick }) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" gradientTransform="rotate(136.27)">
        <stop offset="2.13%" stopColor="#5058f2" />
        <stop offset="100%" stopColor="#44C0B4" />
      </linearGradient>
    </svg>

    <AccountCircleIcon
      sx={{ cursor: 'pointer', fill: 'url(#linearColors)' }}
      onClick={onClick}
    />
  </>
);

const OrderInvoiceTable = ({
  isSearchOpen,
  selectedFilter,
  setIsSearchOpen,
  orderStatus,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(25);
  const [items, setItems] = useState([]);
  const [itemCount, setItemCount] = useState(0);
  const [dialogData, setDialogData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columnFilters['invoices']
  );
  const [selectedFilterState, setSelectedFilterState] =
    useState(selectedFilter);

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const [currentCompanies, setCurrentCompanies] = useState([]);
  const [currentOrderStatus, setCurrentOrderStatus] = useState('');
  const dispatch = useDispatch();
  const userRedux = useSelector(selectUser);
  const [status, type] = selectedFilterState.split('_');

  const [open, setOpen] = useState(false);
  const [openCA, setOpenCA] = useState(false);
  const [openPDF, setOpenPDF] = useState(false);

  const [cancelToken, setCancelToken] = useState();

  const handleClickOpen = (row) => {
    setDialogData(row);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenClientAssociate = (row) => {
    setDialogData(row);
    setOpenCA(true);
  };

  const handleCloseClientAssociate = () => {
    setOpenCA(false);
  };

  const handleClickOpenPdf = (row) => {
    setDialogData(row);
    setOpenPDF(true);
  };

  const handleClosePdf = () => {
    setOpenPDF(false);
  };

  const columns = [
    {
      field: 'status',
      headerName: 'Status',
      // minWidth: 60,
      // maxWidth: 80,
      // flex: 1,
      renderCell: ({ row }) => (
        <Status color={statusColors[row.status?.toLowerCase()]}>
          {capitalizeWord(validateNullValue(row.status, false))}
        </Status>
      ),
    },
    {
      field: 'orderNo',
      headerName: 'Order Number',
      // minWidth: 125,
      // maxWidth: 140,
      // flex: 1,
      // renderCell: ({ row }) => <UnderlinedText>{row.orderNo}</UnderlinedText>,
      renderCell: ({ row }) => <>{validateNullValue(row.orderNo, false)}</>,
    },
    {
      field: 'invoiceNo',
      headerName: 'Invoice Number',
      // minWidth: 120,
      // maxWidth: 160,
      // flex: 1,
      renderCell: ({ row }) => (
        <UnderlinedText onClick={() => handleClickOpenPdf(row)}>
          {validateNullValue(row.invoiceNo, false)}
        </UnderlinedText>
      ),
    },
    {
      field: 'company',
      headerName: 'Company',
      // minWidth: 350,
      // maxWidth: 420,
      // flex: 1,
      valueGetter: ({ row }) => {
        return validateNullValue(row.customer, false);
      },
    },
    {
      field: 'soldTo',
      headerName: 'Sold To',
      // minWidth: 180,
      // maxWidth: 200,
      // flex: 1,
      valueGetter: ({ row }) => {
        return validateNullValue(row.soldTo, false);
      },
    },
    {
      field: 'recipient',
      headerName: 'Recipient',
      // flex: 1,
      valueGetter: ({ row }) => {
        return validateNullValue(row.recipient, false);
      },
    },
    {
      field: 'orderDate',
      headerName: 'Order Date',
      // minWidth: 120,
      // maxWidth: 150,
      // flex: 1,
      valueGetter: ({ row }) => validateNullValue(row.orderDate, true),
    },
    {
      field: 'daysPassedDue',
      headerName: 'Days Past Due',
      valueGetter: ({ row }) => validateNullValue(row.daysPassedDue, false),
    },
    {
      field: 'invoiceDate',
      headerName: 'Invoice Date',
      // minWidth: 120,
      // maxWidth: 220,
      // flex: 1,
      valueGetter: ({ row }) => validateNullValue(row.invoiceDate, true),
    },
    {
      field: 'amount',
      headerName: 'Amount',
      // minWidth: 160,
      // maxWidth: 180,
      // flex: 1,
      valueGetter: ({ row }) => toCurrency(row.amount),
    },
    {
      field: 'originalAmount',
      headerName: 'Amount',
      // minWidth: 200,
      // maxWidth: 270,
      // flex: 1,
      valueGetter: ({ row }) => toCurrency(row.originalAmount),
    },
    {
      field: 'balanceDue',
      headerName: 'Balance',
      // minWidth: 150,
      // maxWidth: 230,
      // flex: 1,
      valueGetter: ({ row }) =>
        row.balanceDue ? toCurrency(row.balanceDue) : '',
    },
    {
      field: 'terms',
      headerName: 'Terms',
      // minWidth: 120,
      // maxWidth: 135,
      // flex: 1,
      valueGetter: ({ row }) => {
        return validateNullValue(row.terms, false);
      },
    },
    {
      field: 'poNumber',
      headerName: 'PO Number',
      // minWidth: 180,
      // maxWidth: 220,
      // flex: 1,
      valueGetter: ({ row }) => {
        return validateNullValue(row.poNumber, false);
      },
    },
    {
      field: 'salesAssociate',
      headerName: 'Sales Associate',
      // minWidth: 115,
      // maxWidth: 130,
      // flex: 1,
      align: 'center',
      renderCell: ({ row }) => {
        return (
          <GradientSalesAssociateIcon
            onClick={() => {
              handleClickOpenClientAssociate(row);
            }}
          />
        );
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      // minWidth: 80,
      // maxWidth: 120,
      // flex: 1,
      align: 'center',
      renderCell: ({ row }) => {
        return <GradientOpendDialogIcon onClick={() => handleClickOpen(row)} />;
      },
    },
  ];

  const isCompaniesSame = () =>
    currentCompanies.length === selectedCompanyRedux.length &&
    JSON.stringify(currentCompanies.map((company) => company.id).sort()) ===
      JSON.stringify(selectedCompanyRedux.map((company) => company.id).sort())
      ? true
      : false;

  const isOrderStatusSame = currentOrderStatus === orderStatus ? true : false;

  const clearCache = () => {
    dispatch(setOpenOrders([]));
    dispatch(setClosedInvoices([]));
    dispatch(setOpenInvoices([]));
    setCurrentCompanies(selectedCompanyRedux);
    userRedux.openOrders = [];
    userRedux.closedInvoices = [];
    userRedux.openInvoices = [];
  };

  const clearOrderCache = () => {
    dispatch(setOpenOrders([]));
    setCurrentOrderStatus(orderStatus);
    userRedux.openOrders = [];
  };

  const getInvoices = async (
    invoiceStatus,
    selectedCompany = [{ id: -1 }],
    search,
    // offset = 1,
    limit = 400
  ) => {
    try {
      setIsLoading(true);
      const isClosed = invoiceStatus.toLowerCase() === 'closed';

      const invoicesPayload = {
        status: invoiceStatus,
        search,
        // offset,
        limit,
      };

      if (selectedCompany.length >= 1 && selectedCompany[0].id > -1) {
        const companyFilter = formatAccountPayload(selectedCompany);
        Object.assign(invoicesPayload, {
          accountId: companyFilter,
        });
      }

      if (!isCompaniesSame()) {
        clearCache();
      }

      let data = isClosed ? userRedux.closedInvoices : userRedux.openInvoices;

      if (!data.length || search) {
        const newInvoices = await orderInvoicesAPI.getInvoices(invoicesPayload);
        data = dataRemoveRowsWithPhraseFiltered(
          'poNumber',
          newInvoices.data.invoices
        );
        !search &&
          (isClosed
            ? dispatch(setClosedInvoices(data))
            : dispatch(setOpenInvoices(data)));
      }

      const dataPros = data.map((row, counter) => {
        return {
          id: counter,
          ...row,
        };
      });

      setItems(dataPros);
      setItemCount(dataPros.count);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getOrders = async (
    status,
    selectedCompany,
    search,
    // offset = 1,
    limit = 400
  ) => {
    try {
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
      }

      let _cancelToken = axios.CancelToken.source();

      setCancelToken(_cancelToken);

      setIsLoading(true);

      const ordersPayload = {
        status,
        search,
        // offset,
        limit,
      };

      if (
        selectedCompany.length >= 1 &&
        selectedCompany[0].id === -1 &&
        userRedux.role.id !== 1 &&
        userRedux.role.id !== 2
      ) {
        return;
      }
      if (selectedCompany.length >= 1 && selectedCompany[0].id > -1) {
        const companyFilter = formatAccountPayload(selectedCompany);
        Object.assign(ordersPayload, {
          accountId: companyFilter,
        });
      }

      if (!isCompaniesSame()) {
        clearCache();
      }

      if (!isOrderStatusSame) {
        clearOrderCache();
      }

      let data = userRedux.openOrders;

      if (!data.length || search) {
        const newOrders = await orderInvoicesAPI.getOrders(
          ordersPayload,
          _cancelToken.token
        );
        data = dataRemoveRowsWithPhraseFiltered(
          'poNumber',
          newOrders.data.openOrders
        );
        !search && dispatch(setOpenOrders(data));
      }

      const dataPros = data.map((row, counter) => {
        return {
          id: counter,
          ...row,
        };
      });
      setItems(dataPros);
      setItemCount(dataPros.count);
    } catch (error) {
      console.log(error);
      setItems([]);
      setItemCount(0);
    } finally {
      setIsLoading(false);
    }
  };

  const getRecentActivity = async (
    selectedCompany,
    offset = 1,
    limit = 25,
    search
  ) => {
    try {
      setIsLoading(true);

      setItems([]);
      // setItemCount(data.count);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const checkFilter = (orderStatus, filter, company, offset, limit, search) => {
    try {
      const [status, type] = filter.split('_');
      setColumnVisibilityModel(columnFilters[type]);
      switch (type) {
        case 'invoices':
          if (status === 'closed') {
            setColumnVisibilityModel(columnFilters.invoicesClosed);
          }
          getInvoices(status === 'all' ? 'open' : status, company, search);
          // status === "all" ? null : status,
          // company,
          // offset,
          // limit,
          // search
          break;
        case 'orders':
          getOrders(orderStatus, company, search);
          // status === "all" ? null : status,
          // company,
          // offset,
          // limit,
          // search
          break;
        case 'activity':
          getOrders(status === 'all' ? null : status, company, search);
          // getRecentActivity(company, offset, limit, search);
          break;
        default:
          break;
      }
    } catch (error) {
      console.log({ error });
    }
  };

  useEffect(() => {
    checkFilter(
      orderStatus,
      selectedFilter,
      selectedCompanyRedux,
      currentPage,
      pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    orderStatus,
    selectedFilter,
    selectedCompanyRedux,
    currentPage,
    pageSize,
  ]);

  const onPageChange = (page) => {
    setCurrentPage(page + 1);
  };

  const onCloseSearch = () => {
    setIsSearchOpen(false);
    checkFilter(orderStatus, selectedFilter, selectedCompanyRedux, 1, 25);
  };

  const handleSearchUsers = (text, filter) => {
    checkFilter(
      'all',
      filter,
      selectedCompanyRedux,
      currentPage,
      pageSize,
      text
    );

    const company = selectedCompanyRedux?.[0]?.name ?? 'All';
    MixpanelHelper.track('Search', {
      type: type,
      status: status,
      company: company,
      currentPage: currentPage,
      pageSize: pageSize,
      search: text,
    });
  };

  const renderTable = useMemo(
    () => (
      <AutoResizeGrid
        columnVisibilityModel={columnVisibilityModel}
        defaultGroupingExpansionDepth={1}
        // onPageChange={onPageChange}
        // paginationMode="server"
        // page={currentPage - 1}
        sx={{ marginTop: '10px' }}
        rows={items}
        columns={columns}
        pageSize={pageSize}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[25, 50, 100]}
        pagination
        // rowCount={itemCount}
        isRowSelectable={(params) => false}
        autoHeight
        getRowId={(row) =>
          type === 'orders' || type === 'activity' ? row.id : row.id
        }
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      columnVisibilityModel,
      currentPage,
      items,
      pageSize,
      itemCount,
      columns,
      type,
    ]
  );

  return (
    <>
      {isSearchOpen && (
        <SearchTableComponent
          searchPlaceholder="Search..."
          onCloseSearch={onCloseSearch}
          onSubmitSearch={handleSearchUsers}
          defaultValue={`${status}_${selectedFilter.split('_')[1]}`}
          setSelectedFilterState={setSelectedFilterState}
          items={[
            {
              value: `${status}_orders`,
              label: 'Orders',
            },
            {
              value: `${status}_invoices`,
              label: 'Invoices',
            },
          ]}
        />
      )}

      {isLoading ? (
        <LoadingContainer>
          <CircularProgress style={{ color: '#B80A26' }} size={60} />
        </LoadingContainer>
      ) : (
        renderTable
      )}
      <DescriptionDialog
        selectedValue={dialogData}
        open={open}
        onClose={handleClose}
        filter={selectedFilterState}
        setIsLoading={setIsLoading}
        CustomColorDataGrid={CustomColorDataGrid}
        LoadingContainer={LoadingContainer}
      />
      <SalesAssociateDialog
        selectedValue={dialogData}
        open={openCA}
        onClose={handleCloseClientAssociate}
        setIsLoading={setIsLoading}
        CustomColorDataGrid={CustomColorDataGrid}
        LoadingContainer={LoadingContainer}
      />
      <PdfDialog
        selectedValue={dialogData}
        open={openPDF}
        onClose={handleClosePdf}
        LoadingContainer={LoadingContainer}
        selectedFilter={selectedFilter}
      />
    </>
  );
};

export default OrderInvoiceTable;
