import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Stack } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import authAPI from '../api/modules/authentication';
import { passwordRegex } from '../Helpers/password';
import { styles } from './SignIn/styles';
import { useNotification } from '../context/notificationContext';
import { useAuth } from '../context/authContext';
import MixpanelHelper from '../Helpers/MixpanelHelper';

export const SetPasswordComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [searchParams] = useSearchParams();
  const passwordToken = searchParams.get('token');
  const { setNotification } = useNotification();

  const navigate = useNavigate();
  const { onLogin } = useAuth();

  useEffect(() => {
    if (!passwordToken) navigate('/', { replace: true });
  }, [searchParams, navigate]);

  const validationSchema = Yup.object({
    password: Yup.string()
      .required('Password is required')
      .matches(passwordRegex, 'Invalid password'),
    confirmPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const onSubmit = async ({ password }, { setSubmitting }) => {
    try {
      const response = await authAPI.setPassword({
        password,
        token: passwordToken,
      });

      const token = response?.data?.data?.access_token;

      if (token) {
        setNotification({
          message: 'Password has been set',
          open: true,
        });

        await onLogin(token);
        MixpanelHelper.track('login');
        navigate('/orders', { replace: true });
      }
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box sx={styles.formContainer}>
      <Stack spacing={2}>
        <div style={styles.signInTypo}>{'Set Your Password?'}</div>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {({ errors, isSubmitting, isValid, values, handleChange }) => (
            <>
              <Form style={styles.signInForm}>
                <FormControl sx={{}} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    name="password"
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    onChange={handleChange('password')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                  {errors.password && (
                    <FormHelperText
                      style={styles.errorMessages}
                      id="filled-weight-helper-text"
                    >
                      {errors.password}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControl sx={{ mt: 3 }} variant="outlined">
                  <InputLabel htmlFor="confirm-password">
                    Confirm Password
                  </InputLabel>
                  <OutlinedInput
                    name="confirmPassword"
                    id="confirm-password"
                    type={showConfirmPassword ? 'text' : 'password'}
                    onChange={handleChange('confirmPassword')}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confirmPassword visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm Password"
                  />
                  {errors.confirmPassword && (
                    <FormHelperText
                      style={styles.errorMessages}
                      id="filled-weight-helper-text"
                    >
                      {errors.confirmPassword}
                    </FormHelperText>
                  )}
                  <FormHelperText
                    style={{ fontWeight: 'bold', marginLeft: 9, marginTop: 3 }}
                    id="filled-weight-helper-text"
                  >
                    8 characters, 1 capital, 1 lowercase, 1 number, 1 special
                    character
                  </FormHelperText>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
                  <Button
                    type="submit"
                    variant="blueGradient-outlined"
                    sx={styles.submitButton}
                    disabled={
                      isSubmitting ||
                      !isValid ||
                      !values.password ||
                      !values.confirmPassword
                    }
                  >
                    Submit
                  </Button>
                </Box>
              </Form>
            </>
          )}
        </Formik>
      </Stack>
    </Box>
  );
};
