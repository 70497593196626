
export const clientPermissions = {
  clientOwner: [
    {
      description: 'User Management',
      defaultChecked: true,
      disabled: true,
      value: 'USER_MANAGEMENT',
    },
    {
      description: 'Report creation and scheduling',
      defaultChecked: true,
      disabled: false,
      value: 'REPORT_CREATION',
    },
    {
      defaultChecked: false,
      description: 'Read Ony',
      disabled: true,
      value: 'READ_ONLY',
    }
  ],
  clientAdminUser: [
    {
      description: 'User Management',
      disabled: false,
      defaultChecked: true,
      value: 'USER_MANAGEMENT',
    },
    {
      description: 'Report creation and scheduling',
      disabled: false,
      defaultChecked: true,
      value: 'REPORT_CREATION',
    },
    {
      description: 'Read Ony',
      disabled: true,
      value: 'READ_ONLY',
    }
  ],
  clientStandardUser: [
    {
      description: 'User Management',
      disabled: true,
      defaultChecked: false,
      value: 'USER_MANAGEMENT',
    },
    {
      description: 'Report creation and scheduling',
      disabled: false,
      defaultChecked: false,
      value: 'REPORT_CREATION',
    },
    {
      description: 'Read Ony',
      disabled: false,
      defaultChecked: true,
      value: 'READ_ONLY',
    }
  ],
}

export const clientRoles = {
  clientAdminUser: {
    default: 'CLIENT_ADMIN',
    roles: [
      {
        description: 'Admin User',
        disabled: false,
        value: 'CLIENT_ADMIN',
      },
      {
        value: 'CLIENT_STANDARD_USER',
        description: 'Standard User',
        disabled: true,
      }
    ]
  },
  clientUser: {
    default: 'CLIENT_STANDARD_USER',
    roles: [
      {
        description: 'Admin User',
        disabled: false,
        value: 'CLIENT_ADMIN',
      },
      {
        description: 'Standard User',
        disabled: false,
        value: 'CLIENT_STANDARD_USER',
      }
    ]
  },
}

export const convertClientRoleToId = (meRoles, clRole) => {
  const role = meRoles.find((role) => role.name === clRole);

  if (!role)
    throw {
      response: {
        data: {
          message: `Role ${clRole} does not exist`,
      },
    },
  };

  return role.id;
}


export const convertClientRolesToIds = (meRoles, clRoles) =>
  meRoles
    .filter((mePermission) => clRoles.includes(mePermission.name))
    .map((clPermission) => clPermission.id);

export const convertClientPermissionToId = (mePermission, clPermission) => {
  const permission = mePermission.find((mePermission) => mePermission.name === clPermission);

  if (!permission)
    throw {
      response: {
        data: {
          message: `Role ${clPermission} does not exist`,
      },
    },
  };

  return permission.id;
}

export const convertClientPermissionsToIds = (mePermissions, clPermissions) =>
  mePermissions
    .filter((mePermission) => clPermissions.includes(mePermission.name))
    .map((clPermission) => clPermission.id);

export const convertClientPermisionsToNames = (mePermissions, clPermissions) =>
  mePermissions
  .filter((mePermission) => clPermissions.includes(mePermission.id))
  .map((clPermission) => clPermission.name);