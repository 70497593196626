import React from 'react';
import { InputAdornment, TextField } from '@mui/material';
import { styled as muiStyled } from "@mui/material/styles";
import { CloseOutlined, SearchOutlined } from '@mui/icons-material';

const Input = muiStyled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root': {
    padding: '0 16px',
  },
  '& .MuiInput-input': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',

    '&::placeholder': {
      color: '#444444',
      opacity: 0.8,
      fontFamily: 'OpenSans',
      fontStyle: 'italic',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
    },
  },
  backgroundColor: '#EEEEEE',
  paddingBottom: '10px',
  paddingTop: '10px',
  borderRadius: '4px',
  border: 'none',
}));

export const SearchInputField = ({
  placeholder = 'Search',
  showClose = false,
  value,
  onFocus,
  onChange,
  onSearchClose
}) => {
  return (
    <Input
      id="input-with-icon-textfield"
      placeholder={placeholder}
      fullWidth
      value={value}
      onChange={onChange}
      onFocus={onFocus}
      autoComplete="off"
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {
              (value ?? '').trim() === '' || !showClose
                ? <SearchOutlined />
                : <CloseOutlined style={{ cursor: 'pointer' }} onClick={onSearchClose} />
            }
          </InputAdornment>
        ),
      }}
      variant="standard"
    />
  )
}
