// PrivateRoute.tsx
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { getToken } from '../Helpers/auth';
import { selectUser } from '../redux/features/user/userSlice';

const PrivateRoute = ({ children, roles }) => {
  const userRedux = useSelector(selectUser);
  const userHasRequiredRole = userRedux && roles.includes(userRedux.role?.id) ? true : false;
  const token = getToken();

  if (userRedux.id === null || !token) {
    return <Navigate to={"/"} replace />;
  }

  //TODO: implement access denied page
  // if (token && !userHasRequiredRole) {
  //   return <div>Access denied</div>
  // }

  return children;
};

export default PrivateRoute;