import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  IconButton,
  Checkbox,
  Grid,
  CircularProgress,
  Stack,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";
import { SearchResultItem } from "./Search/SearchResult";
import { SearchResultNotFound } from "./Search/SearchResultNotFound";
import { SearchInputField } from "./SearchInputField";
import Icons from "./icons";
import accountAPI from '../api/modules/accounts';
import { convertClientPermisionsToNames } from "../Helpers/clientRolesPermissions";
import { selectUser } from "../redux/features/user/userSlice";
import { useSelector } from "react-redux";
import { CheckCircle } from "@mui/icons-material";


const CustomColorDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

const ResultsContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  overflow: auto;
  height: 245px;
  padding: 0;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0 40px 0;
`;

const useOutsideClick = (ref, setIsAccountListVisible) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsAccountListVisible(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const SearchAccount = ({ accountExecutiveId, accounts, onRowsChange }) => {
  const [isSearching, setIsSearching] = useState(false);
  const [assignedAccounts, setAssignedAccounts] = useState([]);
  const [isAccountListVisible, setIsAccountListVisible] = useState(false);
  const [lastSearch, setLastSearch] = useState('');
  const [textToSearch, setTextToSearch] = useState('');
  const [tableRows, setTableRows] = useState(accounts);
  const [pageSize, setPageSize] = useState(25);
  const [isLoading, setIsLoading] = useState(false);

  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, setIsAccountListVisible);

  const userRedux = useSelector(selectUser);

  const getUserAccounts = async (text) => {

    try {
      
      const { data } = await accountAPI.getUserAccounts({
        search: text,
        userId: accountExecutiveId,
      });

      const filteredCompanies = data.data.companyInfo.filter(ae => ae.permissions.length > 0);

      const _assignedAccounts = filteredCompanies.map((account) => {
        let readOnly = false;
        let createReports = false;
        let userManagement = false;

        const permissionNames = convertClientPermisionsToNames(userRedux.permissions, account.permissions);

        permissionNames.forEach((permissionName) => {

          if (permissionName === 'REPORT_CREATION') {
            createReports = true;
          }

          if (permissionName === 'USER_MANAGEMENT') {
            userManagement = true;
          }

          if (permissionName === 'READ_ONLY') {
            readOnly = true;
          }
        });

        return {
          ...account,
          readOnly,
          createReports,
          userManagement,
        }
      });

      return _assignedAccounts;

    } catch (error) {
      console.log({ error });
    } finally {
    }
  }

  const onLoad = async () => { 
    try {
      setIsLoading(true);
      if (!tableRows.length) {
        const _assignedAccounts = await getUserAccounts();
        setTableRows(_assignedAccounts);
      }
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  }

  const onSearchHandler = async (text) => {
    try {
      setIsSearching(true);
      const _assignedAccounts = await getUserAccounts(text);
      setLastSearch(text);
      setIsAccountListVisible(true);
      setAssignedAccounts(_assignedAccounts);

    } catch (error) {
      console.log({ error });

    } finally {
      setIsSearching(false);
    }
  }

  useEffect(() => {
    const delayFn = setTimeout(() => {
      if (textToSearch.trim().length >= 4) {
        onSearchHandler(textToSearch.trim());
      } else {
        setIsSearching(false);
      }
    }, 1000);

    return () => clearTimeout(delayFn);
  }, [textToSearch]);

  useEffect(() => {
    if (!accountExecutiveId) {
      setTableRows([]);
      setAssignedAccounts([]);
      setTextToSearch('');
      setLastSearch('');
    }
    onLoad();
  }, [accountExecutiveId]);

  const renderPermissionCheckBox = ({ value }) => {
    if (!value) return <></>;

    return (
      <Checkbox
        checked={value}
        disabled={true}
        checkedIcon={<CheckCircle />}
        sx={{
          color: "#070505",
          "&.Mui-checked": {
            color: "#44C0B4",
            borderRadius: "50%",
          },
        }}
      />
    );
  }

  const columns = [
    { field: "company", headerName: "Company", minWidth: 195, flex: 1 },
    {
      field: "createReports",
      headerName: "Create Reports",
      minWidth: 111,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "userManagement",
      headerName: "User Management",
      minWidth: 125,
      flex: 1,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "readOnly",
      headerName: "Read Only",
      minWidth: 125,
      renderCell: renderPermissionCheckBox,
      type: "boolean",
      editable: false,
    },
    {
      field: "actions",
      headerName: "Action",
      minWidth: 125,
      renderCell: ({ row }) => (
        <IconButton onClick={() => onRemoveAccountExecutive(row.id)}>
          <Icons.RemoveIcon />
        </IconButton>
      ),
      type: "boolean",
      editable: false,
    },
  ];

  const onRemoveAccountExecutive = (id) => {
    setTableRows((prev) => prev.filter((row) => row.id !== id));
  }

  const onAddAccountExecutive = (id) => {
    const accountToAdd = assignedAccounts.find((ae) => ae.id === id);

    const exists = tableRows.find((row) => row.id === id);

    if (!exists) {
      setTableRows((prev) => [...prev, {
        ...accountToAdd,
      }]);
    } else {
      setTableRows((prev) => prev.filter((row) => row.id !== id));
    }
  }

  const renderAccountsTable = useMemo(() => (
    <CustomColorDataGrid
      disableSelectionOnClick
      sx={{ marginTop: "10px", fontFamily: "Montserrat" }}
      autoHeight
      rows={tableRows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[25, 50, 100]}
      pagination
    />
  ), [pageSize, tableRows]);

  const renderAccountExecutiveList = useMemo(() => (
    assignedAccounts.map((company) => (
      <SearchResultItem
        key={company.id}
        onClick={onAddAccountExecutive}
        title={`${company.company}`}
        subtitle=""
        isSelected={tableRows.find((row) => row.id === company.id)}
        id={company.id}
      />
    ))
  ), [tableRows, assignedAccounts]);

  useEffect(() => {
    onRowsChange(tableRows);
  }, [tableRows]);

  return (
    <Stack sx={{ width: '100%' }}>
      <SearchInputField
        placeholder="Search Account to Add or Remove"
        onChange={({ target }) => setTextToSearch(target.value)}
        value={textToSearch}
        showClose={true}
        onFocus={() => {
          if (assignedAccounts.length) setIsAccountListVisible(true);
        }}
        onSearchClose={() => {
          setTextToSearch('');
          setIsAccountListVisible(false);
          setAssignedAccounts([]);
        }}
      />

      {
        isAccountListVisible && (
          <div style={{ position: 'relative' }}>
            <ResultsContainer ref={wrapperRef}>
              {
                isSearching ? (
                  <LoadingContainer>
                    <CircularProgress style={{ color: '#B80A26' }} />
                  </LoadingContainer>
                ) : (
                  assignedAccounts.length > 0 ? (
                    <>
                      {renderAccountExecutiveList}
                    </>

                  ) : !isSearching && (
                    <SearchResultNotFound title={lastSearch} />
                  )
                )
              }
            </ResultsContainer>
          </div>
        )
      }

      <Grid item xs={12}>
        { isLoading ? (
            <LoadingContainer>
               <CircularProgress style={{ color: '#B80A26' }} />
            </LoadingContainer>
        ) : renderAccountsTable}
      </Grid>
    </Stack>
  );
}

export default SearchAccount;
