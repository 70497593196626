import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import styled from 'styled-components';

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-row": {
    "&:hover, &.Mui-hovered": {
      backgroundColor: "rgba(68, 192, 180, 0.2)",
      "@media (hover: none)": {
        backgroundColor: "transparent",
      },
    },
    "&.Mui-selected": {
      backgroundColor: "rgba(239, 180, 71, 0.2)",
      "&:hover, &.Mui-hovered": {
        backgroundColor: "rgba(239, 180, 71, 0.2)",
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
  },
}));

export const CustomColorDataGrid = ({
  tableRows,
  columns,
  pageSize,
  rowsPerPageOptions = [25, 50, 100],
  setPageSize
}) => {
  return (
    <CustomDataGrid
      disableSelectionOnClick
      sx={{ marginTop: "10px" }}
      autoHeight
      rows={tableRows}
      columns={columns}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      pagination
    />
  )
}
