import React from "react";

const ViewColumnIcon = ({
  fillColor = "#FFFFFF",
  width = 23,
  height = 18,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 23 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.3335 17.9993H15.0002V0.666016H8.3335V17.9993ZM0.333496 17.9993H7.00016V0.666016H0.333496V17.9993ZM16.3335 0.666016V17.9993H23.0002V0.666016H16.3335Z" fill={fillColor} />
    </svg>

  );
};

export default ViewColumnIcon;
