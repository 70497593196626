export const styles = {
  errorMessages: {
    color: '#B80A26'
  },
  signInForm: {
    flexDirection: "column",
    display: "flex",
  },
  form: {
    flexDirection: 'column',
    display: 'flex',
  },
  redText: {
    width: "186px",
    height: "42px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    display: "flex",
    alignItems: "center",
    color: "#B80A26",
  },
  navLink: {
    textDecoration: "none",
  },
  submitButton: {
    borderRadius: "0px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSze: "14px",
    lineHeight: "26px",
    textTransform: "unset !important",
    display: "flex",
    alignItems: "center",
  },
  formContainer: {
    marginTop: "13%",
    width: "65%",
    marginLeft: "17%",
    fontFamily: "Montserrat",
    fontSize: "14px",
  },
  signInTypo: {
    fontFamily: "OpenSans",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "28px",
    lineHeight: "123.5%",
    color: "#B80A26",
    marginBottom: "15px",
  },
  dotted: {
    border: "1px solid #E0E0E0",
    marginTop: "40px",
  },
  textTypography:{
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 400,
    fontSze: "14px",
    color: "#000000"
  },
  textUnset: {
    textTransform: 'unset !important'
  },
  backNextButtons: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
  }
};
