import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: null,
  email: "",
  firstName: "",
  lastName: "",
  fullName: "",
  status: "",
  companyRole: null,
  lastLogin: "",
  permissions: [],
  role: "",
  roles: [],
  openInvoices: [],
  closedInvoices: [],
  openOrders: [],
  reportConfig: { type: null, columns: [], options: {}, accounts: [] },
  reportPayload: {},
  selectedCompany: [{ name: "All Companies Selected", id: -1, email: "" }],
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    initUser: (state, action) => {
      state.id = action.payload.id;
      state.email = action.payload.email;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.fullName = action.payload.fullName;
      state.status = action.payload.status;
      state.companyRole = action.payload.companyRole;
      state.lastLogin = action.payload.lastLogin;
      state.role = action.payload.role;
    },
    resetUser: (state, action) => {
      state.id = null;
      state.email = "";
      state.firstName = "";
      state.lastName = "";
      state.fullName = "";
      state.status = "";
      state.companyRole = null;
      state.lastLogin = "";
      state.permissions = [];
      state.roles = [];
      state.openInvoices = [];
      state.closedInvoices = [];
      state.openOrders = [];
      state.reportConfig = { type: null, columns: [], options: {}, accounts: [] };
      state.reportPayload = {};
      state.selectedCompany = [{
        name: "All Companies Selected",
        id: -1,
        email: "",
      }];
    },
    setOpenInvoices: (state, action) => {
      state.openInvoices = action.payload;
    },
    setClosedInvoices: (state, action) => {
      state.closedInvoices = action.payload;
    },
    setOpenOrders: (state, action) => {
      state.openOrders = action.payload;
    },
    setUserPermissions: (state, action) => {
      state.permissions = action.payload.data;
    },
    setUserRoles: (state, action) => {
      state.roles = action.payload.data;
    },
    setReportConfig: (state, action) => {
      state.reportConfig = action.payload;
    },
    setReportPayload: (state, action) => {
      state.reportPayload = action.payload;
    },
    resetSelectedCompany: (state) => {
      state.selectedCompany = [{
        name: "All Companies Selected",
        id: -1,
        email: "",
      }];
    },
    setSelectedCompany: (state, action) => {
      const companies = [...state.selectedCompany, action.payload]
      state.selectedCompany = companies.filter(payload => payload.id !== -1);
    },
    removeSelectedCompany: (state, action) => {
      state.selectedCompany = state.selectedCompany.filter(payload => payload.id !== action.payload);
    }
  },
});

export const {
  initUser,
  resetUser,
  setUserRoles,
  setOpenInvoices,
  setClosedInvoices,
  setOpenOrders,
  setReportConfig,
  setReportPayload,
  setUserPermissions,
  resetSelectedCompany,
  removeSelectedCompany,
  setSelectedCompany,
} = userSlice.actions;

export const selectUser = (state) => state;
export const getSelectedCompany = (state) => state.selectedCompany;
export default userSlice.reducer;
