import React, { useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { NavLink } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import authAPI from '../../api/modules/authentication';
import { styles } from './styles';
import { useNotification } from '../../context/notificationContext';

export const SignInForm = ({ handleOnSubmit }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { setNotification } = useNotification();

  const validationSchemaLogin = Yup.object({
    email: Yup.string().email('Email is invalid').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => event.preventDefault();

  const onSubmit = async ({ email, password }, { setSubmitting }) => {
    try {
      const response = await authAPI.login({ email, password });

      if (response.data) {
        handleOnSubmit(email);
      }
    } catch ({ response }) {
      setNotification({
        open: true,
        message: response.data.message,
        type: 'error',
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={{ email: '', password: '' }}
      onSubmit={onSubmit}
      validationSchema={validationSchemaLogin}
    >
      {({ errors, isSubmitting, isValid, values, handleChange }) => (
        <>
          <Form style={styles.signInForm}>
            <FormControl sx={{ mb: 2 }} variant="outlined">
              <TextField
                label="Email"
                variant="outlined"
                name="email"
                id="email"
                type="email"
                onChange={handleChange('email')}
              />
              {errors.email && (
                <FormHelperText
                  style={styles.errorMessages}
                  id="filled-weight-helper-text"
                >
                  {errors.email}
                </FormHelperText>
              )}
            </FormControl>

            <FormControl sx={{}} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                Password
              </InputLabel>
              <OutlinedInput
                name="password"
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                onChange={handleChange('password')}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
              {errors.password && (
                <FormHelperText
                  style={styles.errorMessages}
                  id="filled-weight-helper-text"
                >
                  {errors.password}
                </FormHelperText>
              )}
            </FormControl>
            <FormHelperText
              style={{ fontWeight: 'bold', marginLeft: 9, marginTop: 3 }}
              id="filled-weight-helper-text"
            >
              8 characters, 1 capital, 1 lowercase, 1 number, 1 special
              character
            </FormHelperText>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
            >
              <NavLink style={styles.navLink} to={'/forgotpwd'}>
                <Typography sx={styles.redText}>
                  {'Forgot password?'}
                </Typography>
              </NavLink>
              <Button
                type="submit"
                variant="blueGradient-outlined"
                sx={styles.submitButton}
                disabled={
                  isSubmitting || !isValid || !values.email || !values.password
                }
              >
                Login
              </Button>
            </Box>
          </Form>
        </>
      )}
    </Formik>
  );
};
