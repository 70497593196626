import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  DialogTitle,
  Dialog,
  IconButton,
} from "@mui/material";
import orderInvoicesAPI from "../../api/modules/orderInvoices";
import { validateNullValue } from "../../Helpers/formatters";
import CloseIcon from "../icons/CloseIcon";
import styled from "styled-components";
import { Link } from "react-router-dom";

const UnderlinedText = styled.span`
  cursor: pointer;
  font-family: "OpenSans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-decoration-line: underline;
  color: ${({ color = "#B80A26" }) => color};
`;

const handleMailTo = (e, email) => {
  if (email) {
    window.location.href = `mailto:${email}`;
    e.preventDefault();
  }
}

const columnsDialog = [
  { headerName: "Name",
    field: "name",
    minWidth: 130,
    flex: 0.75,
    valueGetter: ({ row }) => {
      return validateNullValue(row.name, false);
    },
  },
  { headerName: "Email",
    field: "email",
    flex: 1,
    minWidth: 200,
    renderCell: ({ row }) => 
    <Link to='#' onClick={(e) => {handleMailTo(e, row.email)}}>
      <UnderlinedText>
        {validateNullValue(row.email, false)}
      </UnderlinedText>
    </Link>,
  },
  { headerName:
    "Phone Number",
    field: "phoneNo",
    flex: 0.5,
    valueGetter: ({ row }) => {
      return validateNullValue(row.phoneNo, false);
    },
  },
  {
    headerName: "Job Title",
    field: "jobTitle",
    flex: 0.5,
    valueGetter: ({ row }) => {
      return "Account Executive";
    },
  },
];

const SalesAssociateDialog = (props) => {
  const {
    onClose,
    selectedValue,
    open,
    CustomColorDataGrid,
    LoadingContainer,
  } = props;
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const titleLabel = "Sales Associate";
  const salesAssociate = selectedValue.salesAssociate;

  const getSalesPerson = async (documentNo) => {
    try {
      setIsLoading(true);

      const { data } = await orderInvoicesAPI.getSalesPersonDetails(
        salesAssociate
      );
      setRows([data.salesPersonDetails]);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (salesAssociate && open) {
      getSalesPerson(salesAssociate);
    }
  }, [salesAssociate, open]);

  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth="md">
      <DialogTitle>
        {titleLabel}
        {onClose ? (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          ) : null}
        </DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem onClick={() => {}}>
          {isLoading ? (
            <LoadingContainer style={{ marginLeft: "47%" }}>
              <CircularProgress style={{ color: "#B80A26" }} size={60} />
            </LoadingContainer>
          ) : (
            <CustomColorDataGrid
              defaultGroupingExpansionDepth={1}
              sx={{ marginTop: "10px", width: "500px", height: "250px" }}
              rows={rows}
              columns={columnsDialog}
              getRowHeight={() => "auto"}
              getRowId={(row) => row.salesPersonCode}
            />
          )}
        </ListItem>
      </List>
    </Dialog>
  );
};

export default SalesAssociateDialog;
