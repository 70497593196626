import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import ReportsTable from './Reports/ReportsTable';
import styled from 'styled-components';
import Paper from '@mui/material/Paper';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import { BodyContainer, BodyGridWrapper } from './Shared/BodyContainer/Index';

const styles = {
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#B80A26 !important',
  display: 'flex',
  gap: '5px',
  width: 'auto',
  height: 'auto',
  padding: '8px 22px',
  borderRadius: '0 !important',
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  fontSize: 16,
  alignItems: 'center',
  cursor: 'pointer',
}));

const Link = styled(NavLink)`
  text-decoration: none;
`;

const ReportsDashboardComponent = () => {
  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ minHeight: 'inherit', display: 'inherit' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopContainer>
              <TitleHeaderRow>
                <TitleHeaderCol>
                  <Box
                    sx={{
                      fontFamily: 'OpenSans',
                      fontWeight: 400,
                      fontSize: '14px',
                      textTransform: 'uppercase',
                      fontStyle: 'normal',
                      letterSpacing: '0.15px',
                      lineHeight: '20px',
                    }}
                  >
                    End-to-End Services
                  </Box>

                  <Box
                    sx={{
                      height: '65px',
                      fontFamily: 'OpenSans',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '48px',
                      lineHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Reports
                  </Box>
                </TitleHeaderCol>

                <TitleHeaderCol>
                  <Link to={'/reports'}>
                    <Item>Back to Reports</Item>
                  </Link>
                </TitleHeaderCol>
              </TitleHeaderRow>
            </TopContainer>
          </Grid>

          <BodyGridWrapper item xs={12} md={12}>
            <BodyContainer>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              ></Box>

              <ReportsTable />
            </BodyContainer>
          </BodyGridWrapper>
        </Grid>
      </Box>
    </>
  );
};

export default ReportsDashboardComponent;
