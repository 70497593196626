import React from 'react';
import { NavLink } from 'react-router-dom';
import { Box, Grid, Button } from '@mui/material';
import TopBackground from '../images/header_background.png';
import styled from 'styled-components';
import { useState } from 'react';
import ReportsHomePageTable from './Reports/ReportsHomePageTable';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import { BodyContainer, BodyGridWrapper } from './Shared/BodyContainer/Index';

const styles = {
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const HederWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

const BuildReportLink = styled(NavLink)`
  width: 147px;
  height: 42px;
  background: linear-gradient(136.27deg, #5058f2 2.13%, #44c0b4 100%);
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none;
  margin-left: 24px;
`;
const PresetReportsButton = styled(Box)`
  width: 147px;
  height: 42px;
  background: #44c0b4;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-decoration: none;
  cursor: pointer;
`;

const ReportsHomePageComponent = () => {
  const [selectedFilter, setSelectedFilter] = useState('recent_reports');

  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ minHeight: 'inherit', display: 'inherit' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopContainer>
              <TitleHeaderRow>
                <TitleHeaderCol>
                  <Box
                    sx={{
                      fontFamily: 'OpenSans',
                      fontWeight: 400,
                      fontSize: '14px',
                      textTransform: 'uppercase',
                      fontStyle: 'normal',
                      letterSpacing: '0.15px',
                      lineHeight: '20px',
                    }}
                  >
                    End-to-End Services
                  </Box>

                  <Box
                    sx={{
                      height: '65px',
                      fontFamily: 'OpenSans',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '48px',
                      lineHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Reports
                  </Box>
                </TitleHeaderCol>
                <TitleHeaderCol>
                  <TitleHeaderRow>
                    {selectedFilter !== 'preset_reports' && (
                      <PresetReportsButton
                        onClick={() => setSelectedFilter('preset_reports')}
                      >
                        Preset Reports
                      </PresetReportsButton>
                    )}

                    <BuildReportLink to={'/build-report/new'}>
                      Build Report
                    </BuildReportLink>
                  </TitleHeaderRow>
                </TitleHeaderCol>
              </TitleHeaderRow>
            </TopContainer>
          </Grid>

          <BodyGridWrapper item xs={12} md={12}>
            <BodyContainer>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              ></Box>
              <ReportsHomePageTable
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
            </BodyContainer>
          </BodyGridWrapper>
        </Grid>
      </Box>
    </>
  );
};

export default ReportsHomePageComponent;
