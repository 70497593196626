import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import MixpanelHelper from '../Helpers/MixpanelHelper';

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    MixpanelHelper.trackPageView(location.pathname);
  }, [location]);
};

export default usePageTracking;
