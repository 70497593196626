import { PluginService } from "../pluginService";

const userService = PluginService("");

export default {
  userInfo() {
    return userService({
      method: "GET",
      url: `/me`,
    });
  },

  getUsers(params) {
    return userService({
      method: "GET",
      url: `/users`,
      params,
    });
  },

  updateUser(params, data) {
    return userService({
      method: "PUT",
      url: `/users/${params.id}`,
      data,
    });
  },

  deleteUser(params) {
    return userService({
      method: "DELETE",
      url: `/users/${params.id}`,
    });
  },

  editAdmin(params, data) {
    return userService({
      method: "PUT",
      url: `/edit-admin/${params.id}`,
      data,
    });
  },

  getUserInfo(id) {
    return userService({
      method: "GET",
      url: `/users/${id}`,
    });
  },

  editClient(params, data) {
    return userService({
      method: "PUT",
      url: `/edit-client/${params.id}`,
      data,
    });
  },

  editExecutive(params, data) {
    return userService({
      method: "PUT",
      url: `/edit-executive/${params.id}`,
      data,
    });
  },
  setShadowUser(data) {
    return userService({
      method: "POST",
      url: `/set-shadow-user`,
      data,
    });
  },
  getShadowAccount(params) {
    return userService({
      method: "GET",
      url: `/get-shadow-accounts`,
      params,
    });
  },
  editShadowAccount(data) {
    return userService({
      method: "POST",
      url: `/edit-shadow-account`,
      data,
    });
  },
  removeShadowAccount(data) {
    return userService({
      method: "DELETE",
      url: `/remove-shadow-account`,
      data,
    });
  },
};
