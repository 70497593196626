import React from 'react';

const StarIcon = ({
  width = 21,
  height = 19,
  color = '#EFB447'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 15.27L16.18 19L14.54 11.97L20 7.24L12.81 6.63L10 0L7.19 6.63L0 7.24L5.46 11.97L3.82 19L10 15.27Z" fill={color} />
    </svg>

  );
}

export default StarIcon;
