import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Box, Grid, Button, CircularProgress, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import styledC from 'styled-components';
import usersAPI from '../api/modules/users';
import { useSelector } from 'react-redux';
import Icons from './icons';
import userAPI from '../api/modules/users';
import {
  getSelectedCompany,
  selectUser,
} from '../redux/features/user/userSlice';
import SearchTableComponent from './SearchTableComponent';
import EditShadowAccountExecutive from './AccountExecutiveInvite/EditShadowAccountExecutive';
import { ConfirmDialog } from './Dialogs/ConfirmDialog';
import { useNotification } from '../context/notificationContext';
import ShadowAccountExecutive from './AccountExecutiveInvite/ShadowAccountExecutive';
import { formatAccountPayload } from '../Helpers/formatters';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import { BodyContainer, BodyGridWrapper } from './Shared/BodyContainer/Index';
import AutoResizeGrid from './AutoResizeGrid/AutoResizeGrid';

const BlueAquaButtonFilled = styled(Button)({
  padding: '8px 22px',
  gap: '8px',
  width: 'auto',
  height: '42px',
  borderRadius: 0,
  textTransform: 'none',
  fontFamily: 'Montserrat',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '26px',
  fontSize: 16,
});

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    height: '400px',
  },
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const ClientName = styledC.div`
  font-family: 'OpenSans';
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 65px;
  display: flex;
  align-items: center;
  color: #FFFFFF;
`;

function ShadowAccountsDashboardComponent() {
  const userRedux = useSelector(selectUser);
  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [usersCount, setUsersCount] = useState(0);
  const [textToSearch, setTextToSearch] = useState('');

  const [editShadowAccountExecutiveModal, setEditShadowAccountExecutiveModal] =
    useState({
      isOpen: false,
      selectedClient: null,
    });

  const [removeShadowAccountModal, setRemoveShadowAccountModal] = useState({
    isOpen: false,
    shadowUserId: null,
    shadowingUserId: null,
    isSubmitting: false,
  });

  const [shadowAccountExecutiveModal, setShadowAccountExecutiveModal] =
    useState({
      isOpen: false,
      selectedClient: null,
    });

  const { setNotification } = useNotification();

  const selectedCompanyRedux = useSelector(getSelectedCompany);
  const tableRef = useRef(null);

  const renderAction = ({ row }) => {
    return (
      <Box display={'flex'}>
        <IconButton onClick={() => handleOpenShadowAccount(row)}>
          <Icons.EditIcon />
        </IconButton>
        <IconButton
          onClick={() =>
            setRemoveShadowAccountModal({
              isOpen: true,
              shadowUserId: row.shadowUserId,
              shadowingUserId: row.shadowing.userId,
            })
          }
        >
          <Icons.RemoveIcon />
        </IconButton>
      </Box>
    );
  };

  const columns = [
    {
      field: 'fullName',
      headerName: 'Full Name',
      minWidth: 200,
      // flex: 1,
    },
    {
      field: 'email',
      headerName: 'Email',
      minWidth: 300,
      // flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#B80A26' }}>{params.row.email}</Box>
      ),
    },
    {
      field: 'shadowingFullName',
      headerName: 'Shadowing',
      minWidth: 200,
      // flex: 1,
    },
    {
      field: 'shadowingEmail',
      headerName: 'Email',
      minWidth: 300,
      // flex: 1,
      renderCell: (params) => (
        <Box sx={{ color: '#B80A26' }}>{params.row.shadowingEmail}</Box>
      ),
    },
    {
      field: 'fromDate',
      headerName: 'From',
      minWidth: 200,
      // flex: 1,
    },
    {
      field: 'toDate',
      headerName: 'To',
      minWidth: 200,
      // flex: 1,
    },
    {
      field: 'Action',
      headerName: 'Actions',
      align: 'center',
      minWidth: 180,
      flex: 1,

      renderCell: renderAction,
    },
  ];

  const getShadowAccounts = async (
    offset = 1,
    limit = 25,
    selectedCompany = { id: -1 },
    search
  ) => {
    try {
      setIsLoading(true);

      const getUsersPayload = {
        offset,
        limit,
      };

      if (search) Object.assign(getUsersPayload, { search });

      if (userRedux.role.name === 'ACCOUNT_EXECUTIVE') {
        Object.assign(getUsersPayload, {
          accountExecutiveId: userRedux.id,
        });
      }

      if (selectedCompany.length >= 1 && selectedCompany[0].id > -1) {
        const companyFilter = formatAccountPayload(selectedCompany);
        Object.assign(getUsersPayload, {
          accountId: companyFilter,
        });
      }

      const { data } = await usersAPI.getShadowAccount(getUsersPayload);

      const shadowAccounts = data.shadowAccounts.map((shadow) => ({
        accountDetails: shadow.accountDetails,
        email: shadow.email,
        fromDate: shadow.accountDetails[0].startDate,
        fullName: shadow.fullName,
        id: `${shadow.shadowUserId}${shadow.shadowing.userId}`,
        shadowing: shadow.shadowing,
        shadowingEmail: shadow.shadowing.email,
        shadowingFullName: shadow.shadowing.fullName,
        shadowUserId: shadow.shadowUserId,
        toDate: shadow.accountDetails[0].endDate,
      }));

      setUsers(shadowAccounts);
      setUsersCount(shadowAccounts.count);
    } catch (error) {
      console.log({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleReloadTable = async () => {
    getShadowAccounts(currentPage, pageSize, selectedCompanyRedux);
  };

  useEffect(() => {
    getShadowAccounts(
      currentPage,
      pageSize,
      selectedCompanyRedux,
      textToSearch
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize, selectedCompanyRedux]);

  const onPageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
  };

  const onPageChange = (page) => {
    scrollToTable();
    setCurrentPage(page + 1);
  };

  const scrollToTable = () => tableRef.current.scrollIntoView();

  const renderTable = useMemo(
    () => (
      <AutoResizeGrid
        sx={{ marginTop: '10px' }}
        rows={users}
        columns={columns}
        onPageChange={onPageChange}
        pageSize={pageSize}
        paginationMode="server"
        page={currentPage - 1}
        onPageSizeChange={onPageSizeChange}
        rowsPerPageOptions={[1, 25, 50, 100]}
        pagination
        rowCount={usersCount}
        autoHeight
        disableSelectionOnClick
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, users, pageSize, usersCount]
  );

  const handleSearchUsers = (text) => {
    setTextToSearch(text);

    getShadowAccounts(currentPage, pageSize, selectedCompanyRedux, text);
  };

  const onCloseSearch = () => {
    setTextToSearch('');
    handleReloadTable();
  };

  const handleOpenShadowAccount = (user) => {
    setEditShadowAccountExecutiveModal({
      isOpen: true,
      selectedClient: user,
    });
  };

  const handleOnConfirm = async () => {
    try {
      setRemoveShadowAccountModal((prev) => ({
        ...prev,
        isSubmitting: true,
      }));

      await userAPI.removeShadowAccount({
        shadowingUserId: removeShadowAccountModal.shadowingUserId,
        shadowUserId: removeShadowAccountModal.shadowUserId,
      });

      setNotification({
        open: true,
        message: (
          <>
            <b>Shadow account</b>
            {' correctly removed.'}
          </>
        ),
      });

      handleReloadTable();
    } catch ({ response }) {
      setNotification({
        open: true,
        message:
          response?.data?.message ?? 'An error occured. Please try again.',
        type: 'error',
      });
    } finally {
      handleCancelConfirm();
    }
  };

  const handleCancelConfirm = () => {
    setRemoveShadowAccountModal((prev) => ({
      isOpen: false,
      shadowingUserId: null,
      shadowUserId: null,
      isSubmitting: false,
    }));
  };

  const setShadowAccount = () => {
    setShadowAccountExecutiveModal({
      isOpen: true,
      selectedClient: userRedux.id,
    });
  };

  return (
    <Box
      sx={{ flexGrow: 1 }}
      style={{ minHeight: 'inherit', display: 'inherit' }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TopContainer>
            <TitleHeaderRow>
              <TitleHeaderCol>
                <Box
                  sx={{
                    fontFamily: 'OpenSans',
                    fontWeight: 400,
                    fontSize: '14px',
                    textTransform: 'uppercase',
                    fontStyle: 'normal',
                    letterSpacing: '0.15px',
                  }}
                >
                  <span
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    {`${userRedux.firstName} ${userRedux.lastName}`}
                  </span>{' '}
                  - {`${userRedux.role?.name?.replace(/_/g, ' ') ?? ''}`}
                </Box>
                <Box>
                  <ClientName>Shadow Accounts</ClientName>
                </Box>
              </TitleHeaderCol>
              <TitleHeaderCol>
                {userRedux.role.name === 'ACCOUNT_EXECUTIVE' && (
                  <Box>
                    <BlueAquaButtonFilled
                      onClick={setShadowAccount}
                      variant={'blueGradient-contained'}
                    >
                      Set Shadow Account
                    </BlueAquaButtonFilled>
                  </Box>
                )}
              </TitleHeaderCol>
            </TitleHeaderRow>
          </TopContainer>
        </Grid>

        {editShadowAccountExecutiveModal.isOpen && (
          <EditShadowAccountExecutive
            open={editShadowAccountExecutiveModal.isOpen}
            clientId={
              editShadowAccountExecutiveModal.selectedClient.shadowing.userId
            }
            shadowInfo={editShadowAccountExecutiveModal.selectedClient}
            onClose={() => {
              setEditShadowAccountExecutiveModal({
                isOpen: false,
                selectedClient: null,
              });
            }}
            handleOnSubmit={handleReloadTable}
          />
        )}

        {shadowAccountExecutiveModal.isOpen && (
          <ShadowAccountExecutive
            open={shadowAccountExecutiveModal.isOpen}
            clientId={shadowAccountExecutiveModal.selectedClient}
            onClose={() => {
              setShadowAccountExecutiveModal({
                isOpen: false,
                selectedClient: null,
              });
            }}
          />
        )}

        {removeShadowAccountModal.isOpen && (
          <ConfirmDialog
            isOpen={removeShadowAccountModal.isOpen}
            onCancel={handleCancelConfirm}
            onConfirm={handleOnConfirm}
            confirmText={'Delete'}
            isSubmitting={removeShadowAccountModal.isSubmitting}
            title={'Remove Shadow Account?'}
            text={'This action cannot be undone.'}
          />
        )}

        <BodyGridWrapper ref={tableRef} item xs={12} md={12}>
          <BodyContainer>
            <SearchTableComponent
              onCloseSearch={onCloseSearch}
              onSubmitSearch={handleSearchUsers}
              defaultValue={'ALL'}
              searchPlaceholder="Search..."
            />

            {isLoading ? (
              <div style={styles.loadingContainer}>
                <CircularProgress style={{ color: '#B80A26' }} size={60} />
              </div>
            ) : (
              <div style={{ flexGrow: 1 }}>{renderTable}</div>
            )}
          </BodyContainer>
        </BodyGridWrapper>
      </Grid>
    </Box>
  );
}

export default ShadowAccountsDashboardComponent;
