export const invoiceReportDefault = {
  group: 2,
  columns: [17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 28, 30, 31, 32],
  accounts: [],
  options: [
    {
      key: 1,
      value: {
        status: 3,
      },
    },
  ],
};
export const orderReportDefault = {
  group: 1,
  columns: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 14, 16],
  accounts: [],
  options: [
    {
      key: 1,
      value: {
        status: 3,
      },
    },
  ],
};
export const scrollUp = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}
