import React from 'react';
import { Button, Dialog, Box } from '@mui/material';
import styled from 'styled-components';

const styles = {
  submitButton: {
    borderRadius: "1px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "15px",
    letterSpacing: "1px",
    textTransform: "unset !important",
  },
  cancelButton: {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    textTransform: 'capitalize',
    color: '#B80A26',
  },
};

const ConfirmTitle = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.15px;
  color: #444444;
  padding: 18px 24px;
`;

const ConfirmMessage = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 6px 24px;
  color: rgba(0, 0, 0, 0.54);
`;

const ConfimContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 600px;
  background: #FFFFFF;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
`;

export const ConfirmDialog = ({
  confirmText,
  isOpen,
  isSubmitting,
  text,
  title,
  onCancel,
  onConfirm
}) => {
  return (
    <Dialog open={isOpen} maxWidth="sm" scroll="body">
      <ConfimContainer>
        <ConfirmTitle>{title}</ConfirmTitle>
        <ConfirmMessage>{text}</ConfirmMessage>
        <Box sx={{ display: "flex", justifyContent: "end", mr: 2, mb: 1 }}>
          <Button
            sx={{ ...styles.cancelButton, mr: 2 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            variant="blueGradient-outlined"
            sx={styles.submitButton}
            onClick={onConfirm}
            disabled={isSubmitting}
          >
            {confirmText}
          </Button>
        </Box>
      </ConfimContainer>
    </Dialog>
  )
}
