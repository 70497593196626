import React, { useState, useEffect, useCallback } from "react";
import { SearchInputField } from "../SearchInputField";
import { CompanyResultItem } from "./CompanyResultItem";
import styled from "styled-components";
import accountService from "../../api/modules/accounts";
import { CircularProgress } from "@mui/material";
import { CompanyNotFoundItem } from "./CompanyNotFoundItem";

const Label = styled.span`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
  margin-bottom: 16px;
  display: block;
`;

const ResultsContainer = styled.div`
  background: #ffffff;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  width: 100%;
`;

const LoadingContainer = styled.div`
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 14px 0;
`;

const MainContainer = styled.div`
  min-height: ${({ selectedCompany }) =>
    !selectedCompany ? "600px" : "400px"};
`;

export const CompanySelection = React.memo(
  ({ selectedCompany, onSelectCompany }) => {
    const [companies, setCompanies] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [lastSearch, setLastSearch] = useState("");
    const [textToSearch, setTextToSearch] = useState("");
    const [isTyping, setIsTyping] = useState(false);

    const onSearchHandler = useCallback(async (search = "") => {
      try {
        setIsLoading(true);
        setLastSearch(search);

        const params = { search };

        const result = await accountService.getAccounts(params);

        const _companies = result.data.value.filter((company) => true);
        setCompanies(_companies);
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }, []);

    const onSelectItem = (No_) => {
      const company = companies.find((company) => company.No_ === No_);
      onSelectCompany(company);
    };

    const handleOnSearchClose = () => {
      onSearchHandler("");
      setTextToSearch("");
    };

    useEffect(() => {}, []);

    useEffect(() => {
      setIsTyping(true);

      const delayFn = setTimeout(() => {
        if (textToSearch.trim().length >= 3) {
          setIsTyping(false);
          onSearchHandler(textToSearch.trim());
        } else {
          setIsLoading(false);
        }
      }, 1000);

      return () => clearTimeout(delayFn);
    }, [textToSearch]);
    return (
      <MainContainer selectedCompany={selectedCompany?.id}>
        <Label>Choose Account</Label>
        <div style={{ marginBottom: 24 }}>
          <SearchInputField
            value={textToSearch}
            onChange={({ target }) => setTextToSearch(target.value)}
          />

          {!isTyping && (
            <ResultsContainer>
              {isLoading ? (
                <LoadingContainer>
                  <CircularProgress style={{ color: "#B80A26" }} />
                </LoadingContainer>
              ) : companies.length > 0 ? (
                companies.map((company) => (
                  <CompanyResultItem
                    onClick={onSelectItem}
                    key={company.No_}
                    name={company.Name}
                    no_={company.No_}
                    isSelected={selectedCompany?.id === company.No_}
                  />
                ))
              ) : (
                !isLoading && <CompanyNotFoundItem text={lastSearch} />
              )}
            </ResultsContainer>
          )}
        </div>
      </MainContainer>
    );
  }
);
