import React from 'react';
import { Box, Grid, Button, TextField, InputAdornment } from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import TopBackground from '../images/header_background.png';
import TrackingTable from './TrackingTable';
import styled from 'styled-components';
import { SearchOutlined } from '@mui/icons-material';
import Icons from './icons';
import { useState } from 'react';
import TopContainer from './TopContainer/TopContainer';
import { TitleHeaderCol, TitleHeaderRow } from './Shared/TitleHeader/Index';
import { BodyContainer, BodyGridWrapper } from './Shared/BodyContainer/Index';

const styles = {
  dataGrid: {
    marginLeft: '38px',
    marginRight: '38px',
    marginTop: '2%',
    marginBottom: '4%',
    maxWidth: '100%',
  },
  title: {
    textAlign: 'intial',
  },
  titleEnd: {
    textAlign: 'end',
  },
  filterButton: {
    height: '50px',
    width: '23%',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
    fontFamily: 'Montserrat',
    fontWeight: 700,
    textTransform: 'unset !important',
  },
  searchButton: {
    height: '50px',
    width: '50px',
    left: '0px',
    top: '0px',
    borderRadius: '0px',
  },
};

const HederWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 450px;
`;

const SearchInput = muiStyled(TextField)(({ theme }) => ({
  width: '85%',
  '& .MuiInputBase-root:before, & .MuiInputBase-root:after': {
    border: 'none !important',
  },
  '& .MuiInput-root': {
    padding: '0 16px',
  },
  '& .MuiInput-input': {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',

    '&::placeholder': {
      color: '#444444',
      opacity: 1,
      fontFamily: 'Montserrat',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '24px',
    },
  },
  paddingBottom: '10px',
  paddingTop: '10px',
  backgroundColor: '#EEEEEE',
  border: 'none',
}));

const ViewColumnContainer = styled.div`
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  background: linear-gradient(136.27deg, #5058f2 2.13%, #44c0b4 100%);
`;

const TrackingDashboardComponent = () => {
  const [selectedFilter, setSelectedFilter] = useState('recent_activity');

  const onFilterChange = (filter) => {
    setSelectedFilter(filter);
  };

  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        style={{ minHeight: 'inherit', display: 'inherit' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TopContainer>
              <TitleHeaderRow>
                <TitleHeaderCol>
                  <Box
                    sx={{
                      fontFamily: 'OpenSans',
                      fontWeight: 400,
                      fontSize: '14px',
                      textTransform: 'uppercase',
                      fontStyle: 'normal',
                      letterSpacing: '0.15px',
                      lineHeight: '20px',
                    }}
                  >
                    End-to-End Services
                  </Box>

                  <Box
                    sx={{
                      height: '65px',
                      fontFamily: 'OpenSans',
                      fontStyle: 'normal',
                      fontWeight: 700,
                      fontSize: '48px',
                      lineHeight: '65px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    Tracking
                  </Box>
                </TitleHeaderCol>
              </TitleHeaderRow>
            </TopContainer>
          </Grid>

          <BodyGridWrapper item xs={12} md={12}>
            <BodyContainer>
              <Box
                sx={{ display: 'flex', justifyContent: 'space-between' }}
              ></Box>

              <TrackingTable />
            </BodyContainer>
          </BodyGridWrapper>
        </Grid>
      </Box>
    </>
  );
};

export default TrackingDashboardComponent;
