import React from 'react';

const StorageIcon = ({
  width = 21,
  height = 16,
  color = '#EFB447'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 16H20V12H0V16ZM2 13H4V15H2V13ZM0 0V4H20V0H0ZM4 3H2V1H4V3ZM0 10H20V6H0V10ZM2 7H4V9H2V7Z" fill={color} />
    </svg>

  );
}

export default StorageIcon;
