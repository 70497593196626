import AddIcon from './AddIcon';
import ArrowDropDown from './ArrowDropDown';
import CheckIcon from './CheckIcon';
import CloseIcon from './CloseIcon';
import DatePickerIcon from './DatePickerIcon';
import EditIcon from './EditIcon';
import ErrorIcon from './ErrorIcon';
import MessageIcon from './MessageIcon';
import MicroAgeLogoIcon from './MicroAgeLogoIcon';
import PauseCircleIcon from './PauseCircleIcon';
import PlayCircleIcon from './PlayCircleIcon';
import RemoveIcon from './RemoveIcon';
import RestoreIcon from './RestoreIcon';
import RightArrowIcon from './RightArrowIcon';
import RightChevronIcon from './RightChevronIcon';
import SearchIcon from './SearchIcon';
import SendIcon from './SendIcon';
import ShareIcon from './ShareIcon';
import ShoppingCartIcon from './ShoppingCartIcon';
import StarIcon from './StarIcon';
import StorageIcon from './StorageIcon';
import SuccessIcon from './SuccessIcon';
import ThumbUpIcon from './ThumbUpIcon';
import UserIcon from './UserIcon';
import ViewColumnIcon from './ViewColumnIcon';

const Icons = {
  AddIcon,
  ArrowDropDown,
  CheckIcon,
  CloseIcon,
  DatePickerIcon,
  EditIcon,
  ErrorIcon,
  MessageIcon,
  MicroAgeLogoIcon,
  PauseCircleIcon,
  PlayCircleIcon,
  RemoveIcon,
  RestoreIcon,
  RightArrowIcon,
  RightChevronIcon,
  SearchIcon,
  SendIcon,
  ShareIcon,
  ShoppingCartIcon,
  StarIcon,
  StorageIcon,
  SuccessIcon,
  ThumbUpIcon,
  UserIcon,
  ViewColumnIcon,
};

export default Icons;
