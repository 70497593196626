import React from 'react';

const SendIcon = ({
  width = 19,
  height = 16,
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.675326 15.5L18.167 8L0.675326 0.5L0.666992 6.33333L13.167 8L0.666992 9.66667L0.675326 15.5Z" fill="url(#paint0_linear_8566_121)" />
      <defs>
        <linearGradient id="paint0_linear_8566_121" x1="1.42786" y1="0.5" x2="15.5112" y2="17.6774" gradientUnits="userSpaceOnUse">
          <stop stopColor="#5058F2" />
          <stop offset="1" stopColor="#44C0B4" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SendIcon;
