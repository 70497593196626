const filters = {
  status: 1,
  dueDate: 4,
  originalAmount: 5,
  balanceDue: 6,
  paymentDate: 8,
  shipDate: 2,
  orderDate: 3,
  invoiceDate: 7,
  passedDue: 9,
};
export const filtersIdsToName = {
  1: "Status",
  4: "Due Date",
  5: "Original Amount",
  6: "Balance Due",
  8: "Payment Date",
  2: "Ship Date",
  3: "Order Date",
  7: "Invoice Date",
  9: "Passed Due",
};

export const getInitData = (data) => {
  let reportOptions = {};

  data.options.forEach((option) => {
    const value = filtersValueFrom(option.key, option.value);
    reportOptions.push(value);
  });
  return reportOptions;
};
export const filtersValueFrom = (key, value) => {
  if (key === 1) {
    return { name: "status", value: statusToLabel[value.status] };
  }

  if (key === 7) {
    if (value?.from === undefined) {
      return {
        name: "invoiceDate",
        value: { option: value, dateFrom: null, dateTo: null },
      };
    } else {
      return {
        name: "invoiceDate",
        value: {
          option: "dateRange",
          dateFrom: value.from,
          dateTo: value.to,
        },
      };
    }
  }
  if (key === 3) {
    if (value?.from === undefined) {
      return {
        name: "orderDate",
        value: { option: value, dateFrom: null, dateTo: null },
      };
    } else {
      return {
        name: "orderDate",
        value: {
          option: "dateRange",
          dateFrom: value.from,
          dateTo: value.to,
        },
      };
    }
  }
  if (key === 9) {
    return {
      name: "passedDue",
      value: { option: value, dateFrom: null, dateTo: null },
    };
  }

  if (key === 6) {
    if (value[0].criteria === "greater than") {
      return { name: "balance1", value: value[0].value };
    }
    if (value[0].criteria === "equal to") {
      return { name: "balance2", value: value[0].value };
    }
    if (value[0].criteria === "less than") {
      return { name: "balance3", value: value[0].value };
    }
  }
  if (key === 5) {
    if (value[0].criteria === "greater than") {
      return { name: "amount1", value: value[0].value };
    }
    if (value[0].criteria === "equal to") {
      return { name: "amount2", value: value[0].value };
    }
    if (value[0].criteria === "less than") {
      return { name: "amount3", value: value[0].value };
    }
  }
  return "";
};
export const filtersValueParser = (key, value) => {
  if (key === 1) {
    return statusToLabel[value.status];
  }

  if (key === 3 || key === 7 || key === 9) {
    if (value?.from === undefined) {
      return value;
    } else {
      return `From: ${value.from} To: ${value.to}`;
    }
  }

  if (key === 6 || key === 5) {
    return `${value[0].criteria} ${value[0].value}`;
  }
  return "";
};
const statuesValues = {
  Open: 1,
  Closed: 2,
  All: 3,
  PendingApproval: 4,
  PendingPrepayment: 5
};
export const statusToLabel = {
  1: "Open",
  2: "Closed",
  3: "All",
  4: "PendingApproval",
  5: "PendingPrepayment"
};

export const optionsParser = (options) => {
  let optionsFormat = [];
  let originalAmountCriteria = [];
  let balanceDueCriteria = [];
  if (options.status !== "") {
    optionsFormat.push({
      key: filters.status,
      value: {
        status: statuesValues[options.status],
      },
    });
  }
  if (options.amount1 !== "") {
    originalAmountCriteria.push({
      criteria: "greater than",
      value: options.amount1,
    });
  }
  if (options.amount2 !== "") {
    originalAmountCriteria.push({
      criteria: "equal to",
      value: options.amount2,
    });
  }
  if (options.amount3 !== "") {
    originalAmountCriteria.push({
      criteria: "less than",
      value: options.amount3,
    });
  }
  if (
    options.amount1 !== "" ||
    options.amount2 !== "" ||
    options.amount3 !== ""
  ) {
    optionsFormat.push({
      key: filters.originalAmount,
      value: originalAmountCriteria,
    });
  }

  if (options.balance1 !== "") {
    balanceDueCriteria.push({
      criteria: "greater than",
      value: options.balance1,
    });
  }
  if (options.balance2 !== "") {
    balanceDueCriteria.push({
      criteria: "equal to",
      value: options.balance2,
    });
  }
  if (options.balance3 !== "") {
    balanceDueCriteria.push({
      criteria: "less than",
      value: options.balance3,
    });
  }

  if (
    options.balance1 !== "" ||
    options.balance2 !== "" ||
    options.balance3 !== ""
  ) {
    optionsFormat.push({
      key: filters.balanceDue,
      value: balanceDueCriteria,
    });
  }

  if (options.invoiceDate.option === "dateRange") {
    optionsFormat.push({
      key: filters.invoiceDate,
      value: {
        from: options.invoiceDate.dateFrom,
        to: options.invoiceDate.dateTo,
      },
    });
  } else {
    optionsFormat.push({
      key: filters.invoiceDate,
      value: options.invoiceDate.option,
    });
  }

  if (options.orderDate.option === "dateRange") {
    optionsFormat.push({
      key: filters.orderDate,
      value: {
        from: options.orderDate.dateFrom,
        to: options.orderDate.dateTo,
      },
    });
  } else {
    optionsFormat.push({
      key: filters.orderDate,
      value: options.orderDate.option,
    });
  }
  if (options.passedDue.option !== "") {
    optionsFormat.push({
      key: filters.passedDue,
      value: options.passedDue.option,
    });
  }

  return optionsFormat;
};

export const initialFormValues = {
  balance1: "",
  balance2: "",
  balance3: "",
  amount1: "",
  amount2: "",
  amount3: "",
  invoiceDate: {
    option: "",
    dateFrom: null,
    dateTo: null,
  },
  orderDate: {
    option: "",
    dateFrom: null,
    dateTo: null,
  },
  passedDue: {
    option: "",
    dateFrom: null,
    dateTo: null,
  },
  status: "All",
};
