import { PluginService } from '../pluginService';

const orderInvoiceService = PluginService('');

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getInvoices(params) {
    return orderInvoiceService({
      method: 'GET',
      url: `/invoices`,
      params,
    });
  },

  getOrders(params, cancelToken) {
    return orderInvoiceService({
      cancelToken,
      method: 'GET',
      url: `/orders`,
      params,
    });
  },
  getOrdersDetails(docNumber) {
    return orderInvoiceService({
      method: 'GET',
      url: `/order-details/${docNumber}`,
    });
  },
  getInvoiceDetails(docNumber) {
    return orderInvoiceService({
      method: 'GET',
      url: `/invoice-details/${docNumber}`,
    });
  },
  getInvoicePdf(docNumber, filter = '') {
    return orderInvoiceService({
      method: 'GET',
      url: `/invoice-pdf/${docNumber}?filter=${filter}`,
      responseType: 'blob',
    });
  },
  getSalesPersonDetails(salesAssociate) {
    return orderInvoiceService({
      method: 'GET',
      url: `/sales-person/${salesAssociate}`,
    });
  },
};
