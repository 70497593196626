export const formatAddress = (addressComponents) => {
  const result = {
    address: '',
    city: '',
    state: '',
    stateAbbr: '',
    zipCode: '',
    country: 'USA',
  };
  const streetNum = findByPropertyAndValue('street_number', addressComponents);
  const route = findByPropertyAndValue('route', addressComponents);
  const city = findByPropertyAndValue('locality', addressComponents);
  const state = findByPropertyAndValue(
    'administrative_area_level_1',
    addressComponents,
  );

  const zipCode = findByPropertyAndValue('postal_code', addressComponents);
  result.address =
    streetNum && route ? `${streetNum.long_name} ${route.long_name}` : '';
  result.city = city?.long_name || '';
  result.state = state?.long_name || '';
  result.stateAbbr = state?.short_name || '';
  result.zipCode = zipCode?.long_name || '';

  return result;
};

const findByPropertyAndValue = (value, array) =>  array.find((component) => component.types[0] == value);