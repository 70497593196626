import React from 'react';

const EditIcon = ({
  width = 18,
  height = 18,
  color = '#9E9E9E'
}) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.713867 12.3732V15.4982H3.83887L13.0555 6.28151L9.93053 3.15651L0.713867 12.3732ZM15.4722 3.86484C15.7972 3.53984 15.7972 3.01484 15.4722 2.68984L13.5222 0.739844C13.1972 0.414844 12.6722 0.414844 12.3472 0.739844L10.8222 2.26484L13.9472 5.38984L15.4722 3.86484Z" fill={color} />
    </svg>

  );
}

export default EditIcon;



