import React from 'react';
import styled from 'styled-components';
import Icons from '../icons';

const Name = styled.span`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #444444;
`;

const Contact = styled.span`
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #BDBDBD;
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ isSelected }) => isSelected ? 'rgba(68, 192, 180, 0.2)' : 'none'};
  padding: 14px 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyResultItem = ({
  no_,
  isSelected,
  name,
  onClick,
}) => {

  const handleOnClick = () => {
    onClick(no_);
  }

  return (
    <Container isSelected={isSelected} onClick={handleOnClick}>
      <Row>
        <Name>{name}</Name>
      </Row>

      <Row>
        {
          isSelected
            ? <Icons.CheckIcon />
            : <Icons.AddIcon />
        }
      </Row>
    </Container>
  )
}
